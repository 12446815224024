import classNames from 'classnames';
import React from 'react';

interface Props {
    onFormSubmit: (form: any) => void
    action: string
    method?: string
    className?: string
    displayErrors?: boolean
    displayErrorsClassName?: string
    id?: string
    children?: any
}

export default class Form extends React.Component<Props, null> {
    static defaultProps = {
        method: 'POST',
        displayErrorsClassName: 'display-errors'
    }

    onFormSubmit = (event: any) => {
        event.preventDefault()
        this
            .props
            .onFormSubmit(event.target)
    }

    render() {
        let className = (this.props.displayErrors)
            ? classNames(this.props.className, this.props.displayErrorsClassName)
            : this.props.className
        return (
            <form
                action={this.props.action}
                className={className}
                id={this.props.id}
                method={this.props.method}
                noValidate
                onSubmit={this.onFormSubmit}
            >
                {this.props.children}
            </form>
        )
    }
}