import { FieldProps } from 'lib-react/web/interfaces/form-interfaces';
import React from 'react';
import InputWrapper from './input-wrapper';

interface Props extends FieldProps { }

export default class IconInputField extends React.Component<Props, null> {
    static FIELD_NAME = 'custom-icon-input-field'

    onChange = (event: { target: { value: string } }) => {
        this.props.onChange(event.target.value)
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const title = schema.title
        const inputClassName = uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        const iconClassName = uiSchema.customIconClassName ? uiSchema.customIconClassName : undefined
        const inputType = uiSchema.customInputType ? uiSchema.customInputType : undefined
        return (
            <InputWrapper
                title={title}
                required={required}
            >
                <div className="has-feedback">
                    <input
                        type={inputType}
                        name={this.props.name}
                        placeholder={this.props.placeholder}
                        className={inputClassName}
                        onChange={this.onChange}
                    />
                    <span className={iconClassName} />
                </div>
            </InputWrapper>
        )
    }
}