import { clone, isObjectEmpty } from 'lib-react/universal/utils';
import { FormDataObject, FormObject, ServerFormObject, DataForBreadcrumbs } from '../interfaces/form-object';

export const EMPTY_FORM_DATA_OBJECT: FormDataObject = {
    form: {
        formSchema: {},
        formData: {}
    }
}

export default class FormObjectAdapter implements FormObject {
    data: FormDataObject
    method: 'PUT' | 'POST'
    url: string
    page: DataForBreadcrumbs

    constructor(jsonNode: ServerFormObject) {
        // Set default values
        this.data = clone(EMPTY_FORM_DATA_OBJECT)
        this.method = 'POST'
        this.url = ""
        this.page = {size: 0, totalElements: 0, totalPages: 0, number: 0}
        // Try re-set the valus if the input object is well formatted
        if (jsonNode) {
            if (jsonNode.PUT || jsonNode.POST) {
                this.data = jsonNode.PUT ? jsonNode.PUT : jsonNode.POST
                this.method = jsonNode.PUT ? 'PUT' : 'POST'
                if (jsonNode.page) {
                    this.page = jsonNode.page
                }
            }
            if (jsonNode.action) {
                this.url = jsonNode.action.href
            } else {
                try {
                    this.url = jsonNode._links.self.href
                } catch (TypeError) {
                    this.url = ""
                }
            }
        }
    }
}