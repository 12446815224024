export const SINGLETON_ERRORS = {
    INIT_ERROR: 'INIT_ERROR',
    AUSILIO_NOT_FOUND: 'AUSILIO_NOT_FOUND',
    REGISTRATION_ERROR: 'REGISTRATION_ERROR'
}

export const REPRESENTS = {
    PRIVACY: 'privacy',
    PARENT_LIST: 'parent-list',
    DISABLE_SECTION: 'disable-section'
}

export const AUSILI_STATUS = {
    AVAILABLE: "AVAILABLE",         // disponibile
    BORROWED: "BORROWED",           // in prestito
    BOOKED_UP: "BOOKED_UP",         // prenotato
    MAINTENANCE: "MAINTENANCE",     // in manutenzione
    UNKNOWN: "UNKNOWN",
}

export const ATTACHMENT_STATUS = {
    GENERATING: "GENERATING",
    TO_SIGN: "TO_SIGN",
    SIGNED: "SIGNED"
}