
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { QrCode2Icon } from './qr-icon';

interface Props {
    onQrClick: () => any
}

const useStyles = makeStyles(() => ({
    largeIcon: {
        width: 60,
        height: 60,
    }
}));

export default function QrComponent(props: Props) {
    const classes = useStyles()
    return (
        <IconButton color="primary" onClick={props.onQrClick} size="medium" className={classes.largeIcon}  >
            <QrCode2Icon fontSize='large' />
        </IconButton>
    )
}