
import { Box } from '@material-ui/core';
import Error400 from 'lib-react/web/themes/adminlte/components/error-pages/400';
import TextualError from 'lib-react/web/themes/adminlte/components/error-pages/textual-error';
import * as React from 'react';
import { PageContainer } from './page-container';

export function Custom404Error() {
    return (
        <Error400
            errorImageUri={"/dist/img/404_page_not_found.jpg"}
        />
    )
}

export function CustomGenericError() {
    const message = (
        <div>
            <p>Si è verificato un errore.</p>
            <p>Si prega di ricaricare la pagina o cliccare su &apos;Disconnetti&apos;.</p>
        </div>
    )
    return (
        <TextualError
            message={message}
        />
    )
}

export function CustomAusilioNotFoundError() {
    return (
        <PageContainer>
            <Box textAlign="center">
                <img className='img-responsive center-block' src={"/dist/img/404_ausilio_not_found.jpg"} />
            </Box>
        </PageContainer>
    )
}