
import { alpha, FormControl, IconButton, InputAdornment, InputBase, InputLabel, styled, Typography,  } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FieldProps } from "lib-react/web/interfaces/form-interfaces";
import { PagesStyles } from "lib-react/web/themes/material-ui/styles/material-theme";
import React, { useEffect, useState } from "react";

interface State {
  password: string;
  showPassword: boolean;
}

interface Props extends FieldProps { }

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.type === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function ShowHidePasswordField(props: Props) {
  const classes = PagesStyles();
  const [active, setActive] = useState<boolean>(props.formData || false);
  const [values, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    props.onChange(active);
  }, [active]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  
  // if (props.materialStyleActive) {
  //   return (
  //   <FormControl variant="outlined">
  //     <InputLabel htmlFor="outlined-adornment-password">{props.schema.title}</InputLabel>
  //     <OutlinedInput
  //       type={values.showPassword ? "text" : "password"}
  //       value={values.password}
  //       onChange={handleChange("password")}
  //       autoComplete="new-password"
  //       endAdornment={
  //         <InputAdornment position="end">
  //           <IconButton
  //             aria-label="toggle password visibility"
  //             onClick={handleClickShowPassword}
  //             onMouseDown={handleMouseDownPassword}
  //             edge="end"
  //           >
  //             {values.showPassword ? <Visibility /> : <VisibilityOff />}
  //           </IconButton>
  //         </InputAdornment>
  //       }
  //       label={props.schema.title}
  //     />
  //   </FormControl>
  // )}

  return (
    <FormControl variant="outlined" className={classes.show_hide_password}>
      <InputLabel shrink htmlFor="bootstrap-input">
        <Typography variant="h6">{props.schema.title}</Typography>
      </InputLabel>
      <BootstrapInput
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
        autoComplete="new-password"
        endAdornment={<InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {values.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>}
      />
    </FormControl>
  );
}

export const SHOW_HIDE_PASSWORD_FIELD_NAME = "custom-show-hide-password-field";
