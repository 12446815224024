import RetryPromise from 'bluebird-retry';
import { timeoutCollection } from 'time-events-manager';
import AjaxRequestInterface from '../../universal/interfaces/ajax-request';
import { castTo, clone, executeAjaxRequest, isBundleVersion, logInfo, logThis } from '../../universal/utils';
import FormObjectAdapter from '../adapters/form-object-adapter';
import { FormObject } from '../interfaces/form-object';
import { METHOD } from '../utils/constants';
import { refreshPage } from '../utils/utils';

const DEFAULT_RETRY_POLICY = {
    interval: 200, backoff: 2, max_interval: 2000, max_tries: 5, throw_original: true
}


export function executeRequest<T>(options: AjaxRequestInterface): Promise<T> {
    const retryPolicy = options.retryPolicy ? options.retryPolicy : DEFAULT_RETRY_POLICY
    return castTo<Promise<T>>(RetryPromise(() => {
        return createPromise(options)
    }, retryPolicy))
}

export function executePerpetualRequest(request: () => any, intervalInMillis: number): number {
    let repeater
    function doPerpetual() {
        request()
        repeater = setTimeout(doPerpetual, intervalInMillis)
    }
    doPerpetual()
    return castTo<number>(repeater)
}

export function stopPerpetualRequest(repeaterId: number) {
    // TODO: FIXME: rimuovo tutti i timeout perchè entrando per ID non funziona
    timeoutCollection.removeAll()
}

export function checkSession(uriToCheck: string): void {
    logThis("Check the user session.")
    let options: AjaxRequestInterface = {
        url: uriToCheck,
        method: 'GET'
    }
    new Promise((resolve, reject) => {
        executeAjaxRequest(options, resolve, reject)
    }).catch((error) => {
        if (error && error.status && (error.status === 401 || error.status === 403)) {
            // The user is not authenticated (the session has expired?) => force the refresh of the page.
            logThis("User not authenticated, force the page refresh.")
            refreshPage()
        }
    })
}

export function checkSessionCallbackOnError(uriToCheck: string, callbackOnError: () => void): void {
    logInfo("Check the user session.")
    let options: AjaxRequestInterface = {
        url: uriToCheck,
        method: 'GET'
    }
    new Promise((resolve, reject) => {
        executeAjaxRequest(options, resolve, reject)
    }).catch((error) => {
        if (error && error.status && (error.status === 401 || error.status === 403)) {
            // The user is not authenticated (the session has expired?) => force the refresh of the page.
            logThis("User not authenticated, callback to execute.")
            if (callbackOnError) {
                callbackOnError()
            }
        }
    })
}

export function getFormData(options: AjaxRequestInterface): Promise<FormObject> {
    const retryPolicy = options.retryPolicy ? options.retryPolicy : DEFAULT_RETRY_POLICY
    let opt = clone(options)
    opt.Adapter = FormObjectAdapter
    opt.method = isBundleVersion() ? METHOD.GET : METHOD.OPTIONS
    return castTo<Promise<FormObject>>(RetryPromise(() => {
        return createPromise(opt)
    }, retryPolicy))
}

export function sendForm(options: AjaxRequestInterface): Promise<any> {
    const retryPolicy = options.retryPolicy ? options.retryPolicy : DEFAULT_RETRY_POLICY
    return castTo<Promise<any>>(RetryPromise(() => {
        if (isBundleVersion()) {
            return new Promise((resolve, reject) => {
                // In bundle version, just invoke the positive callback
                resolve({})
            })
        }
        return createPromise(options)
    }, retryPolicy))
}

function createPromise(options: AjaxRequestInterface) {
    return new Promise((resolve, reject) => {
        executeAjaxRequest(options, (data) => {
            resolve(data)
        }, (error) => {
            if (error.status < 500) {
                if (error.status === 401 && options.sessionCheckUri) {
                    checkSession(options.sessionCheckUri)
                }

                // Reject without retry
                reject(new RetryPromise.StopError(error))
            }
            // Reject with retry
            reject(error)
        })
    })
}