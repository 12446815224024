import { Box, TextField }from '@material-ui/core';
import * as React from 'react';
interface Props {
    filterValue: string
    onFilterChange: (filterValue: string) => any
}

export function FilterComponent(props: Props) {

    const onFilterChange = (event: any) => {
        props.onFilterChange(event.target.value)
    }

    return (
        <Box pr={2} >
            <TextField
                variant="standard"
                label="Cerca"
                fullWidth
                onChange={onFilterChange}
                value={props.filterValue}
            /></Box>
    )
}