import { Navigation } from "commons/interfaces/navigation";
import { StructureInfo } from "commons/interfaces/structure-info";
import { UserInfo } from "commons/interfaces/user-info";
import { MessageInterface } from "lib-react/universal/interfaces/message-interface";
import { addMessage, BaseGlobalAction, GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS, removeMessage, startLoading, stopLoading } from "lib-react/universal/redux/base-actions";
import { expandUri, generateUUID, logThis } from "lib-react/universal/utils";
import { executeRequest } from "lib-react/web/services/ajax-services";
import { SINGLETON_ERRORS } from "utils/constants";
import { REST_ENDPOINTS } from "utils/rest-routes";
import { Dispatch } from "../../../node_modules/redux";
import { batchActions } from "../../../node_modules/redux-batched-actions";

export const GLOBAL_SAVE_STRUCTURE_ID = "GLOBAL_SAVE_STRUCTURE_ID"
export const GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS = "GLOBAL_RETRIEVE_STRUCTURE_INFO"
export const GLOBAL_RETRIEVE_USER_INFO_SUCCESS = "GLOBAL_RETRIEVE_USER_INFO_SUCCESS"

export interface GlobalAction extends BaseGlobalAction {
    structureId?: string
    userInfo?: UserInfo
    structureInfo?: StructureInfo
}

// Called by landing-page
export function retrievePublicInitializationInfo(structureId: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(startLoading())
        const navigationUri = REST_ENDPOINTS.NAVIGATION
        const expandedUri = expandUri(navigationUri, { structureId: structureId })
        executeRequest<Navigation>({ url: expandedUri })
            .then((response) => {
                // Get the structure info
                if (response._links && response._links.organization) {
                    const uri = response._links.organization.href
                    const expandedOrganizationUri = expandUri(uri, { id: structureId })
                    dispatch(retrieveStructureInfo(expandedOrganizationUri))
                }

                return dispatch(batchActions([
                    retrieveNavigationLinksSuccess(response),
                ]))
            })
            .catch((error) => {
                logThis("Error durign navigation fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

// Called by app
export function retrieveInitializationInfo(structureId: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(startLoading())
        const navigationUri = expandUri(REST_ENDPOINTS.NAVIGATION, { structureId: structureId })
        executeRequest<Navigation>({ url: navigationUri })
            .then((response) => {
                // Get the user info
                if (response._links && response._links.userInfo) {
                    const userInfoUri = response._links.userInfo.href
                    dispatch(retrieveUserInfo(userInfoUri))
                }

                // Get the structure info
                if (response._links && response._links.organization) {
                    const uri = response._links.organization.href
                    const expandedUri = expandUri(uri, { id: structureId })
                    dispatch(retrieveStructureInfo(expandedUri))
                }

                return dispatch(batchActions([
                    retrieveNavigationLinksSuccess(response),
                ]))
            })
            .catch((error) => {
                logThis("Error durign navigation fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveNavigationLinksSuccess(navigationLinks: Navigation): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS,
        navigationLinks: navigationLinks
    }
}

export function saveStructureId(structureId: string): GlobalAction {
    return {
        type: GLOBAL_SAVE_STRUCTURE_ID,
        structureId
    }
}

export function retrieveUserInfo(userInfoUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<UserInfo>({ url: userInfoUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveUserInfoSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign user info fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveUserInfoSuccess(userInfo: UserInfo): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_USER_INFO_SUCCESS,
        userInfo
    }
}

export function retrieveStructureInfo(structureInfoUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<StructureInfo>({ url: structureInfoUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveStrucutureInfoSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign structure info fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveStrucutureInfoSuccess(structureInfo: StructureInfo): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS,
        structureInfo
    }
}

// TODO:: spostare su lib-react
export function dispatchRemoveMessage(message: MessageInterface) {
    return (dispatch: Dispatch) => {
        dispatch(removeMessage(message))
    }
}