import { AusiliItem, ExitReservation, EntryReservation } from "commons/interfaces/ausili-item";
import { Navigation } from "commons/interfaces/navigation";
import { StructureInfo } from "commons/interfaces/structure-info";
import { UserInfo } from "commons/interfaces/user-info";
import { Messages } from "lib-react/universal/interfaces/message-interface";

export interface GlobalStateInterface {
    global: {
        _isReduxConfigured: boolean,
        isLoading: boolean,
        navigationLinks: Navigation,
        userInfo: UserInfo,
        structureInfo: StructureInfo,
        structureId: string,
        messages: Messages,
    },
    ausili: {
        ausiliItems: Map<string, AusiliItem>,
        selectedAusilio: AusiliItem
        exitReservationItems: Map<string, ExitReservation>,
        entryReservationItems: Map<string, EntryReservation>
    },
    ux: {
        showImages: boolean,
        filterValue: string
    }
}

export const INITIAL_STATE: GlobalStateInterface = {
    global: {
        _isReduxConfigured: true,
        isLoading: false,
        navigationLinks: null,
        userInfo: null,
        structureInfo: null,
        structureId: null,
        messages: {}
    },
    ausili: {
        ausiliItems: new Map(),
        selectedAusilio: null,
        exitReservationItems: new Map(),
        entryReservationItems: new Map()
    },
    ux: {
        showImages: true,
        filterValue: ""
    }
}
