export function getFormObject() {
    return {
        "_links": {
            "self": {
                "href": ""
            }
        },
        "POST": {
            "form": {
                "formSchema": {
                    "type": "object",
                    "properties": {
                        "password": {
                            "title": "Password",
                            "represents": "password",
                            "type": "string",
                            "minLength": 8
                        },
                        "newPassword1": {
                            "title": "Nuova password",
                            "represents": "password",
                            "type": "string",
                            "minLength": 8
                        },
                        "newPassword2": {
                            "title": "Ripeti password",
                            "represents": "password",
                            "type": "string",
                            "minLength": 8
                        }
                    },
                    "required": ["password", "newPassword1", "newPassword2"]
                },
                "formData": {}
            }
        }
    }
}