import { Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import { AppDimens, BaseMaterialColors } from 'commons/styles/mui-style';
import * as React from 'react';
import { AUSILI_STATUS } from 'utils/constants';

const styles = makeStyles(() => createStyles({
    chip: {
        fontSize: AppDimens.APP_BASE_FONT_SIZE,
    },
    available: {
        backgroundColor: BaseMaterialColors.TEAL,
    },
    bookedUp: {
        backgroundColor: BaseMaterialColors.BROWN,
    },
    borrowed: {
        backgroundColor: BaseMaterialColors.GREY,
    },
    maintenance: {
        backgroundColor: BaseMaterialColors.RED,
    }
}))

interface Props {
    ausilio: AusiliItem
}

export default function StatusChip(props: Props) {
    const ausilio = props.ausilio
    let ausilioStatus
    let colorStyle
    let classes = styles()
    if (ausilio.status === AUSILI_STATUS.AVAILABLE) {
        ausilioStatus = "Disponibile"
        colorStyle = classes.available
    } else if (ausilio.status === AUSILI_STATUS.BOOKED_UP) {
        ausilioStatus = "Prenotato"
        colorStyle = classes.bookedUp
    } else if (ausilio.status === AUSILI_STATUS.BORROWED) {
        ausilioStatus = "In prestito"
        colorStyle = classes.borrowed
    } else if (ausilio.status === AUSILI_STATUS.MAINTENANCE) {
        ausilioStatus = "In manutenzione"
        colorStyle = classes.maintenance
    } else {
        ausilioStatus = "Sconosciuto"
    }
    return (
        <Chip
            label={ausilioStatus}
            className={`${classes.chip} ${colorStyle}`}
            color="primary"
        />
    )
}
