import { FieldProps } from '@rjsf/core';
import SwitchBooleanField from 'commons/components/forms/switch-boolean-field';
import { AutocompleteListField } from 'lib-react/web/components';
import ComboBoxField from 'lib-react/web/components/form-components/combo-box-field';
import FileUrlField from 'lib-react/web/components/form-components/file-url-field';
import { UiSchema } from 'lib-react/web/interfaces/form-interfaces';
import { StyleRulesObject } from 'lib-react/web/interfaces/style-rules-object';
import { baseFormStyleRules, getBaseFields, getBaseWidgets } from 'lib-react/web/utils/base-form-style-rules';
import { COMMONS_REPRESENTATION_KEYS, FORM_INPUT_DEFAULT_WIDGETS, FORM_INPUT_FORMAT, JSON_SCHEMA_TYPES } from 'lib-react/web/utils/schema-utils';
import { createDangerousInnerHtml } from 'lib-react/web/utils/utils';
import * as React from 'react';
import { REPRESENTS } from 'utils/constants';

export function commonFormStyleRules(key: string, object: any): StyleRulesObject {
    let uiObject: UiSchema = baseFormStyleRules(key, object).value

    if (object.type !== JSON_SCHEMA_TYPES.OBJECT) {
        uiObject['classNames'] = 'col-xs-12 col-sm-6 col-lg-4'
    }

    if (object.type === JSON_SCHEMA_TYPES.NUMBER) {
        uiObject['ui:widget'] = FORM_INPUT_DEFAULT_WIDGETS.UPDOWN
    }
    if (object.type === JSON_SCHEMA_TYPES.ARRAY) {
        uiObject['ui:widget'] = FORM_INPUT_DEFAULT_WIDGETS.CHECKBOXES
        uiObject['ui:options'] = { inline: true }

    }
    if (object.type === JSON_SCHEMA_TYPES.BOOLEAN) {
        if (object.represents === REPRESENTS.DISABLE_SECTION) {
            uiObject['classNames'] = ''
            uiObject['ui:field'] = SwitchBooleanField.FIELD_NAME
        } else {
            uiObject['ui:widget'] = FORM_INPUT_DEFAULT_WIDGETS.RADIO
            uiObject['classNames'] = 'radio-area-height col-xs-12 col-sm-6'
        }
    }
    if (object.format === FORM_INPUT_FORMAT.FILE) {
        uiObject['classNames'] = 'col-xs-12'
    }

    if (object.represents === COMMONS_REPRESENTATION_KEYS.FILE_URL) {
        uiObject['classNames'] = FileUrlField.FIELD_NAME
    }

    if (object.represents === FORM_INPUT_FORMAT.FILE) {
        uiObject['classNames'] = 'col-xs-12'
    }

    if (object.represents === REPRESENTS.PARENT_LIST) {
        uiObject['classNames'] = 'no-title'
        uiObject['ui:options'] = {
            orderable: false
        }
    }

    if (object.represents === REPRESENTS.PRIVACY) {
        uiObject['ui:field'] = (props: FieldProps) => {
            let required = props.required ? '*' : undefined
            return (
                <label>
                    <input
                        checked={props.formData}
                        type="checkbox"
                        required={props.required}
                        onChange={
                            (event) => {
                                props.onChange(event.target.checked)
                            }}
                    />
                    <span dangerouslySetInnerHTML={createDangerousInnerHtml(props.schema.title)} />
                    {required}
                </label>
            )
        }
    }

    if (object.represents === COMMONS_REPRESENTATION_KEYS.PHONE) {
        // TODO: creare widget per phone https://text-mask.github.io/text-mask/
        uiObject['ui:options'] = { inputType: 'tel' }
    }
    if (object.format === FORM_INPUT_FORMAT.TEXTAREA) {
        uiObject['classNames'] = 'col-xs-12'
    }
    if (object.autocomplete) {
        uiObject['ui:field'] = ComboBoxField.FIELD_NAME
        uiObject.customUrl = object.autocomplete.href
        uiObject.customAllowCustomValues = object.autocomplete.otherValues
    }
    if (object.represents === FORM_INPUT_DEFAULT_WIDGETS.LIST) {
        uiObject['ui:field'] = AutocompleteListField.FIELD_NAME
    }

    return { key: key, value: uiObject }
}

export function getCommonFields() {
    let outputObject: any = getBaseFields()
    outputObject[SwitchBooleanField.FIELD_NAME] = SwitchBooleanField
    outputObject[ComboBoxField.FIELD_NAME] = ComboBoxField
    outputObject[AutocompleteListField.FIELD_NAME] = AutocompleteListField
    return outputObject
}

export function getCommonWidgets() {
    return getBaseWidgets()
}
