import moment from 'moment'
import { castTo } from '.';

export const FORMAT_ISO8601 = "YYYY-MM-DD"
export const FORMAT_ISO8601_STROKES = "YYYY/MM/DD"
export const FORMAT_UNI_EN28601 = "DD-MM-YYYY"
export const FORMAT_UNI_EN28601_STROKES = "DD/MM/YYYY"
export const FORMAT_UNI_EN28601_DATETIME_STROKES = "DD/MM/YYYY HH:mm"
export const FORMAT_ISO8601_DATETIME = FORMAT_ISO8601 + " HH:mm"

export function getMonthIntervalFromDate(date: Date): { start: Date, end: Date } {
    const start = moment(date).startOf('month').toDate()
    const end = moment(date).endOf('month').toDate()
    return {
        start, end
    }
}

export function isValidDate(date: Date) {
    const casted = castTo<number>(date)
    return date instanceof Date && !isNaN(casted)
}




// --- TESTATO FINO QUI

export function generateRandomDate(start: Date, end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}



export function formatISO8601Date(date: string, inputFormat: string): string {
    return moment(date, inputFormat, true).format(FORMAT_ISO8601) // used the moment strict mode
}

export function formatUNIEN28601Date(date: string, inputFormat: string): string {
    return moment(date, inputFormat, true).format(FORMAT_UNI_EN28601) // used the moment strict mode
}

export function formatDateObject(date: Date, outputFormat: string): string {
    return moment(date).format(outputFormat)
}

export function isToday(date: string, inputFormat: string): boolean {
    const dateToConsider = formatISO8601Date(date, inputFormat)
    const today = formatDateObject(new Date(), FORMAT_ISO8601)
    return today === dateToConsider
}

export function getDateFromString(inputString: string, inputFormat: string): Date {
    return moment(inputString, inputFormat, true).toDate()
}

export function getWeekFromDate(date: Date): { start: Date, end: Date } {
    // Get the first and last days of the week
    const selectedDay = date.getDay()
    const diff = date.getDate() - selectedDay + (selectedDay === 0 ? -6 : 1) // adjust when day is sunday
    const monday = new Date(date.setDate(diff))

    const sunday = new Date(date)
    sunday.setDate(monday.getDate() + 6)

    return {
        start: monday,
        end: sunday
    }
}