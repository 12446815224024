import { GLOBAL_ADD_MESSAGE, GLOBAL_REMOVE_MESSAGE, GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS, GLOBAL_START_LOADING, GLOBAL_STOP_LOADING } from 'lib-react/universal/redux/base-actions';
import { clone } from 'lib-react/universal/utils';
import { GlobalAction, GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS, GLOBAL_RETRIEVE_USER_INFO_SUCCESS, GLOBAL_SAVE_STRUCTURE_ID } from 'redux/actions/global-actions';
import { GlobalStateInterface, INITIAL_STATE } from '../store';

export default function (state = INITIAL_STATE.global, action: GlobalAction): GlobalStateInterface["global"] {
    switch (action.type) {
        case GLOBAL_START_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GLOBAL_STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        case GLOBAL_ADD_MESSAGE:
            var clonedAddMessage = clone(state.messages)
            clonedAddMessage[action.message.id] = action.message
            return {
                ...state,
                messages: clonedAddMessage
            }
        case GLOBAL_REMOVE_MESSAGE:
            var clonedRemoveMessage = clone(state.messages)
            delete clonedRemoveMessage[action.message.id]
            return {
                ...state,
                messages: clonedRemoveMessage
            }
        case GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS:
            return {
                ...state,
                navigationLinks: action.navigationLinks
            }
        case GLOBAL_RETRIEVE_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS:
            return {
                ...state,
                structureInfo: action.structureInfo
            }
        case GLOBAL_SAVE_STRUCTURE_ID:
            return {
                ...state,
                structureId: action.structureId
            }
        default:
            return state
    }
}