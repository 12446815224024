import { Box, Button, Card, CardActions, CardContent, Container, CssBaseline, ThemeProvider, Typography } from '@material-ui/core';
import { CustomGenericError } from 'commons/components/errors';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { PageStyles, THEME } from 'commons/styles/mui-style';
import { Messages } from 'lib-react/universal/interfaces/message-interface';
import { getMessageByMultipleParams } from 'lib-react/universal/redux/redux-utils';
import { expandUri } from 'lib-react/universal/utils';
import Footer from 'lib-react/web/themes/material-ui/footer';
import Header from 'lib-react/web/themes/material-ui/navigation/header';
import { getQueryParamsFromWindowSearch } from 'lib-react/web/utils/utils';
import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { retrievePublicInitializationInfo, saveStructureId } from 'redux/actions/global-actions';
import { GlobalStateInterface, INITIAL_STATE } from 'redux/store';
import configureStore from 'redux/store/configureStore';
import { initApp } from 'utils/app-initializer';
import { SINGLETON_ERRORS } from 'utils/constants';
import { getLoadingComponent } from 'utils/index';
import { WEB_ENDPOINTS } from 'utils/rest-routes';



// Initialize the App
initApp()

// Initialize the Redux store
const store = configureStore(INITIAL_STATE)

interface ActionCreatorsProps {
    retrievePublicInitializationInfo: (structureId: string) => any
    saveStructureId: (structureId: string) => any
}

interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
    messages: Messages
    structureId: string
}

function LandingPage(props: ActionCreatorsProps & Props) {

    // componentDidMount
    useEffect(() => {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            props.saveStructureId(queryParams.structureId)
        }
        props.retrievePublicInitializationInfo(queryParams.structureId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const pageStyles = PageStyles();

    const message = getMessageByMultipleParams(props.messages, {
        errorCode: SINGLETON_ERRORS.INIT_ERROR
    })
    if (message) {
        return CustomGenericError()
    }
    if (!props.navigationLinks) {
        return getLoadingComponent(true)
    }
    const url = expandUri(WEB_ENDPOINTS.AUSILI, { structureId: props.structureId })
    return (
        <ThemeProvider theme={THEME}>
            <CssBaseline />
            <HashRouter>
                <Box className={pageStyles.page}>
                    <Header
                        needNavbarArea={false}
                        title="Ausili WEB"
                    />
                    <Container maxWidth="sm" className={pageStyles.mainContainer}>
                        <Box p={4}>
                            <Card>
                                <CardContent>
                                    <Box p={2}><Typography align='center'>Benvenuto in Ausili WEB, il sito web per la gestione degli ausili.</Typography></Box>
                                    <Box p={2}><Typography align='center'>Clicca su &quot;Entra&quot; per procedere con la navigazione.</Typography></Box>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" size="medium" color="primary" href={url}>Entra</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Container>
                    <Footer structureInfo={undefined} />
                </Box>
            </HashRouter>
        </ThemeProvider>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo,
        messages: state.global.messages,
        structureId: state.global.structureId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrievePublicInitializationInfo: bindActionCreators(retrievePublicInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

const AppHoc = connect(mapStateToProps, mapDispatchToProps)(LandingPage)

ReactDOM.render(
    <Provider store={store}>
        <AppHoc />
    </Provider>, document.getElementById('react-root'))