import Cookies from 'js-cookie';
import { castTo } from 'lib-react/universal/utils';

export function setCookie<T>(param: {
    key: string,
    value: T,
    expires?: number
}) {
    const { key, value, expires } = param
    var castedValue = castTo<string>(value)

    if (expires) {
        Cookies.set(key, castedValue, { expires: expires })
    } else {
        Cookies.set(key, castedValue)
    }
}

export function getCookie<T>(param: { key: string, type: "string" | "number" | "boolean", defaultValue?: T }): T {
    const { key, defaultValue, type } = param
    const value = Cookies.get(key)
    if (defaultValue !== undefined && !value) {
        return defaultValue
    }

    if (type === "string") {
        return castTo<T>(value)
    } else if (type === "boolean") {
        if (value !== undefined) {
            if (value === "true" || value === "false") {
                const boolValue = "true" === value
                return castTo<T>(boolValue)
            }
        }
    } else if (type === "number") {
        const intValue = parseInt(value, 10)
        if (!isNaN(intValue)) {
            return castTo<T>(intValue)
        }
    }
}