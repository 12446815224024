export const ANDROID_INTERFACE_NOT_AVAILABLE = "Interfaccia Android non disponibile."

// Android -> JS communication
export class AndroidToJs {
    bindOnQrReadCallback(callback: any) {
        window.onQrRead = callback
    }

    bindOnDocumentSignedCallback(callback: any) {
        window.onDocumentSigned = callback
    }

    bindForceLoadingScreen(callback: any) {
        window.forceLoadingScreen = callback
    }
}



// JS -> Android communication
export interface JsToAndroid {
    startQRReading: () => any
    openDocument: (downloadUri: string) => any
    signDocument: (downloadUri: string, uploadUri: string, csrf: string) => any
    captureImage: (uploadUri: string, csrf: string) => any
}

declare var jsToAndroid: JsToAndroid
export function getJsToAndroidInterface(): JsToAndroid {
    if (isJsToAndroidInterfaceAvailable()) {
        return jsToAndroid
    }
}
export function isJsToAndroidInterfaceAvailable(): boolean {
    try {
        return jsToAndroid && true
    } catch (ReferenceError) {
        // Android interface not available
    }
    return false
}