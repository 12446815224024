import { generateUUID } from 'lib-react/universal/utils';
import React from 'react';
import Alert from './alert';

interface ComponentProps {
    children: any
}

interface ComponentState {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(er: any, info: any) {
        this.setState({ hasError: true })
    }

    render() {
        let errorMessage = "Si è verificato un problema. Contattare l'assistenza."
        const id = generateUUID()
        if (this.state.hasError) {
            return <Alert id={id} closable title={errorMessage} type='error' />
        }
        return this.props.children
    }
}