export const MISC = {
    A_TARGET_NEW_PAGE: '_blank'
}

export const METHOD = {
    POST: 'POST',
    PUT: 'PUT',
    GET: 'GET',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS'
}