import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { History, Location } from "history";
import { FormObject } from "lib-react/web/interfaces/form-object";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Redirect } from "react-router";
import { HashRouter } from "react-router-dom";
import URI from "urijs";
import {
  clone,
  getQueryParams,
  isArray,
  isString,
  logThis
} from "../../universal/utils";
import CsrfInput from "../components/form-components/csrf-input";

export const CSRF_HEADER_NAME = "_csrf_header";
export const CSRF_NAME = "_csrf";

/**
 * Creates a path appending an array of other params to a starting path.
 */
export function composeUriPath(startPath: string, pathParams: string[]) {
  if (!startPath || typeof startPath !== "string") {
    return;
  }
  let completePath = startPath;
  try {
    pathParams.forEach((item) => {
      completePath += "/" + item;
    });
  } catch (TypeError) {
    // NADE (Not a duck error)
  }
  return completePath;
}

/**
 * Prefixes an hash character (#) to an input path string.
 */
export function getHashedPath(path: string) {
  if (!path || typeof path !== "string") {
    return "#";
  }
  return "#" + path;
}

/**
 * Returns the string representation of the rendered component (via ReactDOMServer), in a React router HashRouter context.
 */
export function getRenderedComponentInHashContext(
  component: React.ReactElement<any>
): string {
  return ReactDOMServer.renderToString(<HashRouter>{component}</HashRouter>);
}

export function getRenderedComponent(
  component: React.ReactElement<any>
): string {
  return ReactDOMServer.renderToString(component);
}

/** Prefixes a character (default = "#"") to easily compose identifiers strings, useable with jQuery */
export function createIdentifier(id: string, prefix: string = "#") {
  if (isString(id)) {
    return `${prefix}${id}`;
  }
}

export function redirectTo(destination: string) {
  if (isString(destination)) {
    window.location.href = destination;
  } else {
    throw new TypeError("Wrong input param. It must be a valid string.");
  }
}

export function refreshPage() {
  window.location.reload();
}

/**
 * Read a param passed by Spring via meta tag.
 * @param {String} metaName the name of the meta to be read.
 * @returns {String} containing the response.
 */
export function getContentFromMetaWithName(metaName: string) {
  if (!isString(metaName)) {
    throw new TypeError("Wrong input param. It must be a valid string.");
  }
  try {
    return $("meta[name='" + metaName + "']").attr("content");
  } catch (ReferenceError) {
    logThis("cannot read content from meta with name. jQuery not available");
  }
}

export function renderCsrfInputItem(csrfToken: string) {
  return <CsrfInput csrfTokenValue={csrfToken} />;
}

export function getRedirectComponent(targetUri: string) {
  return <Redirect push to={targetUri} />;
}

export function decodeJsonString(jsonString: string) {
  if (!isString(jsonString)) {
    throw new SyntaxError("The input value must be a string.");
  }
  return JSON.parse(jsonString);
}

export function getCsrfHeader() {
  let csrfHeader: any = {};
  try {
    const csrfHeaderNode = $("meta[name='" + CSRF_HEADER_NAME + "']");
    const csrfNode = $("meta[name='" + CSRF_NAME + "']");
    if (csrfHeaderNode.length && csrfNode.length) {
      if (csrfHeaderNode.attr("content") && csrfNode.attr("content")) {
        csrfHeader[csrfHeaderNode.attr("content")] = csrfNode.attr("content");
      }
    }
  } catch (e) {
    logThis("Error in getCsrfHeader()", e);
  }
  return csrfHeader;
}

export function splitArrayInChunks(array: any[], chunk: number) {
  if (!isArray(array) || chunk <= 0) {
    return array;
  }

  let resultArray = [];
  var i: number = 0;
  var j: number = array.length;
  for (; i < j; i += chunk) {
    resultArray.push(array.slice(i, i + chunk));
  }
  return resultArray;
}

export function getMessageComponent(options: {
  message: string;
  type: "error" | "success";
}) {

  const { message, type } = options;

  if (type === 'error') {
    var icon = 'icon fa fa-ban'
  }

  return (
    <Box paddingTop="2">
      <Alert severity={type} variant="filled">
        <Typography variant="h5">
          <i className={icon} /> {message}
        </Typography>
      </Alert>
    </Box>
  );
}

export function getQueryParamsFromLocation(locationObject: Location): {
  [name: string]: string;
} {
  let result = {};
  try {
    result = URI(locationObject.search).query(true);
  } catch (e) { }
  return result;
}

export function getQueryParamsFromWindowSearch(): { [name: string]: string } {
  let result = {};
  try {
    result = getQueryParams(window.location.search);
  } catch (e) { }
  return result;
}

export function getHeight(dimensionToSubtract: number = 0) {
  try {
    return $(window).height() - dimensionToSubtract;
  } catch (e) {
    logThis("Cannot get the window height via jQuery");
  }
  return 600;
}

export function createDangerousInnerHtml(content: string) {
  return { __html: content };
}

// TODO: testato fino a qui....

export function updateFormDataState(
  formObject: FormObject,
  newFormData: any
): FormObject {
  let clonedFormObject = clone(formObject);
  clonedFormObject.data.form.formData = clone(newFormData);
  return clonedFormObject;
}

export function replaceCurrentHistoryEntry(history: History, newUri: string) {
  history.replace(newUri);
}
