import { Typography }from '@material-ui/core';
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item';
import { isObjectEmpty, renderComponentsList } from 'lib-react/universal/utils';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface Breadcrumb extends ComponentListItem {
    title: string
    url?: string
    icon?: string
}

interface Props {
    title: string
    breadcrumbs?: Breadcrumb[]
}

export default class PageTitle extends React.Component<Props, null> {

    renderBreadcrumb = (item: Breadcrumb) => {
        if (item.url) {
            var view: JSX.Element = (
                <NavLink to={item.url}>
                    <i className={item.icon} />
                    {item.title}
                </NavLink>)
        } else {
            view = (
                <span>{item.title}</span>
            )
        }
        return (
            <li key={item._key}>
                {view}
            </li>
        )
    }

    render() {
        if (isObjectEmpty(this.props)) {
            return null
        }
        let { title, breadcrumbs } = this.props
        if (breadcrumbs) {
            var breadcrumbViews = renderComponentsList(breadcrumbs, this.renderBreadcrumb)
            var breadcrumbComp = (<ol className='breadcrumb'>
                {breadcrumbViews}
            </ol>)
        }
        return (
            <section className='content-header'>
                <Typography>
                    {title}
                </Typography>
                {breadcrumbComp}
                <br />
            </section>
        )
    }
}