import { AusiliItem } from 'commons/interfaces/ausili-item';
import { castTo, clone } from 'lib-react/universal/utils';
import { AusiliAction, AUSILI_CLEAR_AUSILI, AUSILI_CLEAR_RESERVATIONS, AUSILI_RETRIEVE_AUSILI_ITEM, AUSILI_RETRIEVE_AUSILI_ITEMS, AUSILI_RETRIEVE_ENTRY_RESERVATIONS, AUSILI_RETRIEVE_EXIT_RESERVATIONS } from 'redux/actions/ausili-actions';
import { GlobalStateInterface, INITIAL_STATE } from '../store';

export default function (state = INITIAL_STATE.ausili, action: AusiliAction): GlobalStateInterface["ausili"] {
    switch (action.type) {
        case AUSILI_CLEAR_RESERVATIONS: {
            return {
                ...state,
                exitReservationItems: new Map(),
                entryReservationItems: new Map()
            }
        }
        case AUSILI_CLEAR_AUSILI: {
            return {
                ...state,
                ausiliItems: new Map()
            }
        }
        case AUSILI_RETRIEVE_AUSILI_ITEMS: {
            let clonedItems = castTo<Map<string, AusiliItem>>(clone(state.ausiliItems))
            action.ausiliItems.forEach((item: AusiliItem) => {
                clonedItems.set(item.id, item)
            })
            return {
                ...state,
                ausiliItems: clonedItems
            }
        }
        case AUSILI_RETRIEVE_AUSILI_ITEM: {
            let clonedItems = castTo<Map<string, AusiliItem>>(clone(state.ausiliItems))
            clonedItems.set(action.ausilioItem.id, action.ausilioItem)
            return {
                ...state,
                ausiliItems: clonedItems,
                selectedAusilio: action.ausilioItem
            }
        }
        case AUSILI_RETRIEVE_EXIT_RESERVATIONS:
            return {
                ...state,
                exitReservationItems: action.exitReservationItems
            }
        case AUSILI_RETRIEVE_ENTRY_RESERVATIONS:
            return {
                ...state,
                entryReservationItems: action.entryReservationItems
            }
        default:
            return state
    }
}