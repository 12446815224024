import React from "react"
import { dataURItoBlob, shouldRender, setState, isTypeImage, processFiles } from "./jsonformutils"
import { executeAjaxRequest, logThis } from "lib-react/universal/utils"
import { WidgetProps } from "lib-react/web/interfaces/form-interfaces"

function FilesInfo(props: { filesInfo: any }) {
    const { filesInfo } = props
    if (filesInfo.length === 0) {
        return null
    }
    return (
        <ul className="file-info">
            {filesInfo.map((fileInfo: any, key: any) => {
                const { dataURL, name, size, type } = fileInfo
                let sizeLabel = size > 0 ? ', ' + size + ' bytes' : ''
                let typeLabel = type !== "url" ? type : ''
                let typeAndSizeLabel = (sizeLabel !== "" || typeLabel !== "") ? `(${typeLabel}${sizeLabel})` : ""

                if (size > 0) {
                    if (isTypeImage(type)) {
                        var imagePreview = (
                            <img src={dataURL} className="news-preview" />
                        )
                    }

                    const { blob } = dataURItoBlob(dataURL)
                    const openFileUrl = URL.createObjectURL(blob)
                    var openFileLink = (
                        <a href={openFileUrl} target="_blank">
                            (Apri il file)
                        </a>
                    )
                }

                return (
                    <li key={key}>
                        <strong>{name}</strong>
                        {typeAndSizeLabel}
                        &nbsp;
                        {imagePreview}
                        &nbsp;
                        {openFileLink}
                    </li>
                )
            })}
        </ul>
    )
}

function extractFileInfo(dataURLs: any) {
    return dataURLs
        .filter((dataURL: any) => typeof dataURL !== "undefined")
        .map((dataURL: string) => {

            // WARNING: questo è un fix per permettere il recupero asincrono dei file
            // Questo permette al server di trasmettere solamente l'URL del file, e al client
            // di recuperarlo in modo asincrono.
            if (dataURL.startsWith("http")) {
                return {
                    dataURL: dataURL,
                    name: "Caricamento in corso...",
                    size: 0,
                    type: "url",
                }
            }

            const { blob, name } = dataURItoBlob(dataURL);
            return {
                dataURL: dataURL,
                name: name,
                size: blob.size,
                type: blob.type,
            }
        })
}

interface FInfo {}

interface Props extends WidgetProps {
    multiple: boolean
}

interface State {
    values: any[]
    filesInfo: FInfo
}

export default class FileWidget extends React.Component<Props, State>  {
    static FIELD_NAME = 'custom-file-widget'

    static defaultProps = {
        autofocus: false,
        multiple: false,
    }

    inputRef: any

    constructor(props: any) {
        super(props);
        const { value } = props;
        const values = Array.isArray(value) ? value : [value];

        // Se il file è un URL (quindi il server non ha trasmesso un Base64), lo carico in modo asincrono.
        values.forEach((item) => {
            if (item && item.startsWith("http")) {
                this.loadFileFromUrl(item)
            }
        })

        this.state = { values, filesInfo: extractFileInfo(values) };
    }

    loadFileFromUrl = (url: string) => {
        executeAjaxRequest({ url, responseType: "blob" }, (data: any, headers: any) => {
            data.name = this.getFilenameFromHeader(headers["content-disposition"])
            const event: any = {
                target: {
                    files: [data]
                }
            }
            this.onChange(event)
        }, (error: any) => {
            logThis("Si è verificato un errore nel recupero asincrono del file: " + url, error)
        })
    }

    getFilenameFromHeader = (disposition: string) => {
        var filename = "";
        if (disposition && disposition.indexOf('inline') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (shouldRender(this, nextProps, nextState)) {
            // WARNING: questo è un fix per permettere l'ordinamento dei widget
            // Altrimenti, non venivano correttamente aggiornti i widget di tipo file.
            nextState.filesInfo = extractFileInfo([nextProps.value])
            return true
        }
    }

    onChange = (event: any) => {
        const { multiple, onChange } = this.props
        processFiles(event.target.files).then((filesInfo) => {
            const state = {
                values: filesInfo.map((fileInfo: any) => fileInfo.dataURL),
                filesInfo,
            }
            setState(this, state, () => {
                if (multiple) {
                    onChange(state.values)
                } else {
                    onChange(state.values[0])
                }
            })
        })
    }

    render() {
        const { multiple, id, readonly, disabled, autofocus } = this.props
        let { filesInfo } = this.state
        return (
            <div>
                <p>
                    <input
                        ref={(ref) => { this.inputRef = ref }}
                        id={id}
                        type="file"
                        disabled={readonly || disabled}
                        onChange={this.onChange}
                        defaultValue=""
                        autoFocus={autofocus}
                        multiple={multiple}
                    />
                </p>
                <FilesInfo filesInfo={filesInfo} />
            </div>
        )
    }
}