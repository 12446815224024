import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { UserInfo } from 'commons/interfaces//user-info'
import { Navigation } from 'commons/interfaces/navigation'
import { StructureInfo } from 'commons/interfaces/structure-info'
import { isEmptyMap } from 'lib-react/universal/utils'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { PAGES } from 'utils/rest-routes'

interface Props {
    navigationLinks: Navigation
    userInfo: UserInfo
    structureInfo: StructureInfo
}

interface AllProps extends Props, RouteComponentProps<any>, React.Props<any> { }


function NavigationComponent(props: AllProps) {
    const [links, setLinks] = useState<Map<number, { title: string, icon: string, href: string }>>(new Map())


    // solo al mounting
    useEffect(() => {
        createLinksMap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createLinksMap = () => {
        if (props.navigationLinks && props.navigationLinks._links) {
            const navLinks = props.navigationLinks._links
            const linksMap = new Map()
            if (navLinks.ausili) {
                linksMap.set(0, {
                    href: PAGES.AUSILI_LIST,
                    title: 'AUSILI',
                    icon: 'fa fa-list'
                })
            }
            if (navLinks.entryReservations) {
                linksMap.set(1, {
                    href: PAGES.AUSILI_ENTRY,
                    title: 'ENTRATA',
                    icon: 'fa fa-list'
                })
            }
            if (navLinks.exitReservations) {
                linksMap.set(2, {
                    href: PAGES.AUSILI_EXIT,
                    title: 'USCITA',
                    icon: 'fa fa-list'
                })
            }
            if (navLinks.changePassword) {
                linksMap.set(3, {
                    href: PAGES.CHANGE_PASSWORD,
                    title: 'CAMBIA PASSWORD',
                    icon: 'fa fa-lock'
                })
            }
            setLinks(linksMap)
        }
    }

    const renderItem = (index: number, item: { title: string, icon: string }) => {
        let { title } = item
        return (
            <BottomNavigationAction
                key={'bottomMenu' + index}
                label={title}

            />
        )
    }

    const handleChange = (event: any, value: number) => {
        if (links) {
            const uri: string = links.get(value).href
            props.history.push(uri)
        }
    }

    const getActiveLink = () => {
        let activeIndex = 0
        let locationPath = props.location.pathname
        if (links) {
            links.forEach((item, index) => {
                if (locationPath.startsWith(item.href)) {
                    activeIndex = index
                }
            })
        }
        return activeIndex
    }


    if (isEmptyMap(links)) {
        return null
    }

    let categoriesArray: JSX.Element[] = []
    links.forEach(function (value, key) {
        categoriesArray.push(renderItem(key, value))
    })

    const index = getActiveLink()
    return (
        <BottomNavigation
            onChange={handleChange}
            showLabels
            value={index}
        >
            {categoriesArray}
        </BottomNavigation>
    )

}

export default withRouter(NavigationComponent)