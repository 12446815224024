
import { Box, createTheme, darken, Grid, lighten, makeStyles, Paper, Theme } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import PageTitle from 'commons/components/forms/page-title';
import { PageContainer } from 'commons/components/page-container';
import QrComponent from 'commons/components/qr-component';
import { AuxReservation } from 'commons/interfaces/ausili-item';
import { isEmptyMap } from 'lib-react/universal/utils';
import { DataTable } from 'lib-react/web/components/datatable-mui/data-table';
import { defaultReactDataRenderMUI } from 'lib-react/web/components/datatable/datatable-utils';
import ErrorBoundary from 'lib-react/web/components/misc/error-boundary';
import { composeUriPath, getRedirectComponent } from 'lib-react/web/utils/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { RetrieveAuxReservationsInterface } from 'redux/actions/ausili-actions';
import { GlobalStateInterface } from 'redux/store';
import { AndroidToJs, ANDROID_INTERFACE_NOT_AVAILABLE, getJsToAndroidInterface, isJsToAndroidInterfaceAvailable } from 'utils/android';
import { formatDateForDatatable } from 'utils/index';
import { PAGES } from 'utils/rest-routes';
import CustomButton from './custom-button';
import StatusChip from './status-chip';

interface Props {
    loading: boolean
}

interface AllProps extends Props, RouteComponentProps<any> {
    title: string
    dateTitle: string
    reservations: Map<string, AuxReservation>
    getActionButton: (status: string) => string
    retrieveReservations: (opt: RetrieveAuxReservationsInterface) => any
    requestUrl: string
}


const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                '& .super-app-theme--even': {
                    bgcolor: getBackgroundColor(theme.palette.grey[300], theme.palette.type),
                    '&:hover': {
                        bgcolor: getHoverBackgroundColor(theme.palette.grey[300], theme.palette.type),
                    },
                },
            },
        };
    }
);



function AusiliTablePage(props: AllProps) {
    const [selectedAusilio, setSelectedAusilio] = React.useState<string>(undefined)
    const classes = useStyles(defaultTheme);

    const fetchData = () => {
        props.retrieveReservations({ uri: props.requestUrl })
    }

    // mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => fetchData(), [])




    const onQrRead = (qrContent: string) => {
        setSelectedAusilio(qrContent)
    }

    new AndroidToJs().bindOnQrReadCallback(onQrRead)

    const onQrClick = () => {
        if (isJsToAndroidInterfaceAvailable()) {
            getJsToAndroidInterface().startQRReading()
        } else {
            alert(ANDROID_INTERFACE_NOT_AVAILABLE)
        }
    }



    if (selectedAusilio) {
        const redirPath = composeUriPath(PAGES.AUSILI_DETAILS, [selectedAusilio])
        return getRedirectComponent(redirPath)
    }

    const renderDataTable = () => {


        const columns: GridColDef[] = [
            { headerName: "Codice ausilio", flex: 0.55, field: "code" },
            { headerName: "Persona", flex: 1, field: "booker" },
            { headerName: props.dateTitle, flex: 1, field: "date" }, // TODO: attenzione rivedere info
            { headerName: "Stato", flex: 0.5, field: "status", renderCell: defaultReactDataRenderMUI, sortable: false },
            { headerName: " ", flex: 0.5, field: "action", renderCell: defaultReactDataRenderMUI, sortable: false }
        ]
        columns.forEach(item => {
            item.disableColumnMenu = true
        })

        const data = prenotazioniItemsToDataTableData()
        return (
            <DataTable
                columns={columns}
                defaultSortParam="date"
                defaultSortOrder="asc"
                rows={data}
                rowsForPageOptions={[10, 25, 50, 100]}
                loading={props.loading}
                density='comfortable'
                getRowClassName={(params) =>
                    `super-app-theme--${params.getValue(params.id, 'className')}`
                }
                quickSearch={true}
            />
        )
    }

    const prenotazioniItemsToDataTableData = () => {
        const reservations = props.reservations
        const data: any = []
        if (reservations && !isEmptyMap(reservations)) {
            let rowCounter = 0;
            reservations.forEach((item) => {
                const ausilio = item.ausilio
                const booker = item.user
                const bookerLabel = `${booker.firstname} ${booker.lastname}`
                const linkUrl = composeUriPath(PAGES.AUSILI_DETAILS, [ausilio.id])
                const actionButtonText = props.getActionButton(ausilio.status)
                const actionComponent = (
                    <CustomButton
                        component={Link}
                        to={linkUrl}
                        text={actionButtonText}
                    />
                )
                const statusComponent = (
                    <StatusChip
                        ausilio={ausilio}
                    />
                )
                const formattedDate = formatDateForDatatable(item.date)
                const obj = {
                    id: item.id,
                    code: ausilio.code,
                    booker: bookerLabel,
                    date: formattedDate,
                    status: { component: statusComponent },
                    action: { component: actionComponent },
                    className: rowCounter % 2 == 0 ? 'even' : 'odd'
                }
                rowCounter++;
                data.push(obj)
            })
        }
        return data
    }

    const dataTable = renderDataTable()


    return (
        <PageContainer>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <PageTitle title={props.title} />
                </Grid>
                <Grid item xs={2} ><QrComponent
                    onQrClick={onQrClick}
                />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ErrorBoundary>
                        <Paper elevation={1}>
                            <Box className={classes.root}>{dataTable}</Box>
                        </Paper>
                    </ErrorBoundary>
                </Grid>
            </Grid>
        </PageContainer>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
    }
}


export default connect(mapStateToProps)(withRouter(AusiliTablePage))