import { Box, Grid } from '@material-ui/core';
import CustomButton from 'commons/components/custom-button';
import { CustomAusilioNotFoundError } from 'commons/components/errors';
import ExpandableList, { ListItem } from 'commons/components/expandable-list';
import PageTitle from 'commons/components/forms/page-title';
import { PageContainer } from 'commons/components/page-container';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import { MessageInterface, Messages } from 'lib-react/universal/interfaces/message-interface';
import { startLoading, stopLoading } from 'lib-react/universal/redux/base-actions';
import { getMessageByMultipleParams } from 'lib-react/universal/redux/redux-utils';
import { generateUUID, logThis, renderComponentsList } from 'lib-react/universal/utils';
import { isToday } from 'lib-react/universal/utils/date-utils';
import ErrorBoundary from 'lib-react/web/components/misc/error-boundary';
import { CSRF_NAME, getContentFromMetaWithName } from 'lib-react/web/utils/utils';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { performRegistration, PerformRegistrationInterface, retrieveAusilio, RetrieveAusilioInterface } from 'redux/actions/ausili-actions';
import { dispatchRemoveMessage } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { AndroidToJs, ANDROID_INTERFACE_NOT_AVAILABLE, getJsToAndroidInterface, isJsToAndroidInterfaceAvailable } from 'utils/android';
import { ATTACHMENT_STATUS, AUSILI_STATUS, SINGLETON_ERRORS } from 'utils/constants';
import { getLoadingComponent } from 'utils/index';
import AusilioRightSection from "./ausilio-right-section";
import AusilioTable from './ausilio-table';
import { getCookie } from '../../../lib-react/web/utils/cookies';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    retrieveAusilio: (opt: RetrieveAusilioInterface) => any
    dispatchRemoveMessage: (message: MessageInterface) => any
    performRegistration: (opt: PerformRegistrationInterface) => any
}

interface Props {
    structureId: string
    loading: boolean
    ausiliItemUrl?: string
    registerEntryUrl: string
    registerExitUrl: string
    ausilioDoNotUseDirectly: AusiliItem
    messages: Messages
}

export function AusilioDetailPage(props: ActionCreatorsProps & Props & RouteComponentProps<any> & WithSnackbarProps & React.ClassAttributes<any>) {
    const [ausilioId, setAusilioId] = React.useState<string>(props.match.params.id);

    const fetchData = () => {
        props.retrieveAusilio({ uri: props.ausiliItemUrl, id: ausilioId })
    }

    const onDocumentSigned = () => {
        props.stopLoading()
        fetchData()
    }

    const forceLoadingScreen = () => {
        props.startLoading()
    }

    new AndroidToJs().bindOnDocumentSignedCallback(onDocumentSigned)
    new AndroidToJs().bindForceLoadingScreen(forceLoadingScreen)


    // only on mounting
    React.useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setAusilioId(props.match.params.id)
    }, [props.match.params.id])

    // ex componentWillReceiveProps
    React.useEffect(() => {
        const message = getMessageByMultipleParams(props.messages, {
            id: ausilioId,
            errorCode: SINGLETON_ERRORS.REGISTRATION_ERROR
        })
        if (message) {
            // An error message is available. Display it...
            props.enqueueSnackbar("Si è verificato un errore, si prega di riprovare.", { variant: "error", autoHideDuration: 4000 })
            props.dispatchRemoveMessage(message)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.messages, ausilioId])




    const renderButton = (button: any) => {
        return (
            <Box paddingTop={2} paddingBottom={2} key={generateUUID()}>
                {button}
            </Box>
        )
    }

    const renderActionButton = (ausilio: AusiliItem) => {
        var buttons: any[] = []
        if (ausilio) {
            if (ausilio.attachmentStatus) {

                // APRI REPORT (sempre visibile quando esiste un allegato)
                buttons.push(
                    <CustomButton
                        text="APRI REPORT"
                        callback={performPdfOpen}
                    />
                )

                if (ausilio.attachmentStatus === ATTACHMENT_STATUS.TO_SIGN) {
                    // FIRMA REPORT
                    buttons.push(
                        <CustomButton
                            text={"FIRMA REPORT"}
                            callback={performPdfSign}
                        />
                    )
                }
            }

            if (ausilio.status === AUSILI_STATUS.BOOKED_UP) {
                buttons.push(
                    <CustomButton
                        text={"Registra uscita"}
                        callback={() => { performRegistration({ registrationUri: props.registerExitUrl }) }}
                    />
                )
            } else if (ausilio.status === AUSILI_STATUS.BORROWED) {
                // Renderizzo il pulsante di registrazione entrata SOLO se l'ausilio NON è stato consegnato oggi.
                // Il controllo viene fatto sulla data di prenotazione perchè lato server viene sostituita con la
                // data effettiva di uscita ausilio una volta che questo viene consegnato.
                if (!isToday(ausilio.prenotationDate, "DD/MM/YYYY")) {
                    buttons.push(
                        <CustomButton
                            text={"Registra entrata"}
                            callback={() => { performRegistration({ registrationUri: props.registerEntryUrl }) }}
                        />
                    )
                }
            }
            var buttonsView = renderComponentsList(buttons, renderButton)
            return (
                <div>
                    {buttonsView}
                </div>
            )
        }
    }

    const renderLeftSection = (ausilio: AusiliItem) => {
        const actionButton = renderActionButton(ausilio)
        let items: ListItem[] = [
            {
                title: "Descrizione",
                description: ausilio.description,
                expanded: true
            }
        ]

        return (
            <Grid item sm={6}>
                <Box p={2}>
                    <AusilioTable
                        ausilio={ausilio}
                        actionButtons={[actionButton]}
                    />
                </Box>
                <Box p={2}>
                    <ExpandableList
                        items={items}
                    />
                </Box>
            </Grid>
        )
    }

    const renderRightSection = (ausilio: AusiliItem) => {
        return (
            <AusilioRightSection
                ausilio={ausilio}
                captureImageCallback={performCaptureImage}
            />
        )
    }

    const performPdfOpen = () => {
        logThis("Trigger apertura PDF")
        if (getAusilio()) {
            const downloadUri = getAusilio().attachmentDownloadUri
            if (isJsToAndroidInterfaceAvailable()) {
                getJsToAndroidInterface().openDocument(downloadUri)
            } else {
                alert(ANDROID_INTERFACE_NOT_AVAILABLE)
            }
        }
    }

    const performPdfSign = () => {
        logThis("Trigger firma PDF")
        if (getAusilio()) {
            const downloadUri = getAusilio().attachmentDownloadUri
            const uploadUri = getAusilio().attachmentUploadUri
            // const csrf = getContentFromMetaWithName(CSRF_NAME)
            const csrf = getCookie({ key: 'XSRF-TOKEN', type: 'string', defaultValue: getContentFromMetaWithName('_csrf') })
            if (isJsToAndroidInterfaceAvailable()) {
                getJsToAndroidInterface().signDocument(downloadUri, uploadUri, csrf)
            } else {
                alert(ANDROID_INTERFACE_NOT_AVAILABLE)
            }
        }
    }

    const performCaptureImage = () => {
        if (getAusilio()) {
            const uploadUri = getAusilio().imageUploadUri
            // const csrf = getContentFromMetaWithName(CSRF_NAME)
            const csrf = getCookie({ key: 'XSRF-TOKEN', type: 'string', defaultValue: getContentFromMetaWithName('_csrf') })
            if (isJsToAndroidInterfaceAvailable()) {
                getJsToAndroidInterface().captureImage(uploadUri, csrf)
            } else {
                alert(ANDROID_INTERFACE_NOT_AVAILABLE)
            }
        }
    }




    const performRegistration = (opt: { registrationUri: string }) => {
        logThis("Trigger registrazione ", opt.registrationUri)
        if (isJsToAndroidInterfaceAvailable()) {
            const { structureId, ausiliItemUrl } = props
            const { registrationUri } = opt
            props.performRegistration({
                id: ausilioId,
                structureId,
                uri: registrationUri,
                retrieveAusilioUri: ausiliItemUrl
            })
        } else {
            alert(ANDROID_INTERFACE_NOT_AVAILABLE)
        }
    }

    const getAusilio = () => {
        if (props.ausilioDoNotUseDirectly && props.ausilioDoNotUseDirectly.id === ausilioId) {
            return props.ausilioDoNotUseDirectly
        }
    }


    if (props.loading) {
        return getLoadingComponent(true)
    }



    const message = getMessageByMultipleParams(props.messages, {
        id: ausilioId,
        errorCode: SINGLETON_ERRORS.AUSILIO_NOT_FOUND
    })
    if (message) {
        return (
            <CustomAusilioNotFoundError />
        )
    }

    if (getAusilio()) {
        const ausilio = getAusilio()
        if (ausilio) {
            var leftSection = renderLeftSection(ausilio)
            var rightSection = renderRightSection(ausilio)
        }
        var ausilioTitle = ausilio ? `Dettaglio ausilio :: ${ausilio.title}` : "Dettaglio ausilio"
    }
    return (
        <PageContainer>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PageTitle title={ausilioTitle} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <ErrorBoundary>
                    {leftSection}
                    {rightSection}
                </ErrorBoundary>
            </Grid>
        </PageContainer>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        structureId: state.global.structureId,
        loading: state.global.isLoading,
        ausiliItemUrl: state.global.navigationLinks._links.ausiliItem.href,
        ausilioDoNotUseDirectly: state.ausili.selectedAusilio,
        registerEntryUrl: state.global.navigationLinks._links.registerEntry.href,
        registerExitUrl: state.global.navigationLinks._links.registerExit.href,
        messages: state.global.messages
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        retrieveAusilio: bindActionCreators(retrieveAusilio, dispatch),
        dispatchRemoveMessage: bindActionCreators(dispatchRemoveMessage, dispatch),
        performRegistration: bindActionCreators(performRegistration, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(AusilioDetailPage)))