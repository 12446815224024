import { Upload, UploadFileInfo, UploadFileStatus, UploadOnAddEvent, UploadOnRemoveEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import AjaxRequestInterface, { URITemplateInput } from 'lib-react/universal/interfaces/ajax-request';
import { appendQueryParams, castTo, executeAjaxRequest, generateUUID, getExtensionFromFilename, logInfo, logThis } from 'lib-react/universal/utils';
import LoadingComponent from 'lib-react/web/components/misc/loading-component';
import { FieldProps } from 'lib-react/web/interfaces/form-interfaces';
import { executeRequest } from 'lib-react/web/services/ajax-services';
import React from "react";
import InputWrapper from './input-wrapper';
import { Method } from 'axios';

interface Props extends FieldProps {
    id: string
    multiple: boolean
}

interface CustomUploadFileInfo extends UploadFileInfo {
    url: string
    attachKey: string
}

interface State {
    files: CustomUploadFileInfo[]
    loadingFilesUrls: string[]                  // URLs of files currently loading
}

export default class AsyncFileField extends React.Component<Props, State>  {
    static FIELD_NAME = 'custom-async-file-field'

    static defaultProps = {
        autofocus: false,
        multiple: false,
    }

    inputRef: any


    constructor(props: any) {
        super(props)
        this.state = {
            files: [],
            loadingFilesUrls: []
        }
    }

    componentDidMount() {
        const { formData } = this.props
        const values = Array.isArray(formData) ? formData : [formData]

        // Carico in modo asincrono i file trasmessi nel formData
        values.forEach((attachKey) => {
            if (attachKey) {
                this.loadFileFromUrl(attachKey)
            }
        })
    }

    loadFileFromUrl = (attachKey: string) => {
        let params: URITemplateInput = {};
        params[this.props.uiSchema.customAttachParameter || 'innerAttach'] = attachKey
        let url = appendQueryParams(this.props.uiSchema.customUrl, params)
        this.startLoadingForFile(url)
        executeAjaxRequest({ url, responseType: "blob" }, (data: any, headers: any) => {
            const fileName = this.getFilenameFromHeader(headers["content-disposition"])
            const fileInfo: CustomUploadFileInfo = {
                uid: generateUUID(),
                status: UploadFileStatus.Uploaded,
                progress: undefined,
                name: fileName,
                getRawFile: () => {
                    return data
                },
                size: data.size,
                extension: getExtensionFromFilename(fileName),
                url: url,
                attachKey: attachKey
            }

            const { files } = this.state
            files.push(fileInfo)

            this.setState({ files }, () => { this.stopLoadingForFile(url) })

        }, (error) => {
            logThis("Si è verificato un errore nel recupero asincrono del file: " + url, error)

            const ERROR_FILE_INFO: CustomUploadFileInfo = {
                uid: generateUUID(),
                status: UploadFileStatus.RemoveFailed,
                progress: undefined,
                name: "Impossibile recuperare il file",
                getRawFile: () => {
                    return null
                },
                size: undefined,
                extension: undefined,
                url: url,
                attachKey: attachKey
            }
            const { files } = this.state
            files.push(ERROR_FILE_INFO)

            this.setState({ files }, () => { this.stopLoadingForFile(url) })
        })
    }

    startLoadingForFile = (url: string) => {
        const loadingFilesUrls = this.state.loadingFilesUrls
        loadingFilesUrls.push(url)
        this.setState({ loadingFilesUrls })
    }

    stopLoadingForFile = (url: string) => {
        const loadingFilesUrls = this.state.loadingFilesUrls
        const filteredLoadingFiles = loadingFilesUrls.filter((e) => e !== url)
        this.setState({ loadingFilesUrls: filteredLoadingFiles })
    }

    getFilenameFromHeader = (disposition: string) => {
        var filename = ""
        if (disposition && disposition.indexOf('inline') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }
        return filename
    }

    onAdd = (event: UploadOnAddEvent) => {
        const { files } = this.state
        const affectedFiles = event.affectedFiles

        affectedFiles.forEach((file) => {
            files.push(castTo<CustomUploadFileInfo>(file))
        })

        this.setState({ files })
        logInfo(event)
    }

    onUploadStatusChanged = (event: UploadOnStatusChangeEvent) => {
        let { files } = this.state
        const affectedFiles = event.affectedFiles

        affectedFiles.forEach((file) => {
            if (file.status === UploadFileStatus.Uploaded) {
                // Upload completed
                // Decorate with URL from server response
                const url = event.response.response._links.self.href
                const attachKey = event.response.response.key
                let interestedFile: CustomUploadFileInfo = castTo<CustomUploadFileInfo>(file)
                interestedFile.url = url
                interestedFile.attachKey = attachKey

                // Add to formData
                let propsValues = this.props.formData ? this.props.formData : []
                propsValues = Array.isArray(propsValues) ? propsValues : [propsValues]
                propsValues.push(attachKey)
                this.props.onChange(propsValues)

                // Add to state
                files = files.filter((f) => f.uid !== file.uid)
                files.push(interestedFile)
            } else if (file.status === UploadFileStatus.UploadFailed || file.status === UploadFileStatus.RemoveFailed) {
                let interestedFile: CustomUploadFileInfo = castTo<CustomUploadFileInfo>(file)

                // Add to state
                files = files.filter((f) => f.uid !== file.uid)
                files.push(interestedFile)
            }
        })

        this.setState({ files })
        logInfo(event)
    }

    onDelete = (event: UploadOnRemoveEvent) => {
        let { files } = this.state
        const affectedFiles = event.affectedFiles

        affectedFiles.forEach((file) => {
            const interestedFile = files.find((f) => f.uid === file.uid)

            // Delete from formData
            let propsValues = this.props.formData ? this.props.formData : []
            propsValues = Array.isArray(propsValues) ? propsValues : [propsValues]
            propsValues = propsValues.filter((value: string) => value !== interestedFile.attachKey)
            this.props.onChange(propsValues.length > 0 ? propsValues : undefined)

            // Delete from state
            files = files.filter((f) => f.uid !== file.uid)
        })

        this.setState({ files })
        logInfo(event)
    }

    render() {
        const { id, readonly, disabled, schema, required, uiSchema } = this.props
        const { loadingFilesUrls, files } = this.state

        if (loadingFilesUrls.length > 0) {
            return (
                <LoadingComponent fullPage={false} />
            )
        }

        const saveUrl = uiSchema.customUrl
        const deleteUrl = uiSchema.customUrl

        // Per adesso il caricamento multiplo non funziona correttamente
        // Probabilmente per via della concorrenza nelle modifiche di files nello state
        const disabledMultipleBeacauseBugged = false
        const options : any = {
            formData: null,
            requestOptions: {
                url: uiSchema.customUrl,
                method: "DELETE"
            }
        }

        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
            >
                <Upload
                    batch={false}
                    multiple={disabledMultipleBeacauseBugged}
                    files={files}
                    withCredentials={false}
                    saveUrl={saveUrl}
                    // removeUrl={deleteUrl}
                    removeUrl={(fileInfo, prop) => deleteFiles(deleteUrl, 'DELETE', fileInfo, prop)}
                    removeMethod="DELETE"
                    onStatusChange={this.onUploadStatusChanged}
                    onRemove={this.onDelete}
                    onAdd={this.onAdd}
                    id={id}
                    ref={(ref) => {
                        this.inputRef = ref
                    }}
                    disabled={readonly || disabled}
                />
            </InputWrapper>
        )
    }
}

async function deleteFiles(deleteUrl: string, deleteMethod: Method, files : any[], options: { formData: FormData; requestOptions: any; }): Promise<{ uid: string }> {
    return new Promise((resolve, reject) =>{
        files.forEach((file : any) => {
            const formData : FormData = new FormData()
            formData.append('attachKey', file.attachKey)
            const opt : AjaxRequestInterface = {
                url: deleteUrl,
                method: deleteMethod,
                data: formData
            }                
            executeRequest(opt)
                .then(() => {
                    const toReturn = {
                        uid: file.uid
                    }
                    resolve(toReturn)
                }).catch((error) => {
                    reject(error)
                })
        })
    })
}