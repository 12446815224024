import React from 'react';
import { FieldProps } from '../../interfaces/form-interfaces';
import { InputWrapper } from '..';

interface Props extends FieldProps { }

export default class InputField extends React.Component<Props, null> {
    static FIELD_NAME = 'custom-input-field'

    onChange = (event: { target: { value: string } }) => {
        this.props.onChange(event.target.value)
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const inputClassName = uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
            >
                <input
                    defaultValue={this.props.formData}
                    type={uiSchema.customInputType}
                    required={required}
                    max={uiSchema.customMax}
                    min={uiSchema.customMin}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    className={inputClassName}
                    onChange={this.onChange}
                />
            </InputWrapper>
        )
    }
}