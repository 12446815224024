export interface TextualErrorStyle { iconWrapper: any, icon: any, messageWrapper: any }
export const DEFAULT_STYLE: TextualErrorStyle = {
    iconWrapper: {
        textAlign: "center",
        marginTop: 50
    },
    icon: {
        color: "white",
        fontSize: 150
    },
    messageWrapper: {
        fontSize: 30,
        color: "white",
        textAlign: "center"
    },
}