import { Box, Grid } from '@material-ui/core';
import PageTitle from 'commons/components/forms/page-title';
import { PageContainer } from 'commons/components/page-container';
import { getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { startLoading, stopLoading } from 'lib-react/universal/redux/base-actions';
import FormObjectAdapter from 'lib-react/web/adapters/form-object-adapter';
import ErrorBoundary from 'lib-react/web/components/misc/error-boundary';
import { ChangePasswordComponent } from 'lib-react/web/themes/adminlte/components/change-password';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalStateInterface } from 'redux/store';
import { getFormObject } from './form-object';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    isLoading: boolean
    requestUrl: string
}

interface AllProps extends ActionCreatorsProps, Props, RouteComponentProps<any>, React.Props<any> {

}

class ChangePassword extends React.Component<AllProps, null> {

    render() {
        const resources = new FormObjectAdapter(getFormObject())
        resources.url = this.props.requestUrl
        return (
            <ErrorBoundary>
                <PageContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PageTitle title="Password" />
                        </Grid>
                    </Grid>
                    <Box>
                        <ErrorBoundary>
                            <ChangePasswordComponent
                                isLoading={this.props.isLoading}
                                requestUrl={this.props.requestUrl}
                                resources={resources}
                                startLoading={this.props.startLoading}
                                stopLoading={this.props.stopLoading}
                                fields={getCommonFields}
                                widgets={getCommonWidgets}
                                sm={6}
                            />
                        </ErrorBoundary>
                    </Box>
                </PageContainer>

            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        isLoading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.changePassword.href
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword))