import { Box, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridDensity, GridRowParams, GridRowsProp, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import * as React from "react";
import QuickSearchToolbar from './quick-search-toolbar';


interface Props {
    title?: string;
    columns: GridColDef[];
    rows: GridRowsProp;
    noDataText?: string;
    defaultSortParam?: string;
    defaultSortOrder?: GridSortDirection;
    rowsForPageOptions?: number[]
    loading?: boolean
    density?: GridDensity
    quickSearch?: boolean
    /**
     * Function that applies CSS classes dynamically on rows.
     * @param {GridRowParams} params With all properties from [[GridRowParams]].
     * @returns {string} The CSS class to apply to the row.
     */
    getRowClassName?: (params: GridRowParams) => string;
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function DataTable(props: Props) {
    const rowsPerPageOptions = props.rowsForPageOptions || [5, 10, 20]
    const [pageSize, setPageSize] = React.useState<number>(rowsPerPageOptions[0])
    const [filteredRows, setFilteredRows] = React.useState<GridRowsProp>(props.rows)
    const [filter, setFilter] = React.useState<string>(undefined)


    React.useEffect(() => {
        setFilteredRows(props.rows);
    }, [props.rows]);

    if (props.defaultSortOrder && props.defaultSortParam) {
        var sortModel: GridSortModel = [{ field: props.defaultSortParam, sort: props.defaultSortOrder }]
    }
    if (props.title) {
        var titleComp = (<Box display="flex" justifyContent="center" alignItems="center" p={1}>
            <Typography variant="h3">{props.title}</Typography>
        </Box>)
    }

    const onPageSizeChange = (pageSize: number) => {
        setPageSize(pageSize)
    }


    if (props.quickSearch) {
        const requestSearch = (searchValue: string) => {
            setFilter(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = props.rows.filter((row) => {
                return Object.keys(row).some((field) => {
                    return searchRegex.test(row[field].toString());
                });
            });
            setFilteredRows(filteredRows);
        };


        var components = {
            Toolbar: QuickSearchToolbar
        }

        const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => requestSearch(event.target.value)
        const clearSearch: React.MouseEventHandler<HTMLButtonElement> = () => requestSearch('')

        var componentsProps = {
            toolbar: {
                value: filter,
                onChange: onChange,
                clearSearch: clearSearch
            }
        }
    }



    return (
        <Box>
            {titleComp}
            <DataGrid
                components={components}
                componentsProps={componentsProps}
                autoHeight={true}
                sortModel={sortModel}
                columns={props.columns}
                rows={filteredRows}
                rowsPerPageOptions={rowsPerPageOptions}
                pageSize={pageSize}
                loading={props.loading}
                density={props.density}
                getRowClassName={props.getRowClassName}
                disableSelectionOnClick={true}
                onPageSizeChange={onPageSizeChange}
            />


        </Box>
    );
}
