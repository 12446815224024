import { Box, Button, FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FilterComponent } from 'commons/components/filter-component';
import PageTitle from 'commons/components/forms/page-title';
import { PageContainer } from 'commons/components/page-container';
import QrComponent from 'commons/components/qr-component';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import { startLoading, stopLoading } from 'lib-react/universal/redux/base-actions';
import ErrorBoundary from 'lib-react/web/components/misc/error-boundary';
import { composeUriPath, getRedirectComponent } from 'lib-react/web/utils/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveAusiliItems, RetrieveAusiliItemsInterface } from 'redux/actions/ausili-actions';
import { setFilterValue, showImages } from 'redux/actions/ux-actions';
import { GlobalStateInterface } from 'redux/store';
import { AndroidToJs, ANDROID_INTERFACE_NOT_AVAILABLE, getJsToAndroidInterface, isJsToAndroidInterfaceAvailable } from 'utils/android';
import { PAGES } from 'utils/rest-routes';
import { getLoadingComponent } from '../../utils';
import AusiliListComponent from './ausili-list-component';


const styles = makeStyles(() => createStyles({
    removeFilterButton: {
        marginLeft: 10
    },
    removeFilterText: {
        margin: 0,
        padding: 10
    }
}))

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    retrieveAusiliItems: (opt: RetrieveAusiliItemsInterface) => any
    showImages: (show: boolean) => any
    setFilterValue: (filterValue: string) => any
}

interface Props {
    loading: boolean
    requestUrl?: string
    ausiliItems?: Map<string, AusiliItem>
    filterValue: string
    showPics: boolean
}

function AusiliPage(props: ActionCreatorsProps & Props & RouteComponentProps<any>) {
    const [selectedAusilio, setSelectedAusilio] = useState<string>(undefined)
    const [page, setPage] = useState<number>(0)
    const classes = styles()

    const onQrRead = (qrContent: string) => {
        setSelectedAusilio(qrContent)
    }

    new AndroidToJs().bindOnQrReadCallback(onQrRead)

    // mount e cambio filtro
    useEffect(() => {
        function fetchData() {
            const filter = props.filterValue
            props.retrieveAusiliItems({ uri: props.requestUrl, filter, page })
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterValue, page, props.requestUrl]);





    const onQrClick = () => {
        if (isJsToAndroidInterfaceAvailable()) {
            getJsToAndroidInterface().startQRReading()
        } else {
            alert(ANDROID_INTERFACE_NOT_AVAILABLE)
        }
    }





    const onAusilioSelected = (ausilio: AusiliItem) => {
        setSelectedAusilio(ausilio.id)
    }

    const onFilterReset = () => {
        onFilterChange("")
    }

    const onFilterChange = (filterValue: string) => {
        setPage(0)
        props.setFilterValue(filterValue)
        // this.setState({ page: 0 }, () => {
        //     props.setFilterValue(filterValue)
        // })
    }

    const onShowPickChange = (event: any) => {
        props.showImages(event.target.checked)
    }

    const renderSearchTitle = () => {
        if (props.filterValue !== "") {
            const filterValue = props.filterValue

            return (
                <Grid container>
                    <Grid item xs={4}>
                        <PageTitle title={`Risultati ricerca "${filterValue}"`} />
                        <Button className={classes.removeFilterButton} onClick={onFilterReset}>
                            <p className={classes.removeFilterText}>Rimuovi filtro</p>
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    const onMoreItemsButtonClick = () => {
        setPage(page + 1)
    }

    if (selectedAusilio) {
        const redirPath = composeUriPath(PAGES.AUSILI_DETAILS, [selectedAusilio])
        return getRedirectComponent(redirPath)
    }

    const showPics = props.showPics
    const ausiliItems = props.ausiliItems
    const searchTitle = renderSearchTitle()
    if (props.loading && props.ausiliItems.size == 0) {
        var ausiliList = getLoadingComponent(true)
    } else {
        ausiliList = (<AusiliListComponent
            onMoreItemsButtonClick={onMoreItemsButtonClick}
            showPics={showPics}
            ausiliItems={ausiliItems}
            onAusilioSelected={onAusilioSelected}
            loading={props.loading}
        />)
    }


    return (
        <PageContainer>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="secondary"
                                    checked={props.showPics}
                                    onChange={onShowPickChange}
                                    value="checkedA"
                                />
                            }
                            label="Mostra immagini"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item xs={8}><FilterComponent
                            onFilterChange={onFilterChange}
                            filterValue={props.filterValue}
                        /></Grid>
                        <Grid item xs={4}><QrComponent
                            onQrClick={onQrClick}
                        /></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box>
                {searchTitle}
                <ErrorBoundary>
                    {ausiliList}
                </ErrorBoundary>
            </Box>
        </PageContainer>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.ausili.href,
        ausiliItems: state.ausili.ausiliItems,
        showPics: state.ux.showImages,
        filterValue: state.ux.filterValue
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        retrieveAusiliItems: bindActionCreators(retrieveAusiliItems, dispatch),
        showImages: bindActionCreators(showImages, dispatch),
        setFilterValue: bindActionCreators(setFilterValue, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AusiliPage))