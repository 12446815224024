import { UiSchema } from "../interfaces/form-interfaces";
import { StyleRulesObject } from "../interfaces/style-rules-object";
import {
  COMMONS_REPRESENTATION_KEYS,
  FORM_INPUT_DEFAULT_WIDGETS,
  FORM_INPUT_FORMAT,
  represents,
} from "./schema-utils";
import AsyncFileField from "../components/form-components/async-file-field";
import MaskedInputField from "../components/form-components/masked-input-field";
import MaskedDateField from "../components/form-components/masked-date-field";
import FileUrlField from "../components/form-components/file-url-field";
import FileWidget from "../components/form-components/file-widget";
import SwitchBooleanField, {
  SWITCH_BOOLEAN_FIELD_NAME,
} from "../components/form-components/switch-boolean-field";
import IconInputField from "../components/form-components/icon-input-field";
import ArrayExternalPickerField from "../components/form-components/array-external-picker-field";
import AutocompleteListField from "../components/form-components/autocomplete-list-field";
import NumericStepWidget from "../components/form-components/numeric-step-widget";
import RadioField from "../components/form-components/radio-field";
import { FORM_LIST_TYPE } from "./schema-utils";
import MultiSelectField from "../components/form-components/multiselect-field";
import PhoneField from "../components/form-components/phone-field";
import { Field, Widget } from "@rjsf/core";
import { ShowHidePasswordField } from "../components";
import { SHOW_HIDE_PASSWORD_FIELD_NAME } from "../components/form-components/show-hide-password-field";

export function baseFormStyleRules(key: string, object: any): StyleRulesObject {
  let uiObject: UiSchema = {};

  // Not editable field
  if (object.readOnly) {
    uiObject["ui:disabled"] = true;
  }

  // Hidden field
  if (object.hidden) {
    uiObject["ui:widget"] = FORM_INPUT_DEFAULT_WIDGETS.HIDDEN;
  }

  // Date field
  if (
    represents(object.represents, COMMONS_REPRESENTATION_KEYS.DATE) ||
    object.format === FORM_INPUT_FORMAT.DATE
  ) {
    uiObject["ui:field"] = MaskedDateField.FIELD_NAME;
    uiObject.customMax = object.maxDate;
    uiObject.customMin = object.minDate;
  }

  // File field
  if (object.format === FORM_INPUT_FORMAT.FILE) {
    uiObject["ui:widget"] = FileWidget.FIELD_NAME;
  }

  // Async File field
  if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.MULTI_FILE)) {
    uiObject["ui:field"] = AsyncFileField.FIELD_NAME;
    uiObject.customUrl = object.customUrl;
  }

  // FileUrl field
  if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.FILE_URL)) {
    uiObject["ui:field"] = FileUrlField.FIELD_NAME;
  }

  // Time field
  if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.TIME)) {
    uiObject["ui:field"] = MaskedInputField.FIELD_NAME;
    uiObject.customMask = MaskedInputField.MASKS.TIME_MASK;
  }

  //MultiSelect Field
  if (represents(object.represents, FORM_LIST_TYPE.MULTI_SELECT_LIST)) {
    uiObject["ui:field"] = MultiSelectField.FIELD_NAME;
    uiObject.customUrl = object.autocomplete.href;
  }

  // Switch
  if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.SWITCH)) {
    uiObject["ui:field"] = SWITCH_BOOLEAN_FIELD_NAME;
  }

  // Passwords
  if (object.represents === COMMONS_REPRESENTATION_KEYS.PASSWORD) {
    uiObject.customInputType = COMMONS_REPRESENTATION_KEYS.PASSWORD;
  }

  // ShowHidePassword
  if (object.represents === COMMONS_REPRESENTATION_KEYS.SHOW_HIDE_PASSWORD) {
    uiObject["ui:field"] = SHOW_HIDE_PASSWORD_FIELD_NAME;
  }

  return { key: key, value: uiObject };
}

export function getBaseFields(): { [name: string]: Field } {
  let outputObject: any = {};
  outputObject[MaskedDateField.FIELD_NAME] = MaskedDateField;
  outputObject[MaskedInputField.FIELD_NAME] = MaskedInputField;
  outputObject[FileUrlField.FIELD_NAME] = FileUrlField;
  outputObject[AsyncFileField.FIELD_NAME] = AsyncFileField;
  outputObject[FileUrlField.FIELD_NAME] = FileUrlField;
  outputObject[SWITCH_BOOLEAN_FIELD_NAME] = SwitchBooleanField;
  outputObject[SHOW_HIDE_PASSWORD_FIELD_NAME] = ShowHidePasswordField;
  outputObject[IconInputField.FIELD_NAME] = IconInputField;
  outputObject[ArrayExternalPickerField.FIELD_NAME] = ArrayExternalPickerField;
  outputObject[AutocompleteListField.FIELD_NAME] = AutocompleteListField;
  outputObject[RadioField.FIELD_NAME] = RadioField;
  outputObject[MultiSelectField.FIELD_NAME] = MultiSelectField;
  outputObject[PhoneField.FIELD_NAME] = PhoneField;
  return outputObject;
}

export function getBaseWidgets(): { [name: string]: Widget } {
  let outputObject: any = {};
  outputObject[FileWidget.FIELD_NAME] = FileWidget;
  outputObject[NumericStepWidget.FIELD_NAME] = NumericStepWidget;
  return outputObject;
}
