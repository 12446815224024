export const REST_ENDPOINTS = {
    NAVIGATION: '/resource/navigation?structureId={structureId}',
    CHECK_AUTHENTICATION: "/resource/check-authentication"
}

export const BUNDLE_PREFIX = 'http://192.168.4.60:3002'

export const BUNDLE_REST_ENDPOINTS = {
    NAVIGATION: BUNDLE_PREFIX + REST_ENDPOINTS.NAVIGATION,
    ORGANIZATION: "/resource/public/organizations/{id}",
    USER_INFO: "/resource/user",
    CHANGE_PASSWORD: '/resource/user/change-password',
    AUSILI: "/resource/ausili?structureId={structureId}&filter={filter}&page={page}",
    AUSILI_ITEM: "/resource/ausili/{id}",
    AUSILI_ITEM_IMAGE: "/resource/ausili/1/image",
    ENTRY_RESERVATIONS: "/resource/ausili/entry-reservations?structureId={structureId}",
    EXIT_RESERVATIONS: "/recource/ausili/exit-reservations?structureId={structureId}",
    REGISTER_ENTRY: "/resource/ausili/{id}/register-entry?structureId={structureId}",
    REGISTER_EXIT: "/resource/ausili/{id}/register-exit?structureId={structureId}",
    CHECK_AUTHENTICATION: "/resource/check-authentication",
    DOCUMENTS: "/resource/documents/{id}"
}

export const BUNDLE_EXPRESS_ENDPOINTS = {
    PAGE_AUSILI: "/ausili",
    NAVIGATION: "/resource/navigation",
    ORGANIZATION: "/resource/public/organizations/:id",
    ENTRY_RESERVATIONS: "/resource/ausili/entry-reservations",
    EXIT_RESERVATIONS: "/recource/ausili/exit-reservations",
    REGISTER_ENTRY: "/resource/ausili/:id/register-entry",
    REGISTER_EXIT: "/resource/ausili/:id/register-exit",
    AUSILI_ITEM: "/resource/ausili/:id",
    AUSILI_ITEM_IMAGE: "/resource/ausili/:id/image",
    AUSILI: "/resource/ausili",
    DOCUMENTS: "/resource/documents/:id"
}

export const PAGES = {
    LOGOUT: '/logout',
    NOT_FOUND: '/not-found',
    INDEX: '/',
    CHANGE_PASSWORD: '/cambia-password',
    DASHBOARD: '/',
    AUSILI_LIST: '/ausili',
    AUSILI_DETAILS: '/ausili',
    AUSILI_EXIT: '/reservations',
    AUSILI_ENTRY: '/entry'
}

export const WEB_ENDPOINTS = {
    AUSILI: '/ausili{?structureId}',
    ROOT: '/'
}