import { generateUUID } from 'lib-react/universal/utils';
import React from 'react';

export default class Help {
    htmlTitle: string
    htmlContent: string
    uuid: string

    constructor(htmlTitle: string, htmlContent: string) {
        this.htmlTitle = htmlTitle
        this.htmlContent = htmlContent
        this.uuid = generateUUID()
    }

    public createHelpButton = () => {
        return (
            <button
                type="button"
                className="btn btn-info btn-xs form-help"
                data-toggle="modal"
                data-target={"#" + this.uuid}
            >?</button>
        );
    }

    public createModal = () => {
        return (
            <div className="modal fade" id={this.uuid}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div
                            className="modal-header"
                            id="title"
                            dangerouslySetInnerHTML={{
                                __html: this.htmlTitle,
                            }}
                        >
                        </div>
                        <div
                            className="modal-body"
                            id="content"
                            dangerouslySetInnerHTML={{
                                __html: this.htmlContent,
                            }}
                        ></div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}