import { castTo, isArray, isString } from "lib-react/universal/utils";

export interface JsonSchema {
    type?: string
    properties?: any
    items?: JsonSchema
    title?: string
    noTitle?: boolean
    dependencies?: {
        [name: string]: string[] | JsonSchema
    },
    required?: string[],
    oneOf?: JsonSchema[]
}

export interface UiSchemaValue {
    key: string
    value: any
}

export const JSON_SCHEMA_TYPES = {
    NUMBER: 'number',
    ARRAY: 'array',
    BOOLEAN: 'boolean',
    INTEGER: 'integer',
    NULL: 'null',
    OBJECT: 'object',
    STRING: 'string',
    ANY: 'any'
}

export const FORM_INPUT_FORMAT = {
    FILE: 'data-url',
    TEXTAREA: 'textarea',
    DATE: 'date'
}

export const COMMONS_REPRESENTATION_KEYS = {
    USER: 'user',
    PASSWORD: 'password',
    PHONE: 'phone',
    MOBILE_PHONE: 'mobile-phone',
    EMAIL: 'email',
    DATE: 'date',
    TIME: 'time',
    FILE_URL: 'file-url',
    MULTI_FILE: 'multi-file',
    HTML_CONTENT: 'html-content',
    NUMBER: 'number',
    NUMBER_DECIMAL: 'number-decimal',
    SWITCH: 'switch',
    SHOW_HIDE_PASSWORD: 'show-hide-password'
}

export const FORM_INPUT_DEFAULT_WIDGETS = {
    UPDOWN: 'updown',
    CHECKBOXES: 'checkboxes',
    RADIO: 'radio',
    HIDDEN: 'hidden',
    LIST: 'autocomplete-list'
}

export const FORM_LIST_TYPE = {
    COMBO_BOX: 'combo-box',
    DROPDOWN_LIST: 'dropdown-list',
    MULTI_SELECT_LIST: 'multi-select-list'
}

export function hasRequiredProperties(jsonSchema: JsonSchema): boolean {
    if (jsonSchema.required && jsonSchema.required.length > 0) {
        return true
    }

    if (jsonSchema.properties) {
        for (const propertyKey in jsonSchema.properties) {
            if (jsonSchema.properties.hasOwnProperty(propertyKey) && castTo<JsonSchema>(jsonSchema.properties[propertyKey])) {
                const oneOfArray = castTo<JsonSchema>(jsonSchema.properties[propertyKey])
                if (hasRequiredProperties(oneOfArray)) {
                    return true
                }
            }
        }
    }

    if (jsonSchema.dependencies) {
        const depObject = jsonSchema.dependencies
        for (const propertyKey in depObject) {
            if (depObject.hasOwnProperty(propertyKey) && castTo<JsonSchema>(depObject[propertyKey]).oneOf) {
                const oneOfArray = castTo<JsonSchema>(depObject[propertyKey]).oneOf
                for (const oneOfitem of oneOfArray) {
                    if (hasRequiredProperties(oneOfitem)) {
                        return true
                    }
                }
            }
        }
    }

    return false
}

export function generateUiSchema(jsonSchema: JsonSchema, rulesFunction: (item: any, property: any) => UiSchemaValue): any {
    let uiSchema: any = {}
    if (jsonSchema && jsonSchema.type && jsonSchema.type === 'array') {
        if (jsonSchema.noTitle) {
            uiSchema.classNames = 'no-title'
        }
        uiSchema['items'] = generateUiSchema(jsonSchema.items, rulesFunction)
        const { value } = rulesFunction(undefined, jsonSchema)
        uiSchema = { ...uiSchema, ...value }
    } else if (jsonSchema && jsonSchema.type) { // In data 08/06/2020 è stato rimosso controllo jsonSchema.type === 'object'
        if (jsonSchema.noTitle) {
            uiSchema.classNames = 'no-title'
        }
        const { value } = rulesFunction(undefined, jsonSchema)
        uiSchema = { ...uiSchema, ...value }
        generateUiSchemaForObject(jsonSchema, uiSchema, rulesFunction)
        generateUiSchemaForDependencies(jsonSchema, uiSchema, rulesFunction)
    }
    return uiSchema
}

function generateUiSchemaForObject(jsonSchema: JsonSchema, uiSchema: any, rulesFunction: (item: any, property: any) => UiSchemaValue) {
    for (const item in jsonSchema.properties) {
        if (jsonSchema.properties.hasOwnProperty(item)) {
            if (jsonSchema.properties[item].type === 'object' || jsonSchema.properties[item].type === 'array') {
                const childItemUiSchema = generateUiSchema(jsonSchema.properties[item], rulesFunction);
                uiSchema[item] = !uiSchema[item] ? childItemUiSchema : mergeDeep(uiSchema[item], childItemUiSchema)
            } else {
                const {
                    key,
                    value
                } = rulesFunction(item, jsonSchema.properties[item])
                uiSchema[key] = !uiSchema[key] ?  value : mergeDeep(uiSchema[key], value)
            }
        }
    }
    generateUiSchemaForDependencies(jsonSchema, uiSchema, rulesFunction);
}

function generateUiSchemaForDependencies(jsonSchema: JsonSchema, uiSchema: any, rulesFunction: (item: any, property: any) => UiSchemaValue) {
    if (jsonSchema.dependencies && !isArray(jsonSchema.dependencies)) {
        const depObject = jsonSchema.dependencies;
        for (const propertyKey in depObject) {
            if (depObject.hasOwnProperty(propertyKey) && castTo<JsonSchema>(depObject[propertyKey]).oneOf) {
                const oneOfArray = castTo<JsonSchema>(depObject[propertyKey]).oneOf;
                for (const oneOfitem of oneOfArray) {
                    if (oneOfitem && oneOfitem.type && oneOfitem.type === 'object') {
                        generateUiSchemaForObject(oneOfitem, uiSchema, rulesFunction);
                    }
                }
            }
        }
    }

}

function mergeDeep(target: any, source: any): any {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

function isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function represents(source: any, representsKey: string): boolean {
    if (source && representsKey) {
        if (isString(source)) {
            return source === representsKey
        } else if (isArray(source)) {
            const arraySource = castTo<string[]>(source)
            return arraySource.includes(representsKey)
        }
    }
    return false
}