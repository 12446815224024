import { Box, Button, Grid } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import * as React from 'react';

const styles = StyleSheet.create({
    button: {
        marginTop: 10,
        float: "right"
    },
})

interface Props {
    ausilio: AusiliItem
    captureImageCallback: () => any
}

export default class AusilioRightSection extends React.Component<Props, null> {

    render() {
        const ausilio = this.props.ausilio
        const imageSrc = ausilio && ausilio.image ? ausilio.image : "/dist/img/pic_not_available.png"
        if (ausilio && ausilio.imageUploadUri) {
            var catpureImageView = (
                <Box>
                    <Button onClick={this.props.captureImageCallback} className={css(styles.button)}>
                        Scatta nuova foto
                    </Button>
                </Box>
            )
        }
        return (
            <Grid item sm={6}>
                <Box p={2}>
                    <img src={imageSrc} className="ausilio-detail-image" />
                </Box>
                {catpureImageView}
            </Grid>
        )
    }
}