import { Box, Button, Divider, Grid, GridSize }from '@material-ui/core';
import Form from '@rjsf/bootstrap-4';
import { ErrorSchema, Field, IChangeEvent, Widget } from '@rjsf/core';
import React from 'react';
import { clone, generateUUID, trimStringsRecursively } from '../../../../universal/utils';
import { FormObject } from '../../../interfaces/form-object';
import { baseFormStyleRules, getBaseFields, getBaseWidgets } from '../../../utils/base-form-style-rules';
import { generateUiSchema, hasRequiredProperties, UiSchemaValue } from '../../../utils/schema-utils';
import BoxedContent, { BoxType } from '../../misc/boxed-content';
import { transformErrors } from './form-utils';

interface Props {
    bodyContent?: JSX.Element
    buttonText?: string
    children?: React.ReactNode
    fields?: { [name: string]: Field }
    formAction?: string
    formContext?: any
    formId?: string
    formInputItems?: React.ReactNode
    formKey?: string | number // required if you need to refresh the form after a submit
    formMethod?: string
    formStyleRules?: (item: any, property: any) => UiSchemaValue
    headerComponent?: any
    onChange?: (e: IChangeEvent, es?: ErrorSchema) => any;
    onFormReset?: any
    resetButtonText?: string
    resources?: FormObject
    serverResponseComponent?: any
    showButton?: boolean
    showResetButton?: boolean
    showRequiredFieldsLegend?: boolean
    title?: string
    subTitle?: string
    titleView?: any
    tosComponent?: React.ReactNode
    validate?: (formData: any, errors: any) => any;
    widgets?: { [name: string]: Widget };
    onFormSubmit?: (e: any) => any
    trimStrings?: boolean
    onBackButtonClick?: (e: any) => any
    boxStyle?: BoxType
    lg?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xl?: boolean | GridSize;
    xs?: boolean | GridSize;
}


interface AllProps extends Props { }

//TODO mutare la classe a function component, sostituire shouldComponentUpdate con hook simile (React.Memo)
class FormComponent extends React.Component<AllProps, null> {


    static defaultProps: Props = {
        buttonText: 'Invia',
        fields: getBaseFields(),
        formKey: Date.now(),
        formStyleRules: baseFormStyleRules,
        resetButtonText: 'Pulisci',
        showResetButton: false,
        showButton: true,
        widgets: getBaseWidgets(),
        trimStrings: true,
        showRequiredFieldsLegend: true
    }


    shouldComponentUpdate(nextProps: Props) {
        if (!this.props.resources) {
            // The form is not created yet, allow the update.
            return true
        }

        if (this.props.formKey === nextProps.formKey) {
            // An update is called, but the form already exists for the specified key.
            // Do not allow the update, to avoid form data loss.
            return false
        }

        return true
    }

    renderLegend = () => {
        if (this.props.resources) {
            if (this.props.showRequiredFieldsLegend && hasRequiredProperties(this.props.resources.data.form.formSchema)) {
                return (
                    <p className='text-muted'>* Campi obbligatori</p>
                )
            }
        }
    }

    onFormSubmit = (formObject: { formData: object }) => {
        const clonedFormObject: { formData: object } = clone(formObject)
        if (this.props.trimStrings) {
            clonedFormObject.formData = trimStringsRecursively(clonedFormObject.formData)
        }
        this.props.onFormSubmit(clonedFormObject)
    }

    render() {

        const renderButtons = () => {
            const rightButtons = []
            const leftButtons = []
            if (this.props.showButton) {
                rightButtons.push(
                    <Button variant="contained" color="primary" type='submit' key={generateUUID()}>{this.props.buttonText}</Button>
                )
            }
            if (this.props.showResetButton) {
                rightButtons.push(
                    <Button variant="contained" color="primary" onClick={this.props.onFormReset} type='reset' key={generateUUID()}>{this.props.resetButtonText}</Button>
                )
            }
            if (this.props.onBackButtonClick) {
                leftButtons.push(
                    <Button variant="contained" color="primary" onClick={this.props.onBackButtonClick} key={generateUUID()}>Pagina Precedente</Button>
                )
            }
            return <Box >
                <Divider />
                <Grid container={true}>
                    <Grid item={true} xs={6}>
                        <Box display="flex" justifyContent="flex-start" p={1}>{leftButtons}</Box>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Box display="flex" justifyContent="flex-end" p={1}>{rightButtons}</Box>
                    </Grid>
                </Grid>
            </Box>

        }

        if (this.props.resources) {
            let jsonSchema = this.props.resources.data.form.formSchema
            let formData = this.props.resources.data.form.formData
            let rules = this.props.formStyleRules
            let uiSchema = generateUiSchema(jsonSchema, rules)
            var bodyContent = (
                <Form
                    formContext={this.props.formContext}
                    id={this.props.formId}
                    action={this.props.formAction}
                    method={this.props.formMethod}
                    fields={this.props.fields}
                    formData={formData}
                    key={this.props.formKey}
                    onChange={this.props.onChange}
                    onSubmit={this.onFormSubmit}
                    schema={jsonSchema}
                    showErrorList={false}
                    transformErrors={transformErrors}
                    uiSchema={uiSchema}
                    validate={this.props.validate}
                    widgets={this.props.widgets}
                >
                    {this.props.tosComponent}
                    {this.props.formInputItems}
                    {this.renderLegend()}
                    {renderButtons()}
                </Form>
            )
        } else if (this.props.bodyContent) {
            bodyContent = this.props.bodyContent
        } else {
            return null
        }

        if (this.props.titleView) {
            return (
                <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}  >
                    {this.props.titleView}
                    <BoxedContent type={this.props.boxStyle} title={this.props.title} subTitle={this.props.subTitle} boxCustomHeader={this.props.headerComponent}>
                        <Box p={2}>
                            {bodyContent}
                            {this.props.children}
                        </Box>
                        {this.props.serverResponseComponent}
                    </BoxedContent>
                </Grid>
            )
        } else {
            return (
                <BoxedContent type={this.props.boxStyle} title={this.props.title} subTitle={this.props.subTitle} boxCustomHeader={this.props.headerComponent} xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}>
                    <Box p={2}>
                        {bodyContent}
                        {this.props.children}
                    </Box>
                    {this.props.serverResponseComponent}
                </BoxedContent>
            )
        }
    }
}

export default FormComponent;