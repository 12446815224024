import { Box, Container } from '@material-ui/core';
import { PageStyles } from "commons/styles/mui-style";
import ErrorBoundary from "lib-react/web/components/misc/error-boundary";
import React from "react";


export function PageContainer(props: React.PropsWithChildren<any>) {

    const pageStyles = PageStyles();
    return (
        <ErrorBoundary>
            <Box paddingBottom={10} paddingTop={2}>
                <Container maxWidth="lg" className={pageStyles.mainContainer}>
                    {props.children}
                </Container>
            </Box>
        </ErrorBoundary>
    )
}