import { AusiliItem, EntryReservation, ExitReservation } from "commons/interfaces/ausili-item";
import { addMessage, BaseGlobalAction, startLoading, stopLoading } from "lib-react/universal/redux/base-actions";
import { expandUri, generateUUID, logThis } from "lib-react/universal/utils";
import { executeRequest } from "lib-react/web/services/ajax-services";
import { SINGLETON_ERRORS } from "utils/constants";
import { entryReservationItemsArrayToMap, exitReservationItemsArrayToMap, itemsArrayToMap } from "utils/index";
import { Dispatch } from "../../../node_modules/redux";
import { batchActions } from "../../../node_modules/redux-batched-actions";

export const AUSILI_RETRIEVE_AUSILI_ITEMS = "AUSILI_RETRIEVE_AUSILI_ITEMS"
export const AUSILI_RETRIEVE_AUSILI_ITEM = "AUSILI_RETRIEVE_AUSILI_ITEM"
export const AUSILI_RETRIEVE_EXIT_RESERVATIONS = "AUSILI_RETRIEVE_EXIT_RESERVATIONS"
export const AUSILI_RETRIEVE_ENTRY_RESERVATIONS = "AUSILI_RETRIEVE_ENTRY_RESERVATIONS"
export const AUSILI_CLEAR_AUSILI = "AUSILI_CLEAR_AUSILI"
export const AUSILI_CLEAR_RESERVATIONS = "AUSILI_CLEAR_RESERVATIONS"

export interface AusiliAction extends BaseGlobalAction {
    ausiliItems?: Map<string, AusiliItem>
    ausilioItem?: AusiliItem
    exitReservationItems?: Map<string, ExitReservation>
    entryReservationItems?: Map<string, EntryReservation>
}

export interface RetrieveAusiliItemsInterface { uri: string, filter?: string, page?: number }
export function retrieveAusiliItems(opt: RetrieveAusiliItemsInterface) {
    const { uri, filter, page } = opt
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        const expandedUri = expandUri(uri, {
            filter,
            page: "" + page
        })
        executeRequest<AusiliItem[]>({ url: expandedUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveAusiliItemsSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign user info fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveAusiliItemsSuccess(ausiliItems: AusiliItem[]): AusiliAction {
    const agendaItemsMap = itemsArrayToMap(ausiliItems)
    return {
        type: AUSILI_RETRIEVE_AUSILI_ITEMS,
        ausiliItems: agendaItemsMap
    }
}

export interface RetrieveAusilioInterface { uri: string, id: string }
export function retrieveAusilio(opt: RetrieveAusilioInterface) {
    const { uri, id } = opt
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        const expandedUri = expandUri(uri, {
            id: id
        })
        executeRequest<AusiliItem>({ url: expandedUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveAusilioSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error retrieving ausilio item", error)
                return dispatch(batchActions([
                    addMessage({ id: id, errorCode: SINGLETON_ERRORS.AUSILIO_NOT_FOUND }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveAusilioSuccess(ausilioItem: AusiliItem): AusiliAction {
    return {
        type: AUSILI_RETRIEVE_AUSILI_ITEM,
        ausilioItem
    }
}

export interface RetrieveAuxReservationsInterface {
    uri: string
}

export interface RetrieveExitReservationsInterface extends RetrieveAuxReservationsInterface { }
export function retrieveExitReservations(opt: RetrieveExitReservationsInterface) {
    const { uri } = opt
    return (dispatch: Dispatch) => {
        dispatch(batchActions([
            startLoading(),
            clearLocalReservationsMapSuccess()
        ]))
        executeRequest<ExitReservation[]>({ url: uri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveExitReservationsSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign exit reservations fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveExitReservationsSuccess(items: ExitReservation[]): AusiliAction {
    const itemsMap = exitReservationItemsArrayToMap(items)
    return {
        type: AUSILI_RETRIEVE_EXIT_RESERVATIONS,
        exitReservationItems: itemsMap
    }
}

export interface RetrieveEntryReservationsInterface extends RetrieveAuxReservationsInterface { }
export function retrieveEntryReservations(opt: RetrieveEntryReservationsInterface) {
    const { uri } = opt
    return (dispatch: Dispatch) => {
        dispatch(batchActions([
            startLoading(),
            clearLocalReservationsMapSuccess()
        ]))
        executeRequest<EntryReservation[]>({ url: uri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveEntryReservationsSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign entry reservations fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), errorCode: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveEntryReservationsSuccess(items: EntryReservation[]): AusiliAction {
    const itemsMap = entryReservationItemsArrayToMap(items)
    return {
        type: AUSILI_RETRIEVE_ENTRY_RESERVATIONS,
        entryReservationItems: itemsMap
    }
}

export function clearLocalAusiliMapSuccess(): AusiliAction {
    return {
        type: AUSILI_CLEAR_AUSILI
    }
}

export function clearLocalReservationsMapSuccess(): AusiliAction {
    return {
        type: AUSILI_CLEAR_RESERVATIONS
    }
}

export interface PerformRegistrationInterface { uri: string, id: string, structureId: string, retrieveAusilioUri: string }
export function performRegistration(opt: PerformRegistrationInterface) {
    const expandedUri = expandUri(opt.uri, {
        id: opt.id,
        structureId: opt.structureId
    })
    return (dispatch: Dispatch<any>) => {
        dispatch(startLoading())
        executeRequest<any>({ url: expandedUri, method: 'POST' })
            .then(() => {
                dispatch(retrieveAusilio({ uri: opt.retrieveAusilioUri, id: opt.id }))
            })
            .catch((error) => {
                logThis("Error durign perform registration", error)
                return dispatch(batchActions([
                    addMessage({ id: opt.id, errorCode: SINGLETON_ERRORS.REGISTRATION_ERROR }),
                    stopLoading()
                ]))
            })
    }
}
