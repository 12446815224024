import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { css } from 'aphrodite';
import { Custom404Error, CustomGenericError } from 'commons/components/errors';
import AusiliNavigation from 'commons/components/navigation/navigation-menu';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { UserInfo } from 'commons/interfaces/user-info';
import { COMMON_STYLES } from 'commons/styles/common-style';
import { THEME } from 'commons/styles/mui-style';
import { createBrowserHistory, UnregisterCallback } from 'history';
import { Messages } from 'lib-react/universal/interfaces/message-interface';
import { getMessageByMultipleParams } from 'lib-react/universal/redux/redux-utils';
import { logThis } from 'lib-react/universal/utils';
import { checkSession, executePerpetualRequest } from 'lib-react/web/services/ajax-services';
import { getQueryParamsFromWindowSearch } from 'lib-react/web/utils/utils';
import { SnackbarProvider, withSnackbar, WithSnackbarProps } from 'notistack';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveInitializationInfo, saveStructureId } from 'redux/actions/global-actions';
import { GlobalStateInterface, INITIAL_STATE } from 'redux/store';
import configureStore from 'redux/store/configureStore';
import { initApp } from 'utils/app-initializer';
import { SINGLETON_ERRORS } from 'utils/constants';
import { getLoadingComponent } from 'utils/index';
import { PAGES } from 'utils/rest-routes';
import AusilioDetailPage from './ausili-details';
import AusiliEntry from './ausili-entry';
import AusiliExit from './ausili-exit';
import AusiliPage from './ausili-list';
import PasswordChangePage from './change-pwd';

// Initialize the App and Redux store
initApp()
const store = configureStore(INITIAL_STATE)

interface ActionCreatorsProps {
    retrieveInitializationInfo: (structureId: string) => any
    saveStructureId: (structureId: string) => any
}

interface Props {
    navigationLinks: Navigation
    userInfo: UserInfo
    structureInfo: StructureInfo
    messages: Messages
    structureId: string
}

interface AllProps extends ActionCreatorsProps, Props, RouteComponentProps<any>, WithSnackbarProps, React.Props<any> { }

class App extends React.Component<AllProps, null> {
    historyListener: UnregisterCallback

    componentDidMount() {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            this.props.saveStructureId(queryParams.structureId)
        }
        this.props.retrieveInitializationInfo(queryParams.structureId)
    }

    componentDidUpdate() {
        if (!this.historyListener && this.props.navigationLinks && this.props.navigationLinks._links.checkSession) {
            this.historyListener = createBrowserHistory().listen(() => {
                // Check the authentication
                logThis("Check the user authentication.")
                checkSession(this.props.navigationLinks._links.checkSession.href)
            })
            this.startKeepAliveSession()
        }
    }

    startKeepAliveSession = () => {
        const uri = this.props.navigationLinks._links.checkSession.href
        function keepAliveFunction() {
            checkSession(uri)
        }
        executePerpetualRequest(keepAliveFunction, 1000 * 60)  // Every X minutes
    }

    notFoundRoute = () => (
        <Redirect to={PAGES.NOT_FOUND} />
    )

    routes = () => {
        // const showLogoutButton = !isJsToAndroidInterfaceAvailable()
        return (
            <div>
                <CssBaseline />
                <Switch>
                    <Route component={AusiliPage} exact path={PAGES.DASHBOARD} />
                    <Route component={PasswordChangePage} exact path={PAGES.CHANGE_PASSWORD} />
                    <Route component={AusiliPage} exact path={PAGES.AUSILI_LIST} />
                    <Route component={AusilioDetailPage} exact path={PAGES.AUSILI_DETAILS + '/:id'} />
                    <Route component={AusiliExit} exact path={PAGES.AUSILI_EXIT} />
                    <Route component={AusiliEntry} exact path={PAGES.AUSILI_ENTRY} />

                    <Route render={this.notFoundRoute} />
                </Switch>
                <AusiliNavigation
                    navigationLinks={this.props.navigationLinks}
                    userInfo={this.props.userInfo}
                    structureInfo={this.props.structureInfo}
                />
            </div>
        )
    }

    isInitCompleted = () => {
        const { navigationLinks, userInfo, structureInfo } = this.props
        return navigationLinks && userInfo && structureInfo
    }

    render() {
        const message = getMessageByMultipleParams(this.props.messages, {
            errorCode: SINGLETON_ERRORS.INIT_ERROR
        })
        if (message) {
            return CustomGenericError()
        }
        if (!this.isInitCompleted()) {
            return getLoadingComponent(true)
        }
        return (
            <Switch>
                <Route component={Custom404Error} exact path={PAGES.NOT_FOUND} />
                <Route render={this.routes} />
            </Switch>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        userInfo: state.global.userInfo,
        structureInfo: state.global.structureInfo,
        messages: state.global.messages,
        structureId: state.global.structureId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveInitializationInfo: bindActionCreators(retrieveInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

const AppWithSnackbar = withSnackbar(App)
const AppWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWithSnackbar))

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <ThemeProvider theme={THEME}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    classes={{
                        variantError: css(COMMON_STYLES.snackBar)
                    }}
                >
                    <AppWithRouter />
                </SnackbarProvider>
            </ThemeProvider>
        </HashRouter>
    </Provider>, document.getElementById('react-root'))
