import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import React from 'react';

interface ComponentProps {
    fullPage?: boolean
    message?: string
    styleClassName?: string
    semiTransparentBackground?: boolean
    backgroundColor?: string
}

const styles = StyleSheet.create({
    container: {
        borderTop: 0,
        margin: 15
    }
})

export default class LoadingComponent extends React.Component<ComponentProps, null> {
    static defaultProps = {
        fullPage: false,
        semiTransparentBackground: true,
        backgroundColor: 'white'
    }

    render() {
        const { message, fullPage, styleClassName, semiTransparentBackground, backgroundColor } = this.props
        let style = 'overlay'
        if (fullPage) {
            style = classNames(style, 'overlay-full-page')
        }
        const containerStyle = classNames("box", css(styles.container))
        if (!semiTransparentBackground) {
            var backgroundStyle = {
                backgroundColor: backgroundColor
            }
        }
        return (
            <div className={containerStyle}>
                <div className={style} style={backgroundStyle}>
                    <i className='fa fa-refresh fa-spin' />
                    {this.props.message}
                </div>
            </div>
        )
    }
}