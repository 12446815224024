import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import StatusChip from 'commons/components/status-chip';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import * as React from 'react';

const styles = makeStyles(() => createStyles({
    card: {
        width: '100%',
        align: 'center',
    },
    media: {
        width: 'auto',
        height: 100,
        margin: 'auto'
    },
}))

interface Props {
    ausilio: AusiliItem
    showPic: boolean
}

export default function AusilioCard(props: Props) {
    const { ausilio, showPic } = props
    const classes = styles()
    if (showPic) {
        var imageComponent = (
            <CardMedia
                component="img"
                image={ausilio.image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='}
                title={ausilio.title}
                classes={{ media: classes.media }}
            />
        )
    }
    const code = `${ausilio.code}`
    const description = `${ausilio.description}`
    const statusComponent = (
        <StatusChip
            ausilio={ausilio}
        />
    )
    return (
        <Card className={classes.card}>
            <CardActionArea className={classes.card}>
                {imageComponent}
                <CardContent>
                    <Box p={2} textAlign="center">{statusComponent}</Box>
                    <Typography color="textSecondary" align="center" gutterBottom>
                        {code}
                    </Typography >
                    <Typography noWrap align='center' >
                        {description}
                    </Typography >
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
