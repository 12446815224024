import classNames from 'classnames';
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item';
import { castTo, clone, generateUUID, objectToArray, renderComponentsList } from 'lib-react/universal/utils';
import { FieldProps, JsonSchema, UiSchema } from 'lib-react/web/interfaces/form-interfaces';
import React from 'react';
import { MultiSelectField } from '..';
import ComboBoxField from './combo-box-field';
import DropdownListField from './dropdown-list-field';

interface Props extends FieldProps { }

interface State {
    [name: string]: string
}

interface AutocompleteProperty {
    name: string
    jsonSchema: JsonSchema
    uiSchema: UiSchema
    required: boolean
}

/**
 * Questo componente è un wrapper per ComboBoxField e DropdownListField.
 * Può essere usato su campi singoli delle form, oppure su campi composti (oggetti).
 */
export default class AutocompleteListField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-autocomplete-list-field'

    autocompleteId: string

    constructor(props: Props) {
        super(props)
        this.autocompleteId = generateUUID()
        let initialState: State = {}
        if (this.isMultipleFiledsComponent()) {
            initialState = { ...props.formData }
        } else {
            initialState[props.name] = props.formData
        }
        this.state = initialState
    }

    isMultipleFiledsComponent = () => {
        return this.props.schema.properties
    }

    onChange = (name: string, value: string) => {
        this.setState({ [name]: value }, () => {
            if (this.isMultipleFiledsComponent()) {
                this.props.onChange(this.state)
            } else {
                this.props.onChange(value)
            }
        })
    }

    renderAutocompleteList = (autocompleteProperty: AutocompleteProperty & ComponentListItem) => {
        const { jsonSchema, name, uiSchema, required } = autocompleteProperty

        let myProps = clone(this.props)
        myProps.schema = jsonSchema
        myProps.uiSchema = uiSchema
        myProps.idSchema = this.isMultipleFiledsComponent() ? this.props.idSchema[name] : this.props.idSchema
        myProps.formData = this.state[name]
        myProps.required = required
        myProps.onChange = (value: any) => { this.onChange(name, value) }

        let completeClassNames = classNames("form-group field field-string", uiSchema.classNames)
        if (this.props.errorSchema && this.props.errorSchema[name]) {
            var errors = this.props.errorSchema[name].__errors
            completeClassNames = classNames(completeClassNames, "field-error has-error has-danger")
        }

        const filterKey = uiSchema.customFilterKey ? uiSchema.customFilterKey : name

        if (uiSchema.customListComponentType === DropdownListField.FIELD_NAME) {
            return (
                <div className={completeClassNames} key={autocompleteProperty._key}>
                    <DropdownListField
                        {...myProps}
                        key={autocompleteProperty._key}
                        filterKeys={this.state}
                        errors={errors}
                    />
                </div>
            )
        }

        if (uiSchema.customListComponentType === MultiSelectField.FIELD_NAME) {
            return (
                <div className={completeClassNames} key={autocompleteProperty._key}>
                    <MultiSelectField
                        {...myProps}
                        key={autocompleteProperty._key}
                        filterKeys={this.state}
                        errors={errors}
                    />
                </div>
            )
        }

        return (
            <div className={completeClassNames} key={autocompleteProperty._key}>
                <ComboBoxField
                    {...myProps}
                    key={autocompleteProperty._key}
                    filterKey={filterKey}
                    filterKeys={this.state}
                    errors={errors}
                />
            </div>
        )
    }

    render() {
        if (!this.props.schema.properties) {
            // Single field component
            var autocompleteProperties: AutocompleteProperty[] = [{
                name: this.props.name,
                jsonSchema: this.props.schema,
                uiSchema: this.props.uiSchema,
                required: this.props.required
            }]
        } else {
            // Multiple fields component
            autocompleteProperties = objectToArray(this.props.schema.properties, (name: string, schema: any): AutocompleteProperty => {
                const uiSchema = castTo<any>(this.props.uiSchema)
                const required = this.props.schema.required
                return {
                    name: name,
                    jsonSchema: schema,
                    uiSchema: uiSchema[name],
                    required: required && required.lastIndexOf(name) > -1
                }
            })
        }
        let lists = renderComponentsList(autocompleteProperties, this.renderAutocompleteList, this.autocompleteId)
        return (
            <div>
                {lists}
            </div>
        )
    }
}