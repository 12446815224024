import React from 'react';
import MaskedTextInput from 'react-text-mask';
import { FieldProps } from '../../interfaces/form-interfaces';
import { InputWrapper } from '..';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { JSON_SCHEMA_TYPES } from 'lib-react/web/utils/schema-utils';
import emailMask from 'text-mask-addons/dist/emailMask';

interface Props extends FieldProps { }

export const NUMBER_DECIMAL_MASK = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalLimit: 2,
    decimalSymbol: '.'
});

export const GENERIC_NUMBER_MASK = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
});

interface State {
    emailStatus: boolean
}

export default class MaskedInputField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-masked-input-field'
    static MASKS = {
        TIME_MASK: [/[1-9]/, /\d/, ':', /\d/, /\d/]
    }
    constructor(props: any) {
        super(props)
        this.state = {
            emailStatus: true
        }
    }

    onChange = (event: { target: { value: string } }) => {
        const { uiSchema } = this.props
        if (uiSchema.customMask === emailMask) {
            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9\.]+\.[A-Za-z]+$/.test(event.target.value)) {
                this.setState({
                    emailStatus : true
                })
            } else {
                this.setState({
                    emailStatus : false
                })
            }
        }
        if (uiSchema.customInputType === JSON_SCHEMA_TYPES.NUMBER) {
            this.props.onChange(parseFloat(event.target.value))
        } else if (uiSchema.customInputType === JSON_SCHEMA_TYPES.INTEGER) {
            this.props.onChange(parseInt(event.target.value, 10))
        } else {
            this.props.onChange(event.target.value)
        }
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const inputClassName = uiSchema && uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
            >
                <MaskedTextInput
                    mask={uiSchema.customMask}
                    defaultValue={this.props.formData}
                    type={uiSchema.customInputType}
                    required={required}
                    max={uiSchema.customMax}
                    min={uiSchema.customMin}
                    name={this.props.name}
                    placeholder={this.props.placeholder ? this.props.placeholder : uiSchema["ui:placeholder"]}
                    className={inputClassName}
                    onChange={this.onChange}
                    readOnly={uiSchema["ui:disabled"]}
                    step="any"
                    lang="en_EN"
                />
                {uiSchema.customMask === emailMask && !this.state.emailStatus ? <p>Formato E-Mail Errato</p> : null}
            </InputWrapper>
        )
    }
}