// import "setimmediate";

export function dataURItoBlob(dataURI: any) {
    // Split metadata from data
    const splitted = dataURI.split(",");
    // Split params
    const params = splitted[0].split(";");
    // Get mime-type from params
    const type = params[0].replace("data:", "");
    // Filter the name property from params
    const properties = params.filter((param: any) => {
        return param.split("=")[0] === "name";
    });
    // Look for the name and use unknown if no name property.
    if (properties.length !== 1) {
        var name = "unknown";
    } else {
        // Because we filtered out the other property,
        // we only have the name case here.
        name = properties[0].split("=")[1];
    }

    // Built the Uint8Array Blob parameter from the base64 string.
    const binary = atob(splitted[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Create the blob object
    const blob = new window.Blob([new Uint8Array(array)], { type });

    return { blob, name };
}

function isArguments(object: any) {
    return Object.prototype.toString.call(object) === "[object Arguments]";
}

export function shouldRender(comp: any, nextProps: any, nextState: any) {
    const { props, state } = comp;
    return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export function deepEquals(a: any, b: any, ca: any = [], cb: any = []): any {
    // Partially extracted from node-deeper and adapted to exclude comparison
    // checks for functions.
    // https://github.com/othiym23/node-deeper
    if (a === b) {
        return true;
    } else if (typeof a === "function" || typeof b === "function") {
        // Assume all functions are equivalent
        // see https://github.com/mozilla-services/react-jsonschema-form/issues/255
        return true;
    } else if (typeof a !== "object" || typeof b !== "object") {
        return false;
    } else if (a === null || b === null) {
        return false;
    } else if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    } else if (a instanceof RegExp && b instanceof RegExp) {
        return (
            a.source === b.source &&
            a.global === b.global &&
            a.multiline === b.multiline &&
            a.lastIndex === b.lastIndex &&
            a.ignoreCase === b.ignoreCase
        );
    } else if (isArguments(a) || isArguments(b)) {
        if (!(isArguments(a) && isArguments(b))) {
            return false;
        }
        let slice = Array.prototype.slice;
        return deepEquals(slice.call(a), slice.call(b), ca, cb);
    } else {
        if (a.constructor !== b.constructor) {
            return false;
        }

        let ka = Object.keys(a);
        let kb = Object.keys(b);
        // don't bother with stack acrobatics if there's nothing there
        if (ka.length === 0 && kb.length === 0) {
            return true;
        }
        if (ka.length !== kb.length) {
            return false;
        }

        let cal = ca.length;
        while (cal--) {
            if (ca[cal] === a) {
                return cb[cal] === b;
            }
        }
        ca.push(a);
        cb.push(b);

        ka.sort();
        kb.sort();
        for (var j = ka.length - 1; j >= 0; j--) {
            if (ka[j] !== kb[j]) {
                return false;
            }
        }

        let key;
        for (let k = ka.length - 1; k >= 0; k--) {
            key = ka[k];
            if (!deepEquals(a[key], b[key], ca, cb)) {
                return false;
            }
        }

        ca.pop();
        cb.pop();

        return true;
    }
}

export function setState(instance: any, state: any, callback: any) {
    const { safeRenderCompletion } = instance.props;
    if (safeRenderCompletion) {
        instance.setState(state, callback);
    } else {
        instance.setState(state);
        setImmediate(callback);
    }
}

export function isTypeImage(type: string) {
    return type === 'image/png' || type === 'image/jpeg' || type === 'image/gif'
}

export function addNameToDataURL(dataURL: any, name: any) {
    return dataURL.replace(";base64", `;name=${name};base64`);
}

export function processFile(file: any) {
    const { name, size, type } = file
    let win: any = window
    return new Promise((resolve, reject) => {
        const reader = new win.FileReader()
        reader.onload = (event: any) => {
            resolve({
                dataURL: addNameToDataURL(event.target.result, name),
                name,
                size,
                type,
            });
        };
        reader.readAsDataURL(file);
    });
}

export function processFiles(files: any) {
    return Promise.all([].map.call(files, processFile));
}