import { Box, Grid } from '@material-ui/core';
import Card from 'commons/components/ausilio-card';
import CustomButton from 'commons/components/custom-button';
import { AusiliItem } from 'commons/interfaces/ausili-item';
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item';
import { generateUUID, isEmptyMap, renderComponentsList } from 'lib-react/universal/utils';
import * as React from 'react';
import { getLoadingComponent, mapToItemsArray } from '../../utils';

interface Props {
    ausiliItems: Map<string, AusiliItem>
    onAusilioSelected: (ausilio: AusiliItem) => any
    onMoreItemsButtonClick: () => any
    showPics: boolean
    loading: boolean
}


export default function AusiliListComponent(props: Props) {

    const renderAusiliCard = (item: AusiliItem & ComponentListItem) => {
        return (
            <Grid item xs={12} sm={8} md={4} lg={3} key={item._key}>
                <a onClick={() => { props.onAusilioSelected(item) }}>
                    <Card
                        ausilio={item}
                        showPic={props.showPics}
                    />
                </a>
            </Grid>
        )
    }

    const onMoreItemsButtonClick = () => {
        props.onMoreItemsButtonClick()
    }

    const renderMoreItemsButton = () => {
        if (props.loading) {
            return getLoadingComponent(false)
        }
        return (
            <CustomButton
                text="Altro..." callback={onMoreItemsButtonClick} />
        )
    }

    if (props.ausiliItems && !isEmptyMap(props.ausiliItems)) {
        const itemsArray = mapToItemsArray(props.ausiliItems)
        var ausiliCardViews = renderComponentsList(itemsArray, renderAusiliCard)
        var moreItemsButton = renderMoreItemsButton()
    }
    return (
        <Grid container spacing={3}>
            {ausiliCardViews}
            <Grid item xl={12} key={generateUUID()}>
                <Box flex textAlign="center">
                    {moreItemsButton}
                </Box>
            </Grid>
        </Grid>
    )
}