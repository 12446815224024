



















// TODO da rivedere da qui in poi








export const ICONS = {
    head: "glyphicon glyphicon-user form-control-feedback",
    email: "glyphicon glyphicon-envelope form-control-feedback",
    lock: "glyphicon glyphicon-lock form-control-feedback"
}

let STYLE: any = {
    formGroupClass: "form-group has-feedback",
    formInputClass: "form-control",

    widthCheckbox: "col-xs-8",
    checkboxStyle: "checkbox icheck icheckbox_square-blue ",
    widthEnterButton: "col-xs-4",
    widthRegisterButton: "col-xs-6",
    enterButtonStyle: "btn btn-primary btn-block btn-flat",
    rowClass: "row",

    defaultWidth: "col-md-4",

    widths: {
        'firstName': "col-md-4",
        'lastName': "col-md-4"
    },

    icons: {
        'username': ICONS.head,
        'name': ICONS.head,
        'firstNameParent': ICONS.head,
        'lastName': ICONS.head,
        'email': ICONS.email,
        'retype-email': ICONS.email,
        'password': ICONS.lock,
        'retype-password': ICONS.lock,
        'positive': "fa fa-check-circle-o text-green",
        'negative': "fa fa-times-circle-o text-red"
    },
    tags: {},
    types: {},
    paymentStatus: {}
}

export default STYLE