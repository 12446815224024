import { Theme } from '@material-ui/core';
import { createStyles, makeStyles, StyleRules } from '@material-ui/styles';


/** 
* createStyles aiuta Typescript nel type inference durante la compilazione (vedi TypeScript's type widening)
* 
* makeStyles è un Hook generator
 */


//TODO: icone spostate dalla cartella di AdminLTE. Sarebbero da usare le icone
// di material (svg) invece che le glyphicons
export const ICONS = {
  head: 'glyphicon glyphicon-user form-control-feedback',
  email: 'glyphicon glyphicon-envelope form-control-feedback',
  lock: 'glyphicon glyphicon-lock form-control-feedback',
  phone: 'glyphicon glyphicon-phone form-control-feedback',
  calendar: 'glyphicon glyphicon-calendar form-control-feedback'
}

const drawerWidth = 240;

export const BoxStyles = makeStyles((theme: Theme) =>
createStyles({
  box: {
    position: "relative",
    borderRadius: "3px",
    background: "#ffffff",
    marginBottom: "20px",
    width: "100%",
    borderColor: "#d2d6de",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column"
  },
  box_info: {
    borderTopColor:  theme.palette.info.main
  },
  box_primary: {
    borderTopColor: theme.palette.primary.main
  },
  box_warning: {
    borderTopColor: theme.palette.warning.main
  },
  box_danger: {
    borderTopColor:  theme.palette.error.main
  },
  box_footer: {
    padding: "10px",
    backgroundColor: "#ffffff",
    borderTop: "1px solid #f4f4f4",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  box_header_box_title: {
    display: "inline-block",
    fontSize: "18px",
    margin: 0,
    lineHeight: 1
  },
  box_header: {
    color: "#444",
    display: "block",
    padding: "10px",
    position: "relative"
  },
  box_header_with_border: {
    borderBottom: "1px solid #f4f4f4"
  },
  box_body: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "3px",
    padding: "10px"
  },
  box_body_table: {
    marginBottom: 0
  },
  buttonLeft: {
    float: "left"
  },
  buttonRight: {
    float: "right"
  },
  pull_right: {
    float: "right"
  },
  box_title: {
    fontSize: "18px",
    margin: 0,
    lineHeight: 1,
    align: "center"
  },
  box_box_solid: {
    borderTop: 0
  },
  button: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    '&:hover': {
      background: '#337ab7',
    },
  },
  table_responsive: {
    minHeight: "0.1%",
    overflowX: "auto"
  },
  no_padding: {
    padding: 0
  },
  text_center: {
    textAlign: "center"
  },
  page_header_icon_img: {
    height: "5em"
  }

}));


export const ButtonsStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWithMargin: {
      margin: theme.spacing(1)
    },
    warningStyle: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark
      }
    },
    errorStyle: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark
      }
    },
    logoutButton: {
      position: "relative",
      display: "block",
      padding: "10px 15px",
      height: "50px",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.1)"
      }
    },
    submitButton: {
      padding: "5px 15px",
      background: "transparent",
      border: "0 none",
      color: "white",
      cursor: "pointer",
      "&:focus": {
        outline: "none"
      }
    }
  })
)

export const NavStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })
    },
    // appBarShift: {
    //   marginLeft: drawerWidth,
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
    menuButton: {
      marginLeft: 18,
      flexGrow: 1,
    },
    // menuButtonOpen: {
    //   paddingLeft: 18
    // },
    largeIcon: {
      width: 30,
      height: 30
    },
    hide: {
      display: 'none',
    },
    logout: {
      float: 'right'
    },
    drawer: {
      background: theme.palette.secondary.main,
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      ...theme.mixins.toolbar
    },
    drawerOpen: {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      whiteSpace: "normal"
    },
    drawerClose: {
      background: theme.palette.secondary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 60,
      whiteSpace: "nowrap"
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1
    },
    logoBoxOpen: {
      height: "64px",
      width: 240,
      backgroundColor: theme.palette.primary.main,
    },
    logoBoxClose: {
      height: "64px",
      width: 60,
      backgroundColor: theme.palette.primary.main,
    },
    logo_mini: {
      display: "block",
      marginLeft: "-15px",
      marginRight: "-15px",
      fontSize: "18px"
    },
    LogoTitle: {
      color: theme.palette.primary.contrastText
    },
    // user_image: {
    //   float: "left",
    //   width: "25px",
    //   height: "25px",
    //   borderRadius: "50%",
    //   marginRight: "10px",
    //   marginTop: "-2px",
    //   '@media (max-width: 767px)': {
    //     float: "none",
    //     marginRight: "0",
    //     marginTop: "-8px",
    //     lineHeight: "10px"
    //   }
    // },
    ListRoot: {
      width: '100%',
      // maxWidth: drawerWidth,
    },
    ListItem: {
      paddingTop: 0,
      paddingBottom: 0,
      borderLeft: 5,
      borderLeftStyle: "solid",
      borderLeftColor: "transparent",
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.primary.main
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.primary.main
      },
      width: '100%',
    },
    ListItemActive: {
      borderColor: theme.palette.primary.main
    },
    ItemLabelText: {
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        color: theme.palette.secondary.contrastText
      },
      "&:focus": {
        color: theme.palette.secondary.contrastText
      }
    }
  }),
)

export const CarouselStyles = makeStyles(() =>
  createStyles({
    carousel_control_right_left: {
      backgroundImage: "none"
    },
    carousel_control_fa: {
      fontSize: "40px",
      position: "absolute",
      top: "50%",
      zIndex: 5,
      display: "inline-block",
      marginTop: "-20px"
    },
    carousel_control: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "15%",
      fontSize: "20px",
      color: "#fff",
      textAlign: "center",
      textShadow: "0 1px 2px rgba(0, 0, 0, .6)",
      backgroundColor: "rgba(0, 0, 0, 0)",
      filter: "alpha(opacity=50)",
      opacity: ".5"
    },
    carouselControl_left: {
      backgroundImage: "-webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%)",
      filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1)",
      backgroundRepeat: "repeat-x"
    },
    carousel_control_right: {
      right: 0,
      left: "auto",
      backgroundImage: "-webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%)",
      filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1)",
      backgroundRepeat: "repeat-x"
    }
  })
)

export const PagesStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minHeight: "250px",
      padding: "15px",
      marginRight: "auto",
      marginLeft: "auto",
      paddingLeft: "15px",
      paddingRight: "15px"
    },
    content_body: {
      minHeight: "250px",
      padding: "15px",
      paddingLeft: "15px",
      paddingRight: "15px"
    },
    content_wrapper: {
      flexGrow: 1,
      padding: theme.spacing(3),
      background: theme.palette.grey[100],
      flexDirection: "column",
      ...theme.mixins.toolbar,
      paddingTop: "64px",
      maxWidth: "100%",
      justifyContent: "center",
      style: { background: theme.palette.primary.light }
    },
    title: {
      justifyContent: "center",
      paddingTop: "15px",
      paddingBottom: "15px"
    },
    content_header: {
      position: "relative",
      padding: "15px 15px 0 15px"
    },
    content_header_h1: {
      margin: 0,
      fontSize: "24px"
    },
    login_page: {
      flexGrow: 1,
      padding: theme.spacing(3),
      flexDirection: "column",
      ...theme.mixins.toolbar,
      paddingTop: "64px",
      maxWidth: "100%",
      justifyContent: "center",
      style: { background: theme.palette.primary.light }
    },
    manager_page: {
      flexGrow: 1,
      padding: theme.spacing(3),
      flexDirection: "column",
      ...theme.mixins.toolbar,
      paddingTop: "64px",
      minHeight: "250px",
      maxWidth: "100%",
      justifyContent: "center",
      style: { background: theme.palette.primary.light }
    },
    table: {
      flexGrow: 1,
      padding: theme.spacing(3),
      flexDirection: "column",
      ...theme.mixins.toolbar,
      paddingTop: "80px",
      minHeight: "250px",
      maxWidth: "100%",
      justifyContent: "center",
      style: { background: theme.palette.primary.light }
    },
    drawer: {
      background: theme.palette.secondary.main,
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      ...theme.mixins.toolbar
    },
    show_hide_password: {
      fontSize: '16px'
    }

  })
)

//TODO: capire come iniettare il tipo di style e ritornare un hook con il tipo uguale allo stile originale
// JssHookGenerator: ClassNameMap < typeof Style >
export const JssHookGenerator = (Style: StyleRules) => {
  return makeStyles((theme: Theme) => Style)
}