import { baseFormStyleRules } from 'lib-react/web/utils/base-form-style-rules';
import { UiSchema } from 'lib-react/web/interfaces/form-interfaces';

export function styleRules(key: string, object: any) {
    let uiObject: UiSchema = baseFormStyleRules(key, object).value

    uiObject['classNames'] = 'col-xs-12'

    if (object.represents === 'password') {
        uiObject['ui:widget'] = 'password'
    }
    return {
        key: key,
        value: uiObject
    }
}