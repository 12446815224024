import React from 'react'

interface Props {
    errorImageUri: string
}

export default class Error400 extends React.Component<Props, null> {
    render() {
        const { errorImageUri } = this.props
        return (
            <div className='error-page-parent'>
                <div className='error-page'>
                    <img className='img-responsive center-block' src={errorImageUri} />
                </div>
            </div>
        )
    }
}