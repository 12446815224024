import { FormControlLabel, Switch, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FieldProps } from 'lib-react/web/interfaces/form-interfaces';
import React, { useEffect, useState } from 'react';

interface Props extends FieldProps { }

const useStyles = makeStyles((theme: Theme) => (
  
  {
  root: {
    width: "50px",
    height: "24px",
    padding: 0,
    marginLeft: "1em",
    overflow: "visible"
  },
  switchBase: {
    color: theme.palette.grey[500],
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    color: theme.palette.primary.contrastText,
    width: "20px",
    height: "20px",
    margin: "1px"
  },
  track: {
    borderRadius: "20px",
    backgroundColor: theme.palette.grey[500],
    opacity: "1 !important",
    "&:after, &:before": {
      color: theme.palette.primary.contrastText,
      fontSize: "11px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'Sì'",
      left: "8px"
    },
    "&:before": {
      content: "'No'",
      right: "7px"
    }
  },
  checked: {
    color: theme.palette.primary.main + " !important",
    transform: "translateX(26px) !important"
  }
}))

export default function SwitchBooleanField(props: Props) {
  const classes = useStyles();
  const [active, setActive] = useState<boolean>(props.formData || false)

  const onClick = () => {
    const newValue = !active
    setActive(newValue)
  }

  useEffect(() => {
    props.onChange(active)
  }, [active]);

  return (
    <FormControlLabel
      control={
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          checked={active}
          onClick={onClick}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />}
      label={props.schema.title}
      labelPlacement="start"
    />

  )

}

export const SWITCH_BOOLEAN_FIELD_NAME = 'custom-switch-boolean-field'