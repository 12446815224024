import React from 'react';
import { FieldProps, FormContext } from '../../interfaces/form-interfaces';
import { isEmptyArray } from 'lib-react/universal/utils';

const DEFAULT_LABEL_QUANTITY_0 = "Nessun elemento selezionato"
const DEFAULT_LABEL_QUANTITY_1 = "1 elemento selezionato"
const DEFAULT_LABEL_QUANTITY_N = "elementi selezionati"
const DEFAULT_LABEL_PICK_ITEMS = "Seleziona elementi"

export interface ArrayExternalPickerFieldContext extends FormContext {
    pickItems: (onItemsPicked: (items: any[]) => any, alreadyPickedItems: string[]) => any
    labelQuantityZero?: string
    labelQuantityOne?: string
    labelQuantityMulti?: string
    labelPickItems?: string
}

interface Props extends FieldProps {
    formContext: ArrayExternalPickerFieldContext
    items: any[]
}

function createOnItemsPickedClosure(context: { props: Props }) {
    var that = context
    function onItemsPicked(items: any[]) {
        that.props.onChange(items)
    }
    return onItemsPicked
}

export default class ArrayExternalPickerField extends React.Component<Props, null> {
    static FIELD_NAME = 'custom-array-external-picker-field'

    constructor(props: Props) {
        super(props)
    }

    pickItems = () => {
        this.props.formContext.pickItems(createOnItemsPickedClosure(this), this.props.formData)
    }

    getQuantityLabel = () => {
        const { formData, formContext } = this.props
        const items = formData && !isEmptyArray(formData) ? formData : []
        if (items.length === 0) {
            var quantityLabel = formContext && formContext.labelQuantityZero ? formContext.labelQuantityZero : DEFAULT_LABEL_QUANTITY_0
        } else if (items.length === 1) {
            quantityLabel = formContext && formContext.labelQuantityOne ? formContext.labelQuantityOne : DEFAULT_LABEL_QUANTITY_1
        } else {
            const label = formContext && formContext.labelQuantityMulti ? formContext.labelQuantityMulti : DEFAULT_LABEL_QUANTITY_N
            quantityLabel = `${items.length} ${label}`
        }
        return quantityLabel
    }

    getPickItemsLabel = () => {
        return this.props.formContext && this.props.formContext.labelPickItems ? this.props.formContext.labelPickItems : DEFAULT_LABEL_PICK_ITEMS
    }

    render() {
        const quantityLabel = this.getQuantityLabel()
        const pickItemsLabel = this.getPickItemsLabel()
        return (
            <div>
                <div onClick={this.pickItems} className="btn btn-primary">{pickItemsLabel}</div> {quantityLabel}
            </div>
        )
    }
}