
import { Box, Divider, Grid, GridProps, GridSize, Typography } from '@material-ui/core';
import { ComponentListItem } from "lib-react/universal/interfaces/component-list-item";
import { castTo, renderComponentsList } from "lib-react/universal/utils";
import React from "react";


export type BoxType = 'error' | 'info' | 'warning' | 'success' | 'primary' | 'grey200' | 'grey300' | 'grey400' | 'grey500'

interface Props extends GridProps {
    title?: string
    titleIcon?: JSX.Element
    boxCustomHeader?: any
    subTitle?: string
    description?: string | string[]
    borderTop?: boolean
    type?: BoxType
    gridItem?: boolean
    textAlign?: string
    titleBorder?: boolean
    children: JSX.Element | JSX.Element[]
    // content: JSX.Element
}

function BoxedContent(props: Props) {

    // <Box className={clsx(classesBox.box, classesBox.box_primary)}>
    //     <Box className={classesBox.box_header}>
    //         <Typography className={classesBox.box_title}>{title}</Typography>
    //     </Box>
    //     <Box className={clsx(classesBox.box_body, classesBox.table_responsive, classesBox.no_padding)}>
    //         <Datatable
    //             order={[[0, "desc"], [1, "desc"]]}
    //             columns={TABLE_COLUMNS}
    //             data={datatablesData}
    //         />
    //     </Box>
    // </Box>

    let borderColor
    switch (props.type) {
        case 'primary':
            borderColor = "primary.main"
            break;
        case 'warning':
            borderColor = "warning.main"
            break;
        case 'error':
            borderColor = "error.main"
            break;
        case 'info':
            borderColor = "info.main"
            break;
        case 'success':
            borderColor = "success.main"
            break;
        case 'grey200':
            borderColor = "grey.200"
            break;
        case 'grey300':
            borderColor = "grey.300"
            break;
        case 'grey400':
            borderColor = "grey.400"
            break;
        case 'grey500':
            borderColor = "grey.500"
            break;
    }

    if (props.title) {
        if (props.titleIcon) {
            var icon = (<Box marginRight={1} display="inline">{props.titleIcon}</Box>)
        }
        var title = (<Box p={1} textAlign="center"><Typography variant="h3">{icon}{props.title}</Typography></Box>)
    }

    if (props.subTitle) {
        var subTitle = (<Box p={1} textAlign="center"><Typography variant="h5">{icon}{props.subTitle}</Typography></Box>)
    }



    if (props.description) {
        let lines: { line: string }[]
        if (props.description instanceof String) {
            lines = [{ line: castTo<string>(props.description) }]
        } else {
            lines = castTo<string[]>(props.description).map(s => { return { line: s } })
        }
        let items = renderComponentsList(lines, (line: { line: string } & ComponentListItem) => { return (<Typography variant="body2" key={line._key}>{line.line}</Typography>) })
        var description = (<Box textAlign="center">{items}</Box>)
    }

    if (props.titleBorder) {
        var divider = (<Divider />)
    }
    let borderTop = props.borderTop ? 3 : 0

    const box = (<Box borderTop={borderTop} borderColor={borderColor} borderRadius={1} boxShadow={3} bgcolor="background.paper" alignContent={props.textAlign}>
        {props.boxCustomHeader}
        {title}
        {subTitle}
        {description}
        {divider}
        {props.children}
    </Box>)

    if (props.gridItem) {
        return (
            <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}  >
                {box}
            </Grid>
        )
    } else {
        return box
    }
}

BoxedContent.defaultProps = {
    type: "primary",
    gridItem: true,
    xs: 12 as GridSize,
    titleBorder: false,
    borderTop: true
}

export default BoxedContent