import PageTitle from 'commons/components/forms/page-title';
import { AusiliItem, EntryReservation, ExitReservation } from 'commons/interfaces/ausili-item';
import { StructureInfo } from 'commons/interfaces/structure-info';
import STYLE from 'commons/styles/style';
import { generateUUID, logThis } from 'lib-react/universal/utils';
import { formatDateObject, FORMAT_ISO8601_STROKES, FORMAT_UNI_EN28601_STROKES, getDateFromString } from 'lib-react/universal/utils/date-utils';
import LoadingComponent from 'lib-react/web/components/misc/loading-componentMaterial';
import { getHeight } from 'lib-react/web/utils/utils';
import PerfectScrollbar from 'perfect-scrollbar';
import * as React from 'react';
import { PAGES } from 'utils/rest-routes';


export function getLoadingComponent(fullPage: boolean) {
    return (
        <LoadingComponent
            fullPage={fullPage}
            semiTransparentBackground={true}
        />
    )
}

export function parseEnrollmentIdFromResponse(responseObject: { id: string }) {
    if (responseObject.id) {
        return responseObject.id
    }
    throw new Error('Id not set')
}

export function getTitleViewForPage(opt: { page: string, id?: string, structureInfo: StructureInfo }) {
    let titleView = null
    const { page } = opt

    let homeBreadcrumb = {
        _key: generateUUID(),
        _itemIndex: 0,
        _itemNumber: 1,
        title: 'Home',
        url: PAGES.DASHBOARD,
        icon: 'fa fa-dashboard'
    }

    switch (page) {
        case PAGES.DASHBOARD:
            homeBreadcrumb.url = null
            titleView = {
                title: homeBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb]
            }
            break
    }

    return (
        <PageTitle
            breadcrumbs={titleView.breadcrumbs}
            title={titleView.title}
        />
    )
}

export function getFunctionName(requestType: string, structureInfo: StructureInfo) {
    let labels = structureInfo.registrationLabels;
    if (labels) {
        return labels[requestType] || (STYLE.types[requestType] ? STYLE.types[requestType].label : requestType);
    }
    return STYLE.types[requestType] ? STYLE.types[requestType].label : requestType;
}

export function getTotemContentHeight() {
    const tabsContainerHeight = $('.tabs-container').height()
    return getHeight() - tabsContainerHeight - 1
}

export function updateScrollbar(widget: PerfectScrollbar, selector: string) {
    if (widget) {
        widget.update()
    } else {
        try {
            widget = new PerfectScrollbar(selector, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 7,
                suppressScrollX: true
            })
        } catch (e) {
            logThis("Cannot initialize or update the scrollbar", e)
        }
    }
    return widget
}

// TODO: rendere parametrico e spostare in librerie
export function itemsArrayToMap(items: AusiliItem[]) {
    const map = new Map<string, AusiliItem>()
    for (const item of items) {
        map.set(item.id, item)
    }
    return map
}

export function exitReservationItemsArrayToMap(items: ExitReservation[]) {
    const map = new Map<string, ExitReservation>()
    for (const item of items) {
        map.set(item.id, item)
    }
    return map
}

export function entryReservationItemsArrayToMap(items: EntryReservation[]) {
    const map = new Map<string, EntryReservation>()
    for (const item of items) {
        map.set(item.id, item)
    }
    return map
}

export function mapToItemsArray(map: Map<string, AusiliItem>) {
    const itemsArray: AusiliItem[] = []
    map.forEach((value) => {
        itemsArray.push(value)
    })
    return itemsArray
}

export function prenotazioniMapToItemsArray(map: Map<string, ExitReservation>) {
    const itemsArray: ExitReservation[] = []
    map.forEach((value) => {
        itemsArray.push(value)
    })
    return itemsArray
}

export function formatDateForDatatable(dateString: string) {
    const dateObject = getDateFromString(dateString, FORMAT_UNI_EN28601_STROKES)
    const formattedDate = formatDateObject(dateObject, FORMAT_ISO8601_STROKES)
    return formattedDate
}