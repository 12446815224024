/* tslint:disable:no-console */
import axios from 'axios';
import { ERROR } from 'lib-react/web/components/misc/alert';
import { func } from 'prop-types';
import URI from 'urijs';
import URITemplate from 'urijs/src/URITemplate';
import { deprecate } from 'util';
import { v4 } from 'uuid';
import AjaxRequestInterface, { URITemplateInput } from '../interfaces/ajax-request';
import { ComponentListItem } from '../interfaces/component-list-item';
import { getPlatformSpecificHeaders, isBundleVersion as _isBundleVersion, isProductionVersion as _isProductionVersion } from './platform-specific-utils';

export function generateUUID() {
    return v4()
}

export function isNumber(number: any) {
    return typeof number === 'number'
}

export function isArray(array: any) {
    return Array.isArray(array)
}

export function isMap(mapObject: any) {
    return mapObject instanceof Map
}

export function isString(value: any): boolean {
    return typeof value === 'string'
}

export function isEmptyArray(array: any[]) {
    return Array.isArray(array) && array.length <= 0
}

export function isEmptyMap(map: Map<any, any>) {
    return map instanceof Map && map.size <= 0
}

export function isObjectEmpty(obj: object) {
    let isEmpty = true
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            isEmpty = false
        }
    }
    return isEmpty
}

export function clone(source: any): any {
    if (isArray(source)) {
        return source.slice(0)
    } else if (source instanceof Map) {
        return new Map<any, any>(castTo<any>(source))
    } else if (source && typeof source === 'object') {
        return { ...source }
    }
    return source
}

export function renderComponentsList(itemsList: any[], renderingFunction: (any), uniquePrefix?: string): any[] {
    let componentsList: JSX.Element[] = []
    let index = 0
    if (itemsList && renderingFunction) {
        try {
            itemsList.forEach((item) => {
                let clonedItem = castTo<ComponentListItem>(clone(item))
                clonedItem._key = uniquePrefix ? uniquePrefix + index : generateUUID()
                clonedItem._itemIndex = index
                clonedItem._itemNumber = index + 1
                const component = renderingFunction(clonedItem)
                componentsList.push(component)
                index = index + 1
            })
        } catch (e) {
            logThis('Params not correctly set for renderComponentsList', e)
        }
    } else {
        logThis('Params not correctly set for renderComponentsList')
    }
    return componentsList
}

export function objectToArray(keyValue: { [name: string]: any }, mappingFunction: (a: string, b: any) => any): any[] {
    let list: any[] = []
    if (keyValue) {
        for (var k in keyValue) {
            if (keyValue.hasOwnProperty(k)) {
                if (mappingFunction) {
                    list.push(mappingFunction(k, keyValue[k]))
                } else {
                    list.push(keyValue[k])
                }
            }
        }
    }
    return list
}

export function mapToArray<T>(mapObject: Map<any, T>): T[] {
    const resultArray: T[] = []
    if (isMap(mapObject) && !isEmptyMap(mapObject)) {
        mapObject.forEach((value) => {
            resultArray.push(value)
        })
    }
    return resultArray
}

export function trimString(str: string) {
    if (isString(str)) {
        return str.trim()
    }
    return str
}

export function trimStringsInArray(arrayOfStrings: string[]) {
    if (isArray(arrayOfStrings)) {
        let trimmedArray: string[] = []
        arrayOfStrings.forEach((item) => {
            trimmedArray.push(trimString(item))
        })
        return trimmedArray
    }
}

export function trimStringsRecursively(object: any) {
    if (isString(object)) {
        return trimString(object)
    }

    if (isArray(object)) {
        return trimStringsInArray(object)
    }

    if (!object || typeof object !== 'object') {
        return object
    }

    let outputObject: any = {}
    for (var i in object) {
        if (object.hasOwnProperty(i)) {
            const item = object[i]
            outputObject[i] = trimStringsRecursively(item)
        }
    }
    return outputObject
}

export function expandUri(uri: string, params: URITemplateInput): string {
    try {
        const uriTemplate = URITemplate(uri)
        return uriTemplate.expand(params).valueOf()
    } catch (e) {
        logThis("Cannot expand the uri", e)
    }
    return null
}
export function getVariables(uri: string): Array<string> {
    try {
        const regexMatcher = /\{\?*(.*)\}/;
        return regexMatcher.exec(uri).map(s => s.replace('{', '').replace('}', ''))
    } catch (e) {
        logThis("Cannot expand the uri", e)
    }
    return [];
}

// TODO: fare i test
export function removeQueryParams(uri: string, params: string | URITemplateInput) {
    const uriTemplate = new URI(uri)
    return uriTemplate.removeSearch(params).valueOf()
}

// TODO: fare i test
export function appendQueryParams(uri: string, params: URITemplateInput): string {
    const uriTemplate = new URI(uri)
    return uriTemplate.addSearch(params).valueOf()
}

export function getQueryParams(uri: string): any {
    // Extract from a complete URL
    try {
        return URI.parseQuery(new URL(uri).search)
    } catch (e) { }

    // Extract from a query string
    try {
        return URI.parseQuery(uri)
    } catch (e) { }

    return {}
}

export function isBundleVersion() {
    return _isBundleVersion()
}

export function isProductionVersion() {
    return _isProductionVersion()
}

export function isJestTestingRunning() {
    return process.env.JEST_WORKER_ID !== undefined;
}

export function logThis(...something: any[]) {
    log(LOG_LEVEL.ERROR, ...something)
}

export function logInfo(...something: any[]) {
    log(LOG_LEVEL.INFO, ...something)
}

export enum LOG_LEVEL {
    ERROR, INFO
}

export function log(level: LOG_LEVEL, ...something: any[]) {
    if (!isProductionVersion() && !isJestTestingRunning()) {
        if (level == LOG_LEVEL.ERROR) {
            var stack = new Error().stack
            console.log(stack)
            console.warn(something)
        } else {
            console.log(something)
        }

    }
}



export function executeAsync(func: any, msDelay?: number) {
    setTimeout(func, msDelay ? msDelay : 0)
}

export function splitTextInLines(textParts: string[]) {
    let stringResult = ""
    try {
        textParts.forEach((item) => {
            stringResult += item + "\n"
        })
    } catch (e) {
        stringResult = ""
    }
    return stringResult
}

/**
 * Casts the input param to the specified Typescript interface.
 * It's useful for items defined with union types (https://www.typescriptlang.org/docs/handbook/advanced-types.html)
 * (for ex. a: string | Pet | Cat) and you want to use properties not defined in both string, Pet and Cat interfaces.
 *
 * This method should be used with care, beacuse it could lead to runtime errors.
 */
export function castTo<T>(item: any): T {
    const i: T = item
    return i
}

export function isObjectInObjectsArray<T>(targetObject: T, objects: T[], keyField: any): boolean {
    if (targetObject && isArray(objects) && keyField) {
        for (const obj of objects) {
            const castedObject = castTo<any>(obj)
            const castedTargetObject = castTo<any>(targetObject)
            if (castedObject[keyField] && castedTargetObject[keyField] && castedObject[keyField] === castedTargetObject[keyField]) {
                return true
            }
        }
    }
    return false
}

export function getObjectFromMap<T>(mapObject: Map<string, T>, key: string): T {
    if (mapObject && !isEmptyMap(mapObject) && key) {
        if (mapObject.has(key)) {
            return mapObject.get(key)
        }
    }
    return null
}

export function removeObjectFromMap<K, V>(map: Map<K, V>, key: K) {
    if (map && !isEmptyMap(map)) {
        return map.delete(key)
    }
    return false
}

export function extractPropertiesFromObjectsInArray(opt: { objectsArray: any[], property: string }): any[] {
    const { objectsArray, property } = opt
    let resultArray: any[] = []
    if (isArray(objectsArray) && !isEmptyArray(objectsArray)) {
        resultArray = objectsArray
            .filter((item) => { return item.hasOwnProperty(property) })
            .map((item) => { return item[property] })
    }
    return resultArray
}

/** From: https://stackoverflow.com/a/43467144 */
export function isValidURL(url: string) {
    try {
        new URL(url)
    } catch (error) {
        return false
    }
    return true
}

// --- Testato fino qui

export function filterData(opt: { allData: Map<any, any>, filter: string, filterKeys: string[] }): Map<any, any> {
    const { allData, filter, filterKeys } = opt
    if (allData && filter && filter !== "" && filterKeys && !isEmptyArray(filterKeys)) {
        let filteredData = new Map()
        allData.forEach((data: any, key: any) => {
            for (const filterKey of filterKeys) {
                if (data[filterKey].toLowerCase().includes(filter.toLowerCase())) {
                    filteredData.set(key, data)
                }
            }
        })
        return filteredData
    }
    return allData
}

export function removeItemFromArray<T>(item: T, array: T[]) {
    var index = array.indexOf(item)
    if (index > -1) {
        array.splice(index, 1)
    }
}

export function executeAjaxRequest(options: AjaxRequestInterface, onSuccess: (data: any, headers?: any) => any, onFailure: (data: any) => any) {
    let { url, method, headers, data, Adapter, responseType } = options
    if (!url) {
        throw Error('URL not set for ajax request.')
    }
    let mergedHeaders = {
        ...getPlatformSpecificHeaders(),
        ...(headers || {})
    }
    axios({
        method: method || 'GET',
        url: url,
        headers: mergedHeaders,
        data: data,
        responseType: responseType
    }).then((result) => {
        if (onSuccess) {
            if (result.status === 200 || result.status === 201) {
                if (Adapter) {
                    onSuccess(new Adapter(result.data), result.headers)
                } else {
                    onSuccess(result.data, result.headers)
                }
            } else {
                onFailure(result.data)
            }
        } else {
            logThis('Ajax request success, but onSuccess callback not set.')
        }
    }).catch((result) => {
        if (onFailure) {
            onFailure(result && result.response ? result.response : "Ajax request failed")
        } else {
            logThis('Ajax request failed, and onFailure callback not set.')
        }
    })
}

export function getExtensionFromFilename(filename: string) {
    return filename.substr(filename.lastIndexOf('.'))
}