import { Grid, GridSize }from '@material-ui/core';
import { Field, Widget } from '@rjsf/core';
import { generateUUID, logThis } from 'lib-react/universal/utils';
import { FormPage } from 'lib-react/web/components';
import Alert, { parsePositiveResponse, parseServerErrorResponse, Props as AlertObject } from 'lib-react/web/components/misc/alert';
import ErrorBoundary from 'lib-react/web/components/misc/error-boundary';
import LoadingComponent from 'lib-react/web/components/misc/loading-component';
import { FormObject } from 'lib-react/web/interfaces/form-object';
import { getFormData, sendForm } from 'lib-react/web/services/ajax-services';
import React from 'react';
import { styleRules } from './style/change-password-form-style';

const FORM_INPUT_KEYS = {
    NEW_PASSWORD: "newPassword1",
    RETYPE_NEW_PASSWORD: "newPassword2"
}

interface Props {
    isLoading: boolean
    requestUrl: string
    startLoading: () => any
    stopLoading: () => any
    fields: () => { [name: string]: Field }
    widgets: () => { [name: string]: Widget }
    sm?: GridSize
    resources?: FormObject // If set, no OPTIONS requests will be executed to get the FormObject.
}

interface State {
    formKey: string
    resources: FormObject,
    serverResponse: AlertObject
}

export class ChangePasswordComponent extends React.Component<Props, State> {
    static defaultProps = {
        sm: 3
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            formKey: generateUUID(),
            resources: props.resources,
            serverResponse: null
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (!this.state.resources) {
            getFormData({ url: this.props.requestUrl })
                .then((data) => {
                    this.setState({
                        resources: data
                    })
                    return null
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    onFormSubmit = (formObject: { formData: object }) => {
        let formData = formObject.formData
        let method = this.state.resources.method
        let url = this.state.resources.url
        this.props.startLoading()
        sendForm({ method, url, data: formData })
            .then((message) => {
                this.props.stopLoading()
                this.setState({
                    formKey: generateUUID(),
                    serverResponse: parsePositiveResponse({ autoclose: true })
                })
            }).catch((error) => {
                this.props.stopLoading()
                this.setState({
                    formKey: generateUUID(),
                    serverResponse: parseServerErrorResponse(error)
                })
            })
    }

    validate = (formData: any, errors: any) => {
        if (formData[FORM_INPUT_KEYS.NEW_PASSWORD] !== formData[FORM_INPUT_KEYS.RETYPE_NEW_PASSWORD]) {
            errors[FORM_INPUT_KEYS.RETYPE_NEW_PASSWORD].addError('Le password non corrispondono')
        }
        return errors
    }

    renderLoadingComponent = () => {
        return (
            <LoadingComponent
                fullPage
                semiTransparentBackground={false}
                backgroundColor={'#ecf0f5'}
            />
        )
    }

    renderAlertComponent = (props: AlertObject) => {
        if (!props) {
            return null
        }
        return (
            <Alert
                {...props}
            />
        )
    }

    render() {
        let serverResponseComponent = this.renderAlertComponent(this.state.serverResponse)
        let loadingView = (
            <div className='box'>
                {this.renderLoadingComponent()}
            </div>
        )
        return (
            <Grid container>
                <Grid item sm={this.props.sm}>
                    <ErrorBoundary>
                        {this.props.isLoading ? loadingView : ''}
                        <FormPage
                            buttonText='Cambia'
                            formKey={this.state.formKey}
                            formStyleRules={styleRules}
                            fields={this.props.fields()}
                            widgets={this.props.widgets()}
                            onFormSubmit={this.onFormSubmit}
                            resources={this.state.resources}
                            serverResponseComponent={serverResponseComponent}
                            title='Cambia password'
                            boxStyle='warning'
                            validate={this.validate}
                        />
                    </ErrorBoundary>
                </Grid>
            </Grid>

        )
    }
}