import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import StatusChip from 'commons/components/status-chip'
import { AusiliItem } from 'commons/interfaces/ausili-item'
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item'
import { isEmptyArray, renderComponentsList } from 'lib-react/universal/utils'
import * as React from 'react'
import { AUSILI_STATUS } from 'utils/constants'


interface Props {
    ausilio: AusiliItem
    actionButtons: any[]
}

export default class AusilioTable extends React.Component<Props, null> {

    renderStatusComponent = () => {
        const ausilio = this.props.ausilio
        return (
            <StatusChip
                ausilio={ausilio}
            />
        )
    }

    renderActionButton = (actionButton: ComponentListItem) => {
        return (
            <TableRow key={actionButton._key}>
                <TableCell colSpan={2} component="th" scope="row" >
                    {actionButton}
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const ausilio = this.props.ausilio
        if (ausilio) {
            var code = ausilio.code
            var statusChip = this.renderStatusComponent()
            var actualLocation = this.props.ausilio.actualLocation
            var locationReference = this.props.ausilio.locationReference
            if (this.props.actionButtons && !isEmptyArray(this.props.actionButtons)) {
                var actionButtonsComponent = renderComponentsList(this.props.actionButtons, this.renderActionButton)
            }
            if (ausilio.status === AUSILI_STATUS.BOOKED_UP) {
                var deliveryDateLabel = "Data di prenotazione"
                var deliveryDateContent = ausilio.prenotationDate
            } else if (ausilio.status === AUSILI_STATUS.BORROWED) {
                deliveryDateLabel = "Data di riconsegna prevista"
                deliveryDateContent = ausilio.redeliveryDate
            }
        }
        return (
            <Paper>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Codice ausilio
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div>
                                    {code}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography>Stato dell&apos;ausilio</Typography>
                            </TableCell>
                            <TableCell>
                                {statusChip}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Ubicazione</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div>
                                    {actualLocation}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Magazzino di riferimento</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div>
                                    {locationReference}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>{deliveryDateLabel}</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div>
                                    {deliveryDateContent}
                                </div>
                            </TableCell>
                        </TableRow>
                        {actionButtonsComponent}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}