export function isBundleVersion(): any {
    // MOCK
}

export function isProductionVersion(): any {
    // MOCK
}

export function getPlatformSpecificHeaders(): any {
    // MOCK
}