import { generateUUID, isEmptyArray } from "lib-react/universal/utils";
import React from "react";
import { UiSchema } from "../../interfaces/form-interfaces";
import { createDangerousInnerHtml } from "lib-react/web/utils/utils";
import Help from "../misc/help";

interface Props {
  title: string;
  uiSchema?: UiSchema;
  required?: boolean;
  errors?: string[];
}

export default class InputWrapper extends React.Component<Props, []> {
  constructor(props: Props) {
    super(props);
  }

  renderErrorItem = (error: string) => {
    return (
      <li key={generateUUID()} className={"text-danger"}>
        {error}
      </li>
    );
  };

  renderErrors = (errors: string[]) => {
    if (errors && !isEmptyArray(errors)) {
      const errorItems = errors.map((error) => this.renderErrorItem(error));
      return (
        <div>
          <p />
          <ul className={"error-detail bs-callout bs-callout-info"}>
            {errorItems}
          </ul>
        </div>
      );
    }
  };

  render() {
    const { title, required, uiSchema, errors } = this.props;
    const childrenView =
      uiSchema && uiSchema.customIconClassName ? (
        <div className="has-feedback">
          {this.props.children}
          <span className={uiSchema.customIconClassName} />
        </div>
      ) : (
        this.props.children
      );
    const errorViews = this.renderErrors(errors);
    const titleView =
      uiSchema && uiSchema.customHtmlContent ? (
        <span dangerouslySetInnerHTML={createDangerousInnerHtml(title)} />
      ) : (
        title
      );
    if (this.props.uiSchema && this.props.uiSchema["ui:modalhelpcontent"]) {
      let help = new Help(this.props.uiSchema["ui:modalhelptitle"], this.props.uiSchema["ui:modalhelpcontent"]);
      var modal = help.createModal();
      var button = help.createHelpButton();
    }

    return (
      <div>
        <label>
          {titleView}
          {required ? "*" : null}
          {button}
        </label>
        {childrenView}
        {errorViews}
        {modal}
      </div>
    );
  }
}
