import { FieldProps } from "lib-react/web/interfaces/form-interfaces";
import { COMMONS_REPRESENTATION_KEYS, represents } from "lib-react/web/utils/schema-utils";
import React from "react";
import InputWrapper from './input-wrapper';

interface Props extends FieldProps { }

interface State {
    value: string
    error?: boolean
}

export default class PhoneField extends React.Component<Props, State> {
    private static MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/
    private static ITALIAN_MOBILE_PHONE = /^((\+|00)?39)?3\d{2}\d{6,7}$/
    private static ITALIAN_OTHER_PHONE = /^((\+|00)?39)?(0{1}[1-9]{1,3})[\s|\.|\-]?(\d{4,})$/


    static FIELD_NAME = 'custom-phone-field'

    mobile: boolean

    constructor(props: Props) {
        super(props)
        let formValue = props.formData;
        this.state = {
            value: formValue
        }
        this.mobile = this.props.schema && represents(this.props.schema, COMMONS_REPRESENTATION_KEYS.MOBILE_PHONE)
    }

    validateAndChange(value: string) {
        if (value) {
            var phoneNumber = value.replace(' ', '')
        }
        let error = undefined
        if (!this.valid(phoneNumber)) {
            this.props.onChange(undefined)
            error = true
        } else {
            this.props.onChange(phoneNumber)
        }

        this.setState({
            value: value,
            error: error
        })
    }

    valid(value: string): boolean {
        if (!value)
            return true

        if (this.mobile && !PhoneField.ITALIAN_MOBILE_PHONE.test(value)) {
            return false
        }
        return PhoneField.ITALIAN_MOBILE_PHONE.test(value) || PhoneField.ITALIAN_OTHER_PHONE.test(value)
    }

    componentDidMount() {
        if (this.state.value) {
            this.validateAndChange(this.state.value)
        }
    }

    onChange = (event: { target: { value: string } }) => {
        this.validateAndChange(event.target.value)
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const inputClassName = uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        if (this.state.error) {
            var errors = [this.mobile ? 'Il numero inserito non è un un numero di cellulare valido' : 'Il numero inserito non è un un numero di telefono valido']

        }
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
                errors={errors}
            >
                <input className={inputClassName} type="phone" value={this.state.value} required={required}
                    name={this.props.name}
                    placeholder={this.props.placeholder ? this.props.placeholder : uiSchema["ui:placeholder"]}
                    onChange={this.onChange}
                    readOnly={uiSchema["ui:disabled"]} />
            </InputWrapper>
        )
    }


}