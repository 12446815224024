// Init the modules not refereced via code
// Do not require generated source, and avoid circular dependency
// Do not use minified versions, webpack will minimize the bundle later.
// This method must be called at the beginning, when the app is executed

// Define the global variables type (required by TS)
declare global {
    interface Window {
        JSZip: any
        pdfMake: any
    }
    interface JQueryStatic {
        DataTable: any
        AdminLTE: any
        live: any
    }
    interface JQuery {
        DataTable: any
        AdminLTE: any
        live: any
    }
}

interface AppInitializationModules {
    bootstrap: boolean
    fontAwesome: boolean
    slimscroll: boolean
    adminLte: boolean
    datatables: boolean
    datatablesPdf: boolean
}
declare var AppInitializationModules: AppInitializationModules

export function baseInitApp() {

    if (AppInitializationModules.bootstrap) {
        require('node_modules/bootstrap/dist/css/bootstrap.css')
        require('node_modules/bootstrap/dist/js/bootstrap.js')
    }

    if (AppInitializationModules.fontAwesome) {
        require('node_modules/font-awesome/css/font-awesome.css')
    }

    if (AppInitializationModules.slimscroll) {
        require('node_modules/jquery-slimscroll/jquery.slimscroll.js')
    }

    if (AppInitializationModules.adminLte) {
        require('lib-react/web/themes/adminlte/AdminLTE-2.3.11/dist/css/AdminLTE.css')
        require('lib-react/web/themes/adminlte/AdminLTE-2.3.11/dist/css/skins/_all-skins.css')
        require('lib-react/web/themes/adminlte/AdminLTE-2.3.11/dist/js/app.js')
    }

    // Datatables
    // Add the libraries to window object because of the following:
    // https://datatables.net/forums/discussion/44936/datatables-buttons-not-showing-in-semantic-ui-using-node-npm
    // https://github.com/bpampuch/pdfmake#using-javascript-frameworks
    if (AppInitializationModules.datatables) {
        require('datatables.net-bs/js/dataTables.bootstrap.js')(window, $)
        require('datatables.net-bs/css/dataTables.bootstrap.css')
        require('datatables.net-buttons/js/buttons.flash.js')(window, $)
        require('datatables.net-buttons/js/buttons.html5.js')(window, $)
        require('datatables.net-buttons/js/buttons.print.js')(window, $)
        require('datatables.net-buttons-bs/js/buttons.bootstrap.js')(window, $)
        require('datatables.net-buttons-bs/css/buttons.bootstrap.css')
        require('datatables.net-responsive-bs/js/responsive.bootstrap.js')(window, $)
        require('datatables.net-responsive-bs/css/responsive.bootstrap.css')

        // Warning:
        // datatables.net-select-dt/js/select.dataTables.js is not required here, because it breaks the
        // DataTables CSS for buttons and search plugins.
        // require('datatables.net-select-dt/js/select.dataTables.js')
        require('datatables.net-select/js/dataTables.select.js')(window, $)
        require('datatables.net-select-dt/css/select.dataTables.css')
    }

    if (AppInitializationModules.datatablesPdf) {
        window.JSZip = require('jszip')
        window.pdfMake = require('pdfmake/build/pdfmake.js')
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        window.pdfMake.vfs = pdfFonts.pdfMake.vfs
    }
}