import * as React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FieldProps } from '../../interfaces/form-interfaces'
import { Link } from 'react-router-dom';
import { executeRequest } from 'lib-react/web/services/ajax-services';
import { expandUri, getVariables, logThis } from 'lib-react/universal/utils';
import { InputWrapper } from '..';


interface State {
    selectedItems: { label: string, value: any }[],
    data: { label: string, value: any }[],
    disabled: boolean,
    isLoading: boolean
}


interface EmbeddedResult {
    _embedded: { autocompleteResults: { label: string, value: any }[] },
    _links: { next: Link }
}

type Result = EmbeddedResult | { label: string, value: any }[]

interface AllProps extends FieldProps {
    filterKeys?: {
        [name: string]: any
    }
}

export default class MultiSelectField extends React.Component<AllProps, State> {

    static FIELD_NAME = 'custom-multiselect-list-field'

    constructor(props: AllProps) {
        super(props)
        let formValues: any[] = props.formData;
        if (!this.props.uiSchema.customUseListValue) {
            var selectedValues: any[] = []
            if (formValues)
                formValues.forEach((v) => selectedValues.push({ value: v }))
        } else {
            selectedValues = formValues
        }
        this.state = {
            selectedItems: selectedValues,
            data: [],
            disabled: false,
            isLoading: false
        }

        if (!props.uiSchema.customUrl) {
            throw new Error("No URL set for MultiselectList!")
        }
    }

    onChange = (event: any) => {
        let selectedItems: { label: string, value: any }[] = event.target.value

        let values: any[] = []
        selectedItems.forEach((element: { label: string, value: any }) => {
            values.push(!this.props.uiSchema.customUseListValue ? element.value : element);
        });

        this.setState({
            selectedItems: selectedItems
        });
        this.props.onChange(values)
    }



    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(oldProps: AllProps) {
        if (oldProps.uiSchema.customUrl !== this.props.uiSchema.customUrl || oldProps.filterKeys !== this.props.filterKeys) {
          this.fetchData();
        }
    }

    fetchData = () => {
        let variables: Array<String> = getVariables(this.props.uiSchema.customUrl)
        let allVariables = true;
        let serachKey: {
            [name: string]: any
        } = {}
        variables.forEach((variable: string) => {
            if (!this.props.filterKeys || !this.props.filterKeys[variable] || this.props.filterKeys[variable] == null || Object.keys(this.props.filterKeys[variable]).length === 0) {
                allVariables = false
            } else {
                serachKey[variable] = this.props.filterKeys[variable].value && this.props.filterKeys[variable].label ? this.props.filterKeys[variable].value : this.props.filterKeys[variable]
            }
        })
        if (allVariables) {
            const requestUri = expandUri(this.props.uiSchema.customUrl, serachKey)
            this.setState({
                isLoading: true
            })
            executeRequest<Result>({ url: requestUri, method: 'GET' })
                .then((data) => {
                    let values;
                    if (data instanceof Array) {
                        values = data
                    } else {
                        values = data._embedded.autocompleteResults
                    }
                    this.setState({ data: values, disabled: false, isLoading: false });
                    return null;
                }).catch((data) => {
                    logThis(data)
                })
        } else {
            this.setState({ data: [], disabled: true });
        }
    }

    getValueObjectFromValue = () => {
        const { data, selectedItems } = this.state
        if (data === undefined || selectedItems === undefined)
            return undefined
        return this.props.uiSchema.customShowNotValidData ? selectedItems : data.filter((v) => selectedItems.find((i) => v.value === i.value) != undefined)
    }

    getDataObject = () => {
        const { data, selectedItems } = this.state
        if (!this.props.uiSchema.customShowNotValidData) {
            return data;
        }
        let notFoundElements = data.filter((v) => selectedItems.find((i) => v.value === i.value) == undefined)
        let newData = [];
        newData.push(data);
        newData.push(notFoundElements);
        return newData;
    }

    listNoDataRender = (element: any) => {
        const noData = (
            <h4 style={{ fontSize: '1em' }}>
                <span className="k-icon k-i-warning" style={{ fontSize: '2.5em' }} />
                <br /><br />
                {this.props.uiSchema.customNoData ? this.props.uiSchema.customNoData : "Dato non presente"}
            </h4>
        );

        return React.cloneElement(element, { ...element.props }, noData)
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const valueObjects = this.getValueObjectFromValue()
        
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
            >
                <MultiSelect
                    data={this.state.data}
                    onChange={this.onChange}
                    value={valueObjects}
                    textField="label"
                    dataItemKey="value"
                    listNoDataRender={this.listNoDataRender}
                    disabled={this.state.disabled}
                    placeholder={this.props.uiSchema.customPlaceHolder}
                />
            </InputWrapper>
        );
    }
}