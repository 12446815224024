import { createTheme } from '@material-ui/core';
import { brown, grey, red, teal } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/styles';
import { itIT } from '@mui/x-data-grid';



export class BaseMaterialColors {
    // static WHITE = "#FFF"

    // static PRIMARY = indigo[500]
    // static ACCENT = pink[500]

    static TEAL = teal[500]
    static BROWN = brown[500]
    static RED = red[500]
    static GREY = grey[500]
}

export class AppDimens {
    static QR_ICON_SIZE = 60
    static APP_BASE_FONT_SIZE = 22
}

let themeDef = createTheme({
    typography: {
        fontSize: AppDimens.APP_BASE_FONT_SIZE
    },
    palette: {
        type: 'light',
        primary: {
            main: "#282d48",
        },
        secondary: {
            main: '#FF577B',
        },
        background: {
            default: '#ecf0f5',
        },
    }
}, itIT);
themeDef = createTheme(themeDef, {
    overrides: {
        MuiCssBaseline: {
            body: {
                minHeight: '100vh'
            },
            html: {
                minHeight: '100vh'
            },
            '#react-root': {
                minHeight: '100vh'
            }
        },
        MuiButton: {
            containedPrimary: {
                '&:hover': {
                    color: themeDef.palette.primary.contrastText,
                    textDecoration: "none"
                }
            }
        },
        MuiBottomNavigation: {
            root: {
                backgroundColor: themeDef.palette.primary.main,
                width: '100%',
                height: '4rem',
                position: 'fixed',
                bottom: 0,
                zIndex: 100
            },
        },
        MuiBottomNavigationAction: {
            root: {
                maxWidth: '100%',
                color: themeDef.palette.primary.contrastText,
                "&.Mui-selected": {
                    color: themeDef.palette.secondary.main
                }
            },
            label: {
                maxWidth: '100%'
            }
        },

    }
})

export const THEME = themeDef




export const PageStyles = makeStyles(() => createStyles({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    mainContainer: {
        flexGrow: 1
    }
}))