import { AjvError } from "@rjsf/core"

export function transformErrors(errors: AjvError[]) {
    if (!errors) {
        errors = []
    }

    // Exclude multiple errors on the same property
    errors = errors.filter((item, pos, self) => {
        return self.findIndex((t) => t.property === item.property) === pos
    })

    // Change the displayed messages
    errors = errors.map((error) => {
        if (error.name === 'minItems') {
            error.message = 'Devi selezionare almeno ' + error.params.limit + ' elementi della lista.'
        }
        if (error.name === 'required') {
            error.message = 'Questo campo è obbligatorio.'
        }
        if (error.name === 'minLength') {
            error.message = 'Questo campo deve contenere almeno ' + error.params.limit + ' caratteri.'
        }
        if (error.name === 'maxLength') {
            error.message = 'Questo campo deve contenere al massimo ' + error.params.limit + ' caratteri.'
        }
        if (error.name === 'format' && error.params.format === 'date') {
            error.message = 'Il formato della data non è corretto.'
        }
        if (error.name === 'enum') {
            error.message = 'Questo campo deve essere uguale a uno dei valori ammessi'
        }
        return error
    })

    // Exclude some errors
    errors = errors.filter((error) => {
        return error.name !== 'oneOf' && error.name !== 'enum'
    })

    return errors
}