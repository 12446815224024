import { Box, Divider, Grid, Link, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item';
import { renderComponentsList } from 'lib-react/universal/utils';
import { FooterData, Icon, Partner } from 'lib-react/web/interfaces/structure-info';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';


interface Props extends RouteComponentProps<any> {
    structureInfo?: FooterData
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            backgroundColor: theme.palette.background.paper
        },
        image: {
            paddingLeft: 10
        },
        structurePart: {
            paddingTop: "10px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
        },
        textBottom: {
            fontSize: 13,
            fontWeight: 600,
        },
    }),
);


function Footer(props: Props) {

    const classes = useStyles();

    const renderValue = (value: string, label: string) => {
        if (value) {
            return (
                <span>{label}{value}</span>
            )
        }
        return undefined;
    }

    const renderLogo = (logo: Icon) => {
        if (!logo || !logo.imgPath)
            return null
        if (logo && logo.link) {
            return (
                <Link className={classes.image} href={logo.link} rel="noopener" target='_blank'>
                    <img src={logo.imgPath} width='70' />
                </Link>
            )
        } else if (logo) {
            return (
                <img className={classes.image} src={logo.imgPath} width='70' />
            )
        }
        return undefined
    }

    const renderFooterData = (footerData: FooterData, key?: any) => {
        if (footerData) {
            let name = footerData.name
            if (footerData.contacts) {
                var address = renderValue(footerData.contacts.address, ' - ')
                var phone = renderValue(footerData.contacts.phone, ' - Tel. ')
                var mail = renderValue(footerData.contacts.mail, ' - Email ')

            }
            let logo = renderLogo(footerData.logo)
            return (
                <Box className={classes.structurePart} key={key}>
                    <span>{name}</span>{address}{phone}{mail}{logo}
                </Box>
            )
        }
    }

    const renderPartner = (partner: Partner & ComponentListItem) => {
        if (partner && partner.showDataOnFooter && partner.name) {
            return renderFooterData(partner, partner._key)
        }
        return undefined
    }

    const renderPartners = () => {
        if (props.structureInfo && props.structureInfo.partners) {
            let partners = renderComponentsList(props.structureInfo.partners, renderPartner)
            return (
                <div>
                    {partners}
                </div>
            )
        }
        return undefined
    }


    const renderStructurePart = () => {
        if (props.structureInfo) {
            let structureFooter = renderFooterData(props.structureInfo)
            let partnersFooter = renderPartners()
            return (
                <Box display="block">
                    {structureFooter}
                    {partnersFooter}
                </Box>
            )
        }
        return undefined;
    }



    let structurePart = renderStructurePart()
    let year = new Date().getFullYear();
    return (
        <Box className={classes.footer} >
            <Divider />
            {structurePart}
            <Box p={2}>
                <Grid container >
                    <Grid item xs={6}>
                        <Box pr={1}>
                            <Typography align="right" className={classes.textBottom}>
                                © {year} created by
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box pl={1}>
                            <Link href='https://www.insoft.it' >
                                <img src='https://www.insoft.it/wp-content/themes/health-center-lite/images/logo.png' width='70' />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


export default withRouter(Footer)