import { Box, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { DEFAULT_STYLE, TextualErrorStyle } from './styles/textual-error-style';
interface Props {
    message: string | JSX.Element
    styles?: TextualErrorStyle
}

export default class TextualError extends React.Component<Props, null> {
    static defaultProps = {
        styles: DEFAULT_STYLE
    }

    render() {
        const { message } = this.props
        const styles = StyleSheet.create(this.props.styles)
        // const iconStyle = classNames("icon fa fa-exclamation-triangle", css(styles.icon))
        return (
            <div className='error-page-parent'>
                <div className='error-page'>
                    <Grid container alignContent='center' justifyContent='center'>
                        <Grid item>
                            <Box className={css(styles.iconWrapper)}>
                                <WarningIcon style={this.props.styles.icon} />
                            </Box>
                            <Box className={css(styles.messageWrapper)}>
                                {message}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div >
        )
    }
}