import { Button } from '@material-ui/core';
import * as React from 'react';

interface Props extends React.PropsWithChildren<any> {
    text?: string
    callback?: () => any
    component?: any
}


function CustomButton(props: Props) {
    const content = props.text ? (
        <span>{props.text}</span>
    ) : props.children
    return (
        <Button variant="contained" size="large" color="primary" onClick={props.callback} component={props.component}
            to={props.to}>
            {content}
        </Button>
    )
}

export default CustomButton