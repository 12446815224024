
import { castTo } from "lib-react/universal/utils";
import {
  ReactComponentSupplier,
  ReactLabelComponent,
  ReactStringComponent
} from "lib-react/web/interfaces/react-string-component";
import ReactDOM from "react-dom";
import { Props, SelectableData } from "./index";
import  {GridCellValue, GridCellParams, GridSortModelParams} from "@mui/x-data-grid"

// TODO TEST fino qui

export function defaultReactDataRender(
  data: ReactStringComponent,
  type: any,
  row: any
) {
  if (type === "sort") {
    return data.label;
  }
  return data.component;
}

export function defaultReactDataRenderMUI(params: GridCellParams) {
  let data = castTo<ReactLabelComponent>(params.value);
  return data.component;
}

export function defaultReactDataSortMUI(
  v1: GridCellValue,
  v2: GridCellValue,
  cellParams1: GridSortModelParams,
  cellParams2: GridSortModelParams
): number {
  let data1 = castTo<ReactLabelComponent>(v1);
  let data2 = castTo<ReactLabelComponent>(v2);
  if (data1.label < data2.label) {
    return -1;
  }
  if (data1.label > data2.label) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function defaultReactDataCellCreated(
  td: Element,
  cellData: ReactComponentSupplier,
  rowData: any,
  row: any,
  col: any
) {
  ReactDOM.render(cellData.componentSupplier.apply(cellData.data), td);
}

export function getAjax(props: Props): any {
  return props.ajaxFunction && props.ajaxFunction != null
    ? props.ajaxFunction
    : undefined;
}

export function getButtons(props: Props) {
  let buttonsArray = [];
  if (props.allButtons || props.buttonCopy) {
    buttonsArray.push({
      extend: "copy",
      className: "btn-sm",
      text: "Copia",
    });
  }
  if (props.allButtons || props.buttonCsv) {
    buttonsArray.push({
      extend: "csv",
      className: "btn-sm",
    });
  }
  if (props.allButtons || props.buttonExcel) {
    buttonsArray.push({
      extend: "excel",
      className: "btn-sm",
    });
  }
  if (props.allButtons || props.buttonPdf) {
    buttonsArray.push({
      extend: "pdf",
      className: "btn-sm",
    });
  }
  if (props.allButtons || props.buttonPrint) {
    buttonsArray.push({
      extend: "print",
      className: "btn-sm",
      text: "Stampa",
    });
  }
  if (props.buttonSelectAll) {
    buttonsArray.push({
      extend: "selectAll",
      className: "btn-sm",
      text: "Seleziona tutto",
    });
  }
  if (props.buttonSelectNone) {
    buttonsArray.push({
      extend: "selectNone",
      className: "btn-sm",
      text: "Deseleziona tutto",
    });
  }
  return buttonsArray;
}

export function getSelectSettings(props: Props) {
  var selectSettings = {
    style: "multi",
    selector: "td:first-child",
  };
  if (props.select) {
    return selectSettings;
  }
}

export function autoSelectRows(datatable: any, props: Props) {
  if (props.select && props.data) {
    datatable
      .api()
      .rows((idx: any, data: SelectableData, node: any) => {
        return data._dtSelected ? true : false;
      })
      .select();
  }
}

export function addOnRowsSelectedListener(datatable: any, props: Props) {
  datatable.on("select.dt", (e: any, dt: any, type: any, indexes: any) => {
    props.onRowsSelected(getSelectedRowsData(datatable));
  });
  datatable.on("deselect.dt", (e: any, dt: any, type: any, indexes: any) => {
    props.onRowsSelected(getSelectedRowsData(datatable));
  });
}

export function getSelectedRowsData(datatable: any) {
  return datatable.api().rows({ selected: true }).data().toArray();
}

export function getDomOptions(props: Props) {
  // https://datatables.net/reference/option/dom
  let domOptions = "";
  if (props.allInputs || props.inputLenght) {
    domOptions += "l";
  }
  domOptions += "B";
  if (props.allInputs || props.inputFilter) {
    domOptions += "f";
  }
  domOptions += "r";
  domOptions += "t";
  if (props.allInputs || props.inputPagesInfo) {
    domOptions += "i";
  }
  if (props.allInputs || props.inputPagination) {
    domOptions += "p";
  }
  return domOptions;
}

export function getPageLength(props: Props) {
  if (props.allInputs || props.inputLenght) {
    return 10;
  }
  return props.data.length;
}

export function getOrder(props: Props) {
  return props.order ? props.order : [[2, "desc"]];
}

export function getColumnDefsForOrder(props: Props) {
  let columns = props.columns;
  let columnDefs: any[] = [];

  if (props.select) {
    columnDefs.push({
      className: "select-checkbox",
      targets: 0,
    });
  }

  columns.forEach((value, index) => {
    columnDefs.push({
      orderable:
        value.orderable === undefined || value.orderable === null
          ? true
          : value.orderable, // enabled by default
    });
  });

  return columnDefs;
}

// TODO TEST jquery

export function reloadTableData(data: any, tableId: string) {
  const table = $("#" + tableId).DataTable();
  table.clear();
  table.rows.add(data);
  table.draw();
}

export function reloadTableDataAjax(tableId: string) {
  const table = $("#" + tableId).DataTable();
  table.ajax.reload();
}
