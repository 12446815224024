import { UXAction, UX_FILTER_VALUE, UX_SHOW_IMAGES } from 'redux/actions/ux-actions';
import { GlobalStateInterface, INITIAL_STATE } from '../store';

export default function (state = INITIAL_STATE.ux, action: UXAction): GlobalStateInterface["ux"] {
    switch (action.type) {
        case UX_SHOW_IMAGES:
            return {
                ...state,
                showImages: action.showImages
            }
        case UX_FILTER_VALUE:
            return {
                ...state,
                filterValue: action.filterValue
            }
        default:
            return state
    }
}