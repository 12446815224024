import { renderComponentsList } from 'lib-react/universal/utils';
import { FieldProps } from 'lib-react/web/interfaces/form-interfaces';
import React from 'react';
import InputWrapper from './input-wrapper';

interface Props extends FieldProps { }

interface State {
    selectedItemValue: string
}

export default class RadioField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-radio-field'

    constructor(props: Props) {
        super(props)
        this.state = {
            selectedItemValue: props.formData
                ? props.formData
                : null
        }
    }

    onChange() {
        return (event: { target: { value: string } }) => {
            let value = event.target.value
            this.setState({
                selectedItemValue: value
            }, () => this.props.onChange(value))
        }
    }

    renderSingleRadio = (item: { label: string, value: string, img: string, _key: string }) => {
        let { label, value, img } = item
        return (
            <div key={item._key}>
                <label>
                    <input
                        checked={value === this.state.selectedItemValue}
                        onChange={this.onChange()}
                        type='radio'
                        value={value}
                    />
                    <span className='separate-to-text'>{label}</span>
                </label>
            </div>
        )
    }

    render() {
        const { schema, uiSchema, required } = this.props
        const { enumNames, enum: enums } = schema

        let objectsArray = []
        for (var i = 0; i < enumNames.length; i++) {
            objectsArray.push({ label: enumNames[i], value: enums[i] })
        }

        let itemsFirstColumn = renderComponentsList(objectsArray.splice(0, Math.ceil(objectsArray.length / 2)), (item: any) => {
            return this.renderSingleRadio(item)
        })
        let itemsSecondColumn = renderComponentsList(objectsArray, (item: any) => {
            return this.renderSingleRadio(item)
        })
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
            >
                <div className='radio'>
                    <div className='col-xs-12 col-sm-6'>
                        {itemsFirstColumn}
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                        {itemsSecondColumn}
                    </div>
                </div>
            </InputWrapper>
        )
    }
}