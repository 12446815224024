import { MessageInterface, Messages } from "lib-react/universal/interfaces/message-interface";
import { castTo } from "../utils";

export function getMessageByParam(messages: Messages, paramName: "id" | "target" | "errorCode", paramValue: string): MessageInterface {
    let resultObject = null
    var BreakException = {}
    try {
        Object.keys(messages).forEach((key: string, index: number) => {
            const message: MessageInterface = messages[key]
            if (message[paramName] === paramValue) {
                resultObject = message
                throw BreakException
            }
        })
    } catch (e) {
        // do nothing
    }
    return resultObject
}

/**
 * Metodo che restituisce la prima occorrenza di messaggio le cui proprietà matchano con la lista di messaggi passati
 * come parametro.
 *
 * @param messages collezione di messaggi da scandire.
 * @param queryParamObject oggetto i cui parametri vengono usati per filtrare i messaggi.
 */
export function getMessageByMultipleParams(messages: Messages, queryParamObject: MessageInterface): MessageInterface {
    let resultObject: MessageInterface
    var BreakException = {}
    try {
        Object.keys(messages).forEach((key: string) => {
            const message: any = messages[key]
            resultObject = message
            for (var property in queryParamObject) {
                if (queryParamObject.hasOwnProperty(property)) {
                    if (message[property] !== castTo<any>(queryParamObject)[property]) {
                        resultObject = null
                    }
                }
            }
            if (resultObject) {
                throw BreakException
            }
        })
    } catch (e) {
        // do nothing
    }
    return resultObject
}

export function getValueFromMapObject<T>(opt: { key: string, mapObject: { [key: string]: T } }): T {
    if (!opt || !opt.mapObject || !opt.key) {
        return
    }
    const { key, mapObject } = opt
    return mapObject[key]
}