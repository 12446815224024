import { CircularProgress, Grid }from '@material-ui/core';
import React from 'react';

interface ComponentProps {
    fullPage?: boolean
    message?: string
    // @deprecated use backgroundColor
    semiTransparentBackground?: boolean
    backgroundColor?: string
}

export default function LoadingComponent(props: ComponentProps) {
    // let style = 'overlay'
    // if (props.fullPage) {
    //     style = classNames(style, 'overlay-full-page')
    // }

    let cssStyle: React.CSSProperties = {}
    if (props.fullPage) {
        cssStyle['minHeight'] = '70vh'
    }

    if (!props.semiTransparentBackground) {
        cssStyle['backgroundColor'] = props.backgroundColor
    }

    // <Grid item>
    //             <div className={style} style={backgroundStyle}>
    //                 <CircularProgress size={60} color='primary' />
    //                 {props.message}
    //             </div>
    //         </Grid>

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={cssStyle}>
            <Grid item>
                <CircularProgress size={60} color='primary' />
                {props.message}
            </Grid>
        </Grid>
    )

}

LoadingComponent.defaultProps = {
    fullPage: false,
    semiTransparentBackground: true,
    backgroundColor: 'transparent'
}