import {Close as CloseIcon} from '@material-ui/icons';
import { Alert as AlertMaterial, AlertTitle }from '@material-ui/lab';
import {  Collapse, IconButton, Typography }from '@material-ui/core'; 
import { ServerResponse } from 'lib-react/universal/interfaces/server-response';
import React from 'react';
import { generateUUID, isObjectEmpty, renderComponentsList } from '../../../universal/utils';

export const SUCCESS = 'success'
export const ERROR = 'error'

type typesList = 'error' | 'info' | 'warning' | 'success'


export interface Props {
    id: string
    type?: typesList
    title?: string
    errors?: string[]
    message?: string
    ref?: string
    closable?: boolean
    autoClose?: boolean
}

interface State {
    shouldCreate: boolean
}

export function parsePositiveResponse(opt: { autoclose?: boolean, closable?: boolean } = null): Props {
    return {
        id: generateUUID(),
        type: SUCCESS,
        title: 'Richiesta eseguita con successo',
        closable: opt && opt.closable,
        autoClose: opt && opt.autoclose
    }
}

export function parseServerErrorResponse(response: any): Props {
    let serverResponse: ServerResponse = response
    if (response.failure) {
        // Local failure (JS context). Create ad-hoc error response
        serverResponse.status = 500
        serverResponse.data = {
            message: "Si è verificato un errore imprevisto, si prega di riprovare."
        }
    } else if (response.name === "StopError") {
        // Retry stopped
        serverResponse = response.message
    }

    // TODO: attenzione, questa interfaccia è cambiata il 11/05/2020 per socialis-bandi-web
    const id = generateUUID()
    const type = serverResponse.status === 200 ? SUCCESS : ERROR
    const title = serverResponse.status === 200 ? 'Richiesta eseguita con successo' : 'Errore nella richiesta'
    const message = serverResponse.data && serverResponse.data.message ? serverResponse.data.message : ''
    const errors: string[] = []
    if (serverResponse.data && serverResponse.data.errors) {
        serverResponse.data.errors.forEach((item) => {
            errors.push(item.message ? item.message : "Errore sconosciuto.")
        })
    }

    return { id, type, title, errors, message }
}

export default function Alert(props: Props) {

    const [open, setOpen] = React.useState(true);
    const renderErrorItem = (item: any) => {
        return (
            <li key={item._key}>
                {item.label}
            </li>
        )
    }


    if (isObjectEmpty(props)) {
        return null
    }
    let { type, title, errors, closable, message, autoClose } = props
    if (errors) {
        let errorsObjects = errors.map((str: any) => { return { label: str } })
        var errorsViews = renderComponentsList(errorsObjects, renderErrorItem)
        var errorSection = (<ol>
            {errorsViews}
        </ol>)
    }



    if (closable && !autoClose) {
        var onCloseAction = (
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpen(false);
                }}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>
        )
    }
    return (
        <Collapse in={open}>
            <AlertMaterial severity={type} action={onCloseAction} variant="filled">
                <AlertTitle>{title}</AlertTitle>
                <Typography>{message}</Typography>
                {errorSection}
            </AlertMaterial>
        </Collapse>
    )
}
