import { AppBar, Box, Container, Toolbar, Typography }from '@material-ui/core';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps<any>, React.Props<any> {
    needNavbarArea?: boolean
    loginRedirectUrl?: string
    structureId?: string
    title?: string
    logoMini?: string
    logoLarge?: string
    showLogoutButton?: boolean
}

class Header extends React.Component<Props, null> {
    static defaultProps = {
        needNavbarArea: true,
        showLogoutButton: true
    }

    renderHeaderTopNav = () => {
        const title = this.props.title
        if (this.props.logoLarge) {
            var image = (
                <img className='user-image' src={this.props.logoLarge} />
            )
        }
        return (
            <AppBar position="static" color="primary" >
                <Toolbar disableGutters={true}>
                    <Box flexGrow={1}>
                        <Box display="flex" alignItems="center">
                            {image}
                            <Container maxWidth="lg">
                                <Typography variant="h6" noWrap component="div">
                                    {title}
                                </Typography>
                            </Container>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }

    renderHeaderSideNav = () => {
        const title = this.props.title
        if (this.props.logoMini) {
            var logoMini = (
                <img className='user-image' src={this.props.logoMini} />
            )
        }
        if (this.props.logoLarge) {
            var logoLarge = (
                <img className='user-image' src={this.props.logoLarge} />
            )
        }
        // if (this.props.showLogoutButton) {
        //     var logoutButton = (
        //         <div className='navbar-custom-menu'>
        //             <ul className='nav navbar-nav'>
        //                 <li><LogoutButton logoutRedirectUrl={this.props.loginRedirectUrl} structureId={this.props.structureId} /></li>
        //             </ul>
        //         </div>
        //     )
        // }
        var logoutButton = undefined
        return (
            <header className='main-header'>
                <Link to={"/"}>
                    <div className='logo'>
                        <span className='logo-mini'>
                            {logoMini}
                        </span>
                        <span className='logo-lg'>
                            {logoLarge}
                            {title}
                        </span>
                    </div>
                </Link>
                <nav className='navbar navbar-static-top'>
                    <a className='sidebar-toggle' data-toggle='offcanvas' href='#' role='button'>
                        <span className='sr-only' />
                        <span className='icon-bar' />
                        <span className='icon-bar' />
                        <span className='icon-bar' />
                    </a>
                    {logoutButton}
                </nav>
            </header>
        )
    }

    render() {
        return this.props.needNavbarArea ? this.renderHeaderSideNav() : this.renderHeaderTopNav()
    }
}

export default withRouter(Header)