import moment from 'moment'
import React from 'react';
import { FieldProps } from '../../interfaces/form-interfaces';
import { MaskedInputField } from '..';

interface Props extends FieldProps { }

const FORMAT_UI = "DD/MM/YYYY"
const FORMAT_ISO8601 = "YYYY-MM-DD"
const FORMAT_UNI_EN28601 = "DD-MM-YYYY"

export default class MaskedDateField extends React.Component<Props, null> {
    static FIELD_NAME = 'custom-masked-date-field'
    static DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

    onChange = (value: string) => {
        const formattedDate = value && value !== '' ? this.formatISO8601Date(value, FORMAT_UI) : undefined
        this.props.onChange(formattedDate)
    }

    formatISO8601Date = (date: string, inputFormat: string) => {
        return moment(date, inputFormat, true).format(FORMAT_ISO8601) // used the moment strict mode
    }

    formatUNIEN28601Date = (date: string, inputFormat: string) => {
        return moment(date, inputFormat, true).format(FORMAT_UNI_EN28601) // used the moment strict mode
    }

    render() {
        const formattedDate = this.formatUNIEN28601Date(this.props.formData, FORMAT_ISO8601)
        const props = { ...this.props }
        props.formData = formattedDate
        props.onChange = this.onChange
        props.uiSchema.customMask = MaskedDateField.DATE_MASK
        return (
            <MaskedInputField
                {...props}
            />
        )
    }
}