import { BaseGlobalAction } from "lib-react/universal/redux/base-actions";
import { batchActions } from "redux-batched-actions";
import { Dispatch } from "../../../node_modules/redux";
import { clearLocalAusiliMapSuccess } from "./ausili-actions";

export const UX_SHOW_IMAGES = "GLOBAL_SHOW_IMAGES"
export const UX_FILTER_VALUE = "GLOBAL_FILTER_VALUE"

export interface UXAction extends BaseGlobalAction {
    showImages?: boolean
    filterValue?: string
}

export function showImages(show: boolean): UXAction {
    return {
        type: UX_SHOW_IMAGES,
        showImages: show
    }
}

export function setFilterValue(filterValue: string) {
    return (dispatch: Dispatch) => {
        return dispatch(batchActions([
            setFilterValueSuccess(filterValue),
            clearLocalAusiliMapSuccess()
        ]))
    }
}

export function setFilterValueSuccess(filterValue: string): UXAction {
    return {
        type: UX_FILTER_VALUE,
        filterValue: filterValue
    }
}