import React from 'react'

interface Props {
    csrfTokenValue: string
    id?: string
    name?: string
}

export default class CsrfInput extends React.Component<Props, null> {
    static defaultProps = {
        id: "csrf_token",
        name: "_csrf"
    }

    render() {
        return (
            <input
                id={this.props.id}
                name={this.props.name}
                type='hidden'
                value={this.props.csrfTokenValue}
            />
        )
    }

}