import * as React from 'react';
import Toggle from 'react-bootstrap-toggle';

interface Props {
    formData: boolean
    schema: { enumNames: string[], enum: string[], imgs: string[], title: string }
    onChange: (value: boolean) => any
    required: boolean
}

interface State {
    active: boolean
}


export default class SwitchBooleanField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-switch-boolean-field'


    constructor(props: Props) {
        super(props)
        this.state = { active: props.formData || false }
    }

    onClick = () => {
        const newValue = !this.state.active
        this.setState({ active: newValue }, () => this.props.onChange(newValue))
    }

    render() {
        return (
            <div>
                <label className="switch-label">{this.props.schema.title}</label>
                <Toggle
                    active={this.state.active}
                    on='Si'
                    off='No'
                    onClick={this.onClick}
                />
            </div>
        )
    }
}