import AusiliTablePage from 'commons/components/ausili-table-page';
import { AuxReservation } from 'commons/interfaces/ausili-item';
import { startLoading, stopLoading } from 'lib-react/universal/redux/base-actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveExitReservations, RetrieveExitReservationsInterface } from 'redux/actions/ausili-actions';
import { GlobalStateInterface } from 'redux/store';
import { AUSILI_STATUS } from 'utils/constants';


interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    retrieveReservations: (opt: RetrieveExitReservationsInterface) => any
}

interface Props {
    loading: boolean
    requestUrl?: string
    reservations?: Map<string, AuxReservation>
}

interface AllProps extends ActionCreatorsProps, Props, RouteComponentProps<any>, React.Props<any> {

}

function AusiliExitPage(props: AllProps) {

    const getActionButton = (status: string) => status === AUSILI_STATUS.BOOKED_UP ? "USCITA" : "VEDI"
    const dateTitle = "Data di prenotazione"



    return (
        <AusiliTablePage title='USCITA Ausili' dateTitle={dateTitle} getActionButton={getActionButton} requestUrl={props.requestUrl}
            retrieveReservations={props.retrieveReservations} reservations={props.reservations} />

    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.exitReservations.href,
        reservations: state.ausili.exitReservationItems
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        retrieveReservations: bindActionCreators(retrieveExitReservations, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AusiliExitPage))