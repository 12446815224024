import { MessageInterface } from "lib-react/universal/interfaces/message-interface";

export const GLOBAL_START_LOADING = "GLOBAL_START_LOADING"
export const GLOBAL_STOP_LOADING = "GLOBAL_STOP_LOADING"
export const GLOBAL_ADD_MESSAGE = "GLOBAL_ADD_MESSAGE"
export const GLOBAL_REMOVE_MESSAGE = "GLOBAL_REMOVE_MESSAGE"
export const GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS = "GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS"

export interface BaseAction {
    type: string
}

export interface BaseGlobalAction extends BaseAction {
    message?: MessageInterface
    navigationLinks?: any
}

export function startLoading(): BaseGlobalAction {
    return {
        type: GLOBAL_START_LOADING
    }
}

export function stopLoading(): BaseGlobalAction {
    return {
        type: GLOBAL_STOP_LOADING
    }
}

export function addMessage(message: MessageInterface): BaseGlobalAction {
    return {
        type: GLOBAL_ADD_MESSAGE,
        message
    }
}

export function removeMessage(message: MessageInterface): BaseGlobalAction {
    return {
        type: GLOBAL_REMOVE_MESSAGE,
        message
    }
}