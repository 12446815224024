import { DropDownList } from '@progress/kendo-react-dropdowns';
import { expandUri, logThis } from 'lib-react/universal/utils';
import { executeRequest } from 'lib-react/web/services/ajax-services';
import React from 'react';
import { InputWrapper } from '..';
import { FieldProps } from '../../interfaces/form-interfaces';
import LoadingComponent from '../misc/loading-component';

export interface DropdownListData {
    label: string
    value: string
}

interface Props extends FieldProps {
    filterKeys?: {
        [name: string]: string
    }
    errors?: string[]
    isTest?: boolean // Aggiunto per permettere i test evitando l'esecuzione della funziona ajax. Se possibile, sostituire mockando la funzione ajax dal test
}

interface State {
    data: DropdownListData[]
    selectedItemValue: any
    isLoading: boolean
}

export default class DropdownListField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-dropdown-list-field'

    static defaultProps = {
        filterKeys: {}
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            data: [],
            selectedItemValue: props.formData,
            isLoading: false
        }

        if (!props.uiSchema.customUrl) {
            throw new Error("No URL set for DropdownList!")
        }
    }

    componentDidMount() {
        this.fetchDataFromServer()
    }

    componentDidUpdate(oldProps: Props) {
        if (oldProps.uiSchema.customUrl !== this.props.uiSchema.customUrl || oldProps.filterKeys !== this.props.filterKeys) {
          this.fetchDataFromServer();
        }
    }

    onChange = (event: any) => {
        const selectedItem: DropdownListData = event.target.value
        let newValue = !this.props.uiSchema.customUseListValue ? selectedItem.value : selectedItem
        this.setState({ selectedItemValue: newValue })
        this.props.onChange(newValue)
    }

    createExpandObject = () => {
        return this.props.filterKeys
    }

    fetchDataFromServer = () => {
        const url = this.props.uiSchema.customUrl
        const requestUri = expandUri(url, this.createExpandObject())
        const that = this
        if (!this.props.isTest) {
            this.setState({ isLoading: true })
            executeRequest<any>({ url: requestUri })
                .then((response) => {
                    let values: DropdownListData[] = response
                    if (response._embedded && response._embedded.comboBoxOptions) {
                        values = response._embedded.comboBoxOptions
                    }

                    if (!this.props.required) {
                        values.unshift({label: '', value: undefined});
                    }
                    that.setState({ data: values, isLoading: false })
                })
                .catch((error) => {
                    that.setState({ isLoading: false })
                    logThis("Cannot fetch the dropdown-list values", error)
                })
        }
    }

    getValueObjectFromValue = () => {
        const { data, selectedItemValue } = this.state
        if (data === undefined || selectedItemValue === undefined)
            return undefined
        return data.find((v) => v.value === (!this.props.uiSchema.customUseListValue ? selectedItemValue : selectedItemValue.value))
    }

    listNoDataRender = (element: any) => {
        const noData = (
            <h4 style={{ fontSize: '1em' }}>
                <span className="k-icon k-i-warning" style={{ fontSize: '2.5em' }} />
                <br /><br />
                Dato Non Presente
            </h4>
        )
        return React.cloneElement(element, { ...element.props }, noData)
    }

    render() {
        const { schema, uiSchema, required, errors } = this.props
        const inputClassName = uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        const valueObject = this.getValueObjectFromValue()

        if (this.state.isLoading) {
            return (
                <LoadingComponent
                    fullPage={false}
                    semiTransparentBackground={true}
                    backgroundColor={'#ecf0f5'}
                />
            )
        }

        const disabled = this.props.readonly || this.props.schema.readonly

        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
                errors={errors}
            >
                <DropDownList
                    data={this.state.data}
                    textField={'label'}
                    dataItemKey={'value'}
                    filterable={false}
                    onChange={this.onChange}
                    value={valueObject}
                    className={inputClassName}
                    listNoDataRender={this.listNoDataRender}
                    disabled={disabled}
                />
            </InputWrapper>
        )
    }
}