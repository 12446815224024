import React from 'react';
import { FieldProps } from '../../interfaces/form-interfaces';

interface Props extends FieldProps { }

export default class FileUrlField extends React.Component<Props, null> {
    static FIELD_NAME = 'custom-file-url-field'

    renderLink = (link: string, title: string) => {
        return (
            <a href={link} target={"_blank"}>
                <button type="button" className="btn btn-primary">{title}</button>
            </a>
        )
    }

    render() {
        const { schema, formData } = this.props
        if (formData) {
            var link = this.renderLink(formData, schema.title)
        }
        return (
            <div>
                {link}
            </div>
        )
    }
}