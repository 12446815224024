
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ComponentListItem } from 'lib-react/universal/interfaces/component-list-item';
import { clone, isEmptyArray, renderComponentsList } from 'lib-react/universal/utils';
import * as React from 'react';
import { useState } from 'react';


const styles = makeStyles(() => createStyles({
    root: {
        width: '100%',
    }
}))

interface Props {
    items: ListItem[]
}

export interface ListItem {
    title: string
    description: string
    content?: any
    expanded?: boolean
}

export default function ExpandableList(props: Props) {
    const initMap = () => {
        const map = new Map<number, boolean>()
        renderComponentsList(props.items, (item: ListItem & ComponentListItem) => {
            map.set(item._itemNumber, item.expanded ? true : false)
        })
        return map
    }


    const [expandedItems, setExpandedItems] = useState<Map<number, boolean>>(initMap())


    const renderExpansionPanel = (item: ListItem & ComponentListItem) => {
        const { title, description } = item
        var expanded = (expandedItems.get(item._itemNumber) === true)
        if (item.content) {
            var content = item.content
        } else {
            content = (
                <Typography>
                    {description}
                </Typography>
            )
        }
        return (
            <Accordion expanded={expanded} onChange={handleChange(item._itemNumber)} key={item._key}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {content}
                </AccordionDetails>
            </Accordion >
        )
    }

    const handleChange = (itemNumber: number) => (event: any, expanded: boolean) => {
        const map = clone(expandedItems)
        map.set(itemNumber, expanded)
        setExpandedItems(map)
    }


    // const expandAll = () => {
    //     const map = new Map<number, boolean>()
    //     renderComponentsList(props.items, (item: ListItem & ComponentListItem) => {
    //         map.set(item._itemNumber, true)
    //     })
    //     setExpandedItems(map)
    // }

    // const collapseAll = () => {
    //     setExpandedItems(new Map())
    // }


    const items = props.items
    if (!items || isEmptyArray(items)) {
        return null
    }
    const panelsComponents = renderComponentsList(items, renderExpansionPanel)
    const classes = styles()
    return (
        <div className={classes.root}>
            {/*
                TODO: temporanemanete disabilitato perchè non ci sono le schede
                <Button onClick={expandAll} className={css(styles.button)}>Espandi tutto</Button>
                <Button onClick={collapseAll} className={css(styles.button)}>Comprimi tutto</Button>*/}
            {panelsComponents}
        </div>
    )
}