import { ComboBox } from '@progress/kendo-react-dropdowns';
import { expandUri, isEmptyArray, logThis } from 'lib-react/universal/utils';
import { executeRequest } from 'lib-react/web/services/ajax-services';
import React from 'react';
import { InputWrapper } from '..';
import { FieldProps } from '../../interfaces/form-interfaces';

export interface ComboBoxData {
    label: string
    value: string
}

interface Props extends FieldProps {
    filterKeys?: {
        [name: string]: string
    }
    filterKey?: string
    errors?: string[]
}

interface State {
    data: ComboBoxData[]
    filterLabel: string
}

/**
 * ATTENZIONE: invece di usare questo componente direttamente, usare AutocompleteList.
 */
export default class ComboBoxField extends React.Component<Props, State> {
    static FIELD_NAME = 'custom-combo-box-field'

    static defaultProps = {
        filterKeys: {},
        filterKey: "search" // TODO: da rinominare perchè crea confusione con filter nello state.
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            data: [],
            filterLabel: props.formData
        }

        if (!props.uiSchema.customUrl) {
            throw new Error("No URL set for ComboBox!")
        }
    }

    componentDidMount() {
        this.fetchDataFromServer(this.state.filterLabel)
    }

    componentDidUpdate(oldProps: Props, oldState: State) {
        if (oldProps.uiSchema.customUrl !== this.props.uiSchema.customUrl || oldState.filterLabel !== this.state.filterLabel) {
            this.fetchDataFromServer(this.state.filterLabel);
        }
    }

    onFilterChange = (event: any) => {
        const filterLabel = event.filter.value
        if (filterLabel === "") {
            this.resetSelection()
        } else {
            this.setState({ filterLabel: filterLabel })
        }
    }

    resetSelection = () => {
        this.setState({ filterLabel: "" })
        this.props.onChange(undefined)
    }

    onChange = (event: any) => {
        const filterObject: ComboBoxData = event.target.value
        const filterLabel = this.getFilterLabelFromFilterObject(filterObject)
        this.setState({ filterLabel })
        this.props.onChange(filterObject.value)
    }

    createExpandObject = (filter: string) => {
        let newExpandObject = this.props.filterKeys
        newExpandObject[this.props.filterKey] = filter
        return newExpandObject
    }

    fetchDataFromServer = (filter: string) => {
        const url = this.props.uiSchema.customUrl
        const requestUri = expandUri(url, this.createExpandObject(filter))
        const that = this
        executeRequest<ComboBoxData[]>({ url: requestUri })
            .then((response) => {
                if (response.length === 0 && this.props.uiSchema.customAllowCustomValues && filter) {
                    response.push({
                        value: filter,
                        label: filter
                    })
                }
                that.setState({ data: response })
            })
            .catch((error) => {
                logThis("Cannot fetch the combobox values", error)
            })
    }

    listNoDataRender = (element: any) => {
        const noData = (
            <h4 style={{ fontSize: '1em' }}>
                <span className="k-icon k-i-warning" style={{ fontSize: '2.5em' }} />
                <br /><br />
                Dato Non Presente
            </h4>
        );

        return React.cloneElement(element, { ...element.props }, noData)
    }

    /**
     * Funzione necessaria perchè Kendo ComboBox richiede una label (e non una key) come valore per l'attributo "filter".
     * Tuttavia, dentro state.filterLabel nel costruttore del componente ci può finire una label che non matcha con i dati restituiti dal server
     * mediante la funzione fetchDataFromServer(), e quindi la ComboBox risulterà vuota.
     */
    getFilterLabelFromFilterObject = (filterObject: ComboBoxData) => {
        let internalFilterLabel
        if (this.state && this.state.data && !isEmptyArray(this.state.data)) {
            if (filterObject.value && filterObject.value !== "") {
                this.state.data.forEach((item) => {
                    if (item.value.toLowerCase() === filterObject.value.toLowerCase()) {
                        internalFilterLabel = item.label
                    }
                })
            }
        }
        return internalFilterLabel
    }

    render() {
        const { schema, uiSchema, required, errors } = this.props
        const inputClassName = uiSchema.customInputClassName ? uiSchema.customInputClassName : 'form-control'
        const disabled = this.props.readonly || this.props.schema.readonly
        return (
            <InputWrapper
                title={schema.title}
                required={required}
                uiSchema={uiSchema}
                errors={errors}
            >
                <ComboBox
                    allowCustom={uiSchema.customAllowCustomValues}
                    data={this.state.data}
                    textField={'label'}
                    filterable={true}
                    filter={this.state.filterLabel}
                    onFilterChange={this.onFilterChange}
                    onChange={this.onChange}
                    className={inputClassName}
                    listNoDataRender={this.listNoDataRender}
                    disabled={disabled}
                />
            </InputWrapper>
        )
    }
}
