import { createTheme } from '@material-ui/core';
import { brown, grey, indigo, pink, red, teal } from '@material-ui/core/colors';
import { StyleSheet } from 'aphrodite';

// TODO: spostare in qualche libreria
export class BaseDimens {
    static FONT_SIZE_XXLARGE = 30
    static FONT_SIZE_XLARGE = 25
    static FONT_SIZE_LARGE = 18
    static FONT_SIZE_MEDIUM = 13
    static FONT_SIZE_SMALL = 10
    static FONT_SIZE_XSMALL = 8
}

// TODO: spostare in qualche libreria
export class BaseMaterialColors {
    static WHITE = "#FFF"

    static PRIMARY = indigo[500]
    static ACCENT = pink[500]

    static TEAL = teal[500]
    static BROWN = brown[500]
    static RED = red[500]
    static GREY = grey[500]
}

export class AppDimens extends BaseDimens {
    static QR_ICON_SIZE = 60
    static APP_BASE_FONT_SIZE = 22
}

export class AppColors {
    static PRIMARY = "#282d48"
    static ACCENT = "#FF577B"
}

export const COMMON_STYLES = StyleSheet.create({
    floatRight: {
        float: 'right'
    },
    snackBar: {
        fontSize: AppDimens.APP_BASE_FONT_SIZE
    }
})

export const APP_THEME = createTheme({
    typography: {
        fontSize: AppDimens.APP_BASE_FONT_SIZE,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: AppColors.PRIMARY,
            light: AppColors.ACCENT,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: AppColors.ACCENT,
            main: AppColors.ACCENT,
            // dark: will be calculated from palette.secondary.main,
            contrastText: AppColors.ACCENT,
        },
    },
})